import React from 'react'
import BeatLoaderfn from '../../../../helper/BeatLoaderfn'


export default function BranchCardTotal({ title, title1,quantity, usedclass, titleColorClass, countColorClass, onClicksetFlag, tableflag, dashloading }) {
    return (
        <div className={usedclass} onClick={() => onClicksetFlag(tableflag)}>
            <text data-toggle="modal" data-target="#mainletters" className="card-body dataLoadBtn">
                <h5 className={titleColorClass}>{title}</h5>
                {title1 && title1 !==''  ? <h5 className={titleColorClass}>{title1}</h5>:<br/>}
                <h1 className={countColorClass} style={{ letterSpacing: 2 }}>
                    {dashloading ? <BeatLoaderfn customcolor={tableflag === 'Weekly' || tableflag === 'Monthly' ? 'black' : 'white'} /> : quantity}
                </h1>
            </text>
        </div>

    )
}
