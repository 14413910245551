import { useState } from "react"
import AdminNavbar from "../../../../Component/AdminNavbar/AdminNavbar"
import Footer from "../../../../Component/Footer/Footer"
import Header from "../../../../Component/Header/Header"
import { Loader } from "react-overlay-loader"
import Select, { AriaOnFocus } from 'react-select';
import { baseUrl } from "../../../../BaseURL/BaseURL"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { toastInfo } from "../../../../helper/Toasthelper"


export const MultipleBranch = () => {
    const UserID = sessionStorage.getItem("User")
    const [loading, setloading] = useState(false)
    const [gridData, setgridData] = useState([])
    const [IsClear, setIsClear] = useState(false)
    const [IsValidate, setIsValidate] = useState(true)
    const [IsPost, setIsPost] = useState(false)

    var UserLoginData
    const LoginDataa = (value) => {
        UserLoginData = value
    }

    const [EmployeeNameDDL, setEmployeeNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const GetEmployeeDDL = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_TableUserMasterForMultipalBranch_Select?UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.status && result.data) {
                    let list = result.data.map((item, index) => ({
                        value: item.m_TableUserMasterID,
                        label: item.tableName,
                    }))

                    setEmployeeNameDDL({
                        DDL: list,
                        ID: 0,
                        Label: "Select..."
                    })
                }
                else {
                    setEmployeeNameDDL({
                        DDL: [],
                        ID: 0,
                        Label: "Select...",
                    })
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetEmployeeDDL()
    }, [])

    const Get_TableData = () => {
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/LFMS/Master/Get_M_TableUserMasterWiseBranchName_Select?M_TableUserMasterID=${EmployeeNameDDL.ID}&UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status && result.data) {
                    setgridData(result.data)
                }
                setloading(false)
            })
            .catch(error => {
                setloading(false)
                console.log('error', error)
            });
    }

    useEffect(() => {
        if (EmployeeNameDDL.ID !== 0) {
            Get_TableData()
        }
    }, [EmployeeNameDDL.ID, IsPost])

    const Clear = () => {
        setgridData([])
        setIsClear(!IsClear)
        Get_TableData()

        setEmployeeNameDDL({
            ...EmployeeNameDDL,
            ID: 0,
            Label: "Select..."
        })
    }

    const handleOnCheckClick = (index, check) => {
        if (gridData) {
            let tempGridData = [...gridData]
            tempGridData[index].isChecked = check
            // console.log(tempGridData)
            setgridData(tempGridData)
        }
    }

    const handelAssignClicked = () => {
        let allCheckData = ""
        gridData.forEach((item) => {
            if (item.isChecked) {
                allCheckData = allCheckData + item.m_BranchNameMasterID + ","
            }
        })

        PostAssign(allCheckData)
    }

    const PostAssign = (allCheckData) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var formdata = new FormData();
        formdata.append("M_TableUserMasterID", EmployeeNameDDL.ID);
        formdata.append("M_BranchNameMasterID_Data", allCheckData);
        formdata.append("UserID", UserID);
        formdata.append("Flag", "Insert");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/LFMS/Master/Post_M_TableUserMasterWiseBranchName_InsertUpdate`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setIsPost(!IsPost)
                    toast.success(result.message)
                    Clear()
                } else {
                    toast.error(result.message)
                }
            })
            .catch(error => {
                toast.error('Something went wrong')
                console.log('error', error)
            });

    }
    return (
        <div>
            {loading && <Loader fullPage loading />}

            <div className="wrapper">
                <AdminNavbar active="MultipleBranch" />

                <div className="main">
                    <Header LoginDataa={LoginDataa} />

                    <main className="content">

                        <div className="container-fluid p-0" >

                            <div className="row mb-2 ml-auto mb-xl-2" style={{ marginTop: '-2%' }}>
                                <div className="col-auto d-sm-block">
                                    <h2>Multiple Branch</h2>

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '1%' }}>
                                <div className="col-sm-11 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="filter">
                                                {/* <div className="form-row"> */}
                                                <form className="form-inline">
                                                    <div className="form-group mb-2">
                                                        <label for="staticEmail2" style={{ marginTop: '2%' }}>कार्यासनाचे नाव  :</label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <Select
                                                            isClearable
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            isSearchable
                                                            maxMenuHeight={300}
                                                            value={{ value: EmployeeNameDDL.ID, label: EmployeeNameDDL.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setEmployeeNameDDL({ ...EmployeeNameDDL, ID: e.value, Label: e.label })
                                                                    :
                                                                    setEmployeeNameDDL({ ...EmployeeNameDDL, ID: 0, Label: "Select..." })

                                                            }}
                                                            options={EmployeeNameDDL.DDL}
                                                        />
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="table-responsive pb-3" style={{ height: '50vh' }}>
                                                <table className="table table-bordered data-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>

                                                            <th style={{ textAlign: 'left' }}>शाखेचे नाव </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {EmployeeNameDDL.ID !== 0 && gridData && gridData.length > 0 ? gridData.map((i, j) => {
                                                            return (
                                                                <tr>
                                                                    <td align='center' id='srwidth2'>
                                                                        <input
                                                                            name="myCheckbox"
                                                                            value={i.isChecked}
                                                                            checked={i.isChecked}
                                                                            type='checkbox'
                                                                            onClick={(e) => handleOnCheckClick(j, !i.isChecked)}

                                                                        />
                                                                    </td>
                                                                    <td>{i?.branchName}</td>
                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            < tr >
                                                                <td></td>
                                                                <td>माहिती उपलब्ध नाही</td>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                EmployeeNameDDL.ID !== 0 && gridData && gridData.length > 0 &&
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        // disabled={IsValidate}
                                                        className="btn btn-primary"
                                                        data-dismiss="modal"
                                                        onClick={() => handelAssignClicked()}
                                                    >Add</button>

                                                    <button type="button" className="btn btn-dark" onClick={Clear}>Clear</button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </main>

                    <Footer />
                </div>
            </div >
        </div >
    )
}