import React from 'react'
import { FaFilePdf } from 'react-icons/fa';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { Link } from 'react-router-dom';
import ExcelExportPendancyTableCOMM from '../../Commisioner/SPDashboard/Dashboard/Pendency/ExcelExportPendancyTableCOMM';
import CommFourthDrillPopUp from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/CommFourthDrillPopUp';


export default function BranchTable(
    {
        showhide,
        setshowhide,
        ddlloader,
        ReferenceTypeDDL,
        onChnageDDL,
        SPData,
        flag,
        ReferenceTypeTableData,
        tableloader,
        IsBranchSelect
    }) {

    const [DDLDataID, setDDLDataID] = React.useState('0')
    const [DDLDataIDHeading, setDDLDataIDHeading] = React.useState('')

    const onChnageDays = (e) => {
        onChnageDDL(e)
    }

    React.useEffect(() => {
        sessionStorage.setItem('refid', DDLDataID)
        onChnageDDL(DDLDataID)
    }, [DDLDataID,IsBranchSelect])

    const [PendancyData, setPendancyData] = React.useState([])
    const [PendancyShow, setPendancyShow] = React.useState(false)

    const hideshowPendancyTablePopUp = (data, show) => {
        setPendancyData(data)
        setPendancyShow(show)
    }

    const [FourthData, setFourthData] = React.useState([])
    const [FourthShow, setFourthShow] = React.useState(false)

    const hideshowFourthPopUp = (data, show) => {
        setFourthData(data)
        setFourthShow(show)
    }

    return (

        <div className={
            showhide ? "col-12 col-md-6 col-xxl-6 d-flex order-2 pr-3"
                :
                "col-12 col-md-12 col-xxl-12 d-flex order-2 pr-3"}
        >
            <div className="spcard flex-fill w-100">
                <div className=" spcard-header mr-2 ml-2 mb-n4">
                    <div className='row'>

                        <div className="col-lg-5 col-xxl-8 col-md-11 col-sm-11">
                            <h5 className="spcard-title mb-0 mt-2 ml-n2">
                                {
                                    flag && flag === 'file' ?
                                        'कार्यासना नुसार प्रलंबित पत्राचा गोषवारा'
                                        :
                                        'कार्यासनानुसार प्रलंबित नस्तीचा गोषवारा'
                                }

                            </h5>
                        </div>

                        <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 mt-2 mb-3 gx-1">
                            {/* <ExcelExportPendancyTableCOMM ReferenceTypeTableData={ReferenceTypeTableData} name='कार्यासना नुसार प्रलंबित नस्तीचा गोषवारा' flag='कार्यासनाचे नाव' /> */}

                            {
                                ReferenceTypeTableData && ReferenceTypeTableData.url ?
                                    <button type="submit" className="btn btn-outline-danger btn-md mr-2"
                                        onClick={() => window.open(ReferenceTypeTableData && ReferenceTypeTableData.url)}
                                    >
                                        <FaFilePdf />
                                        <span className='mx-2'>PDF</span>
                                    </button>
                                    : ''
                            }
                        </div>

                        <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 mt-2 mb-3 gx-2">
                            <button type='button' className='btn btn-primary'
                                disabled={ddlloader}
                                onClick={() => setshowhide(!showhide)}
                                style={{ fontSize: '12px', paddingTop: '2px' }}
                            >{!showhide ? 'Show Graph' : 'Hide Graph'}</button>
                        </div>

                        <div className="filter col-lg-3 col-xxl-4 col-md-11 col-sm-11 mt-n1 gx-1">
                            <select
                                className="form-control"
                                name="letterSorting"
                                id="letterSorting"
                                value={DDLDataID}
                                onChange={(e) => {
                                    setDDLDataID(e.target.value)
                                    sessionStorage.setItem('refid', e.target.value)
                                    setDDLDataIDHeading(ReferenceTypeDDL[e.target.selectedIndex - 1].referenceType)
                                }}

                            >
                                <option value='0' Selected>एकूण</option>
                                {
                                    ReferenceTypeDDL !== null ? ReferenceTypeDDL.map(item =>
                                        <option name={item.referenceType} value={item.id}>{item.referenceType}</option>

                                    ) : <option disabled>माहिती उपलब्ध नाही</option>

                                }

                            </select>
                        </div>

                    </div>
                </div>

                <div className="align-self-center w-100 p-2">

                    <div className="tbl-sticky mt-n1" style={{ height: '50vh' }}>
                        {

                            ddlloader ?

                                <div className='loadingcenter' style={{ height: '50vh' }}>
                                    <i
                                        className="fa fa-refresh fa-spin loadingIcon"
                                    />
                                    <label className='loadinglabel'>Loading ...</label>
                                </div>

                                :
                                ReferenceTypeTableData && ReferenceTypeTableData.data === null ?

                                    // <div className='loadingcenter' style={{ height: '51vh' }}>
                                    //     <i className="fa fa-exclamation-triangle tableNorecordicon" />
                                    //     <label className='tablenorecordtable'> No Record Found ...</label>
                                    // </div>

                                    <div className='loadingcenter' style={{ height: '50vh', overflow: 'hidden' }}>
                                        <img
                                            src="assets/img/folder.png"
                                            style={{ height: "40px", marginLeft: "10px" }}
                                        /><label className='tablenorecordtable'>&nbsp;&nbsp;No Record Found ...</label>
                                    </div>

                                    :

                                    <table className="table table-bordered table-hover" style={{ borderCollapse: "collapse", }}>

                                        <thead>
                                            <tr>
                                                {/* <th>अ.क्रं.</th> */}
                                                <th >कार्यासनाचे नाव</th>
                                                <th >एकूण नस्ती
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalInwardCount ? ReferenceTypeTableData.totalInwardCount : "0"}
                                                </th>

                                                <th >एकूण निर्गमीत
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalCloseCount ? ReferenceTypeTableData.totalCloseCount : "0"}

                                                </th>
                                                <th >एकूण प्रलंबित
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalPendingCount ? ReferenceTypeTableData.totalPendingCount : "0"}

                                                </th>
                                                <th>0 ते 7 दिवस
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_0_7_Count ? ReferenceTypeTableData.daysPending_0_7_Count : "0"}

                                                </th>
                                                <th>7 ते 21 दिवस
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_7_21_Count ? ReferenceTypeTableData.daysPending_7_21_Count : "0"}

                                                </th>
                                                <th >21 ते 90 दिवस
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_21_3_Month_Count ? ReferenceTypeTableData.daysPending_21_3_Month_Count : "0"}

                                                </th>
                                                <th >90 ते 365 दिवस
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_3_12_Month_Count ? ReferenceTypeTableData.daysPending_3_12_Month_Count : "0"}

                                                </th>
                                                <th >1 वर्षा पुढील
                                                    <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_1_YearAbove_Count ? ReferenceTypeTableData.daysPending_1_YearAbove_Count : "0"}

                                                </th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {
                                                ReferenceTypeTableData && ReferenceTypeTableData.data ? ReferenceTypeTableData.data.map((i, index) => {
                                                    return (
                                                        <tr >
                                                            <td >{i.entityName ? i.entityName : '-'}</td>
                                                            {/* id='trNamewidth' */}

                                                            <td align='center' id='trwidth'>
                                                                {i.totalInwardCount ? i.totalInwardCount : '0'}
                                                            </td>



                                                            <td align='center' id='trwidth'>
                                                                {i.totalCloseCount ? i.totalCloseCount : '0'}
                                                            </td>

                                                            <td align='center' style={{ color: 'blue', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log('show')
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'totalPendingCount',
                                                                        fourthShowby: 'All',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}
                                                            >
                                                                {i.totalPendingCount ? i.totalPendingCount : '0'}
                                                            </td>

                                                            <td align='center' id='trwidth' className='text-success'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log(i)
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'DaysPending_0_7_Count',
                                                                        fourthShowby: 'ReferenceType',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>
                                                                {i.daysPending_0_7_Count ? i.daysPending_0_7_Count : '0'}

                                                            </td>

                                                            <td align='center' id='trwidth' className='text-success'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log(i)
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'DaysPending_7_21_Count',
                                                                        fourthShowby: 'ReferenceType',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>
                                                                {i.daysPending_7_21_Count ? i.daysPending_7_21_Count : '0'}

                                                            </td>

                                                            <td align='center' id='trwidth' style={{ color: 'orange', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log(i)
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'DaysPending_21_3_Month_Count',
                                                                        fourthShowby: 'ReferenceType',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>
                                                                {i.daysPending_21_3_Month_Count ? i.daysPending_21_3_Month_Count : '0'}

                                                            </td>

                                                            <td align='center' id='trwidth' className='text-danger'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log(i)
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'DaysPending_3_12_Month_Count',
                                                                        fourthShowby: 'ReferenceType',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>
                                                                {i.daysPending_3_12_Month_Count ? i.daysPending_3_12_Month_Count : '0'}

                                                            </td>

                                                            <td align='center' id='trwidth' className='text-danger'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log(i)
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All",
                                                                        FilterID: '63',
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'DaysPending_1_YearAbove_Count',
                                                                        fourthShowby: 'ReferenceType',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>
                                                                {i.daysPending_1_YearAbove_Count ? i.daysPending_1_YearAbove_Count : '0'}

                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                                    :
                                                    <tr><label>No Record Found ...</label></tr>
                                            }


                                        </tbody>


                                    </table>
                        }

                        {
                            FourthShow ?
                                <CommFourthDrillPopUp
                                    setFourthShow={setFourthShow}
                                    FourthData={FourthData}
                                    flag='show'
                                /> : <></>
                        }

                        {/* {
                            PendancyShow ?
                                <PendancyTablePopUp
                                    setPendancyShow={setPendancyShow}
                                    PendancyData={PendancyData}
                                    DDLDataID={DDLDataID}
                                    ReferenceTypeDDL={ReferenceTypeDDL}
                                    DDLDataIDHeading={DDLDataIDHeading}
                                    flag={flag}
                                /> : <></>
                        } */}
                    </div>

                </div>
            </div>
        </div>
    )
}