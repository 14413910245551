import React from 'react'

const GraphButton = (props) => {
    const { setShowGraph, showGraph } = props
    return (
        <div className="row col-md-2 col-sm-12 maintop">
            <div className="btn-group btn-group-lg" role="group" aria-label="Basic example">

                <button type="button"
                    // className='btn dash-btn btn-secondary float-right'
                    className={showGraph === 'Bar' ? "btn dash-btn dash-btn-active" : "btn btn-outline-secondary"}
                    style={{ width: '80px' }}
                    onClick={() => setShowGraph('Bar')}
                >प्रलंबित</button>

                <button type="button"
                    // className='btn dash-btn dash-btn-active float-right'
                    className={showGraph === 'Pie' ? "btn dash-btn dash-btn-active" : "btn btn-outline-secondary"}
                    style={{ width: '80px' }}
                    onClick={() => setShowGraph('Pie')}
                >निर्गमीत</button>

            </div>
        </div>
    )
}

export default GraphButton

