
import React from 'react'

export default function BranchCardTotal4({ title, quantity, bgClass, name, textclass, qtyclass, nameclass }) {


    return (
        <div>
            <div className={bgClass} style={{ height: '27vh' }}>
                <div className="card-body">
                    <h5 className={textclass}>{title}</h5>
                    {/* {
                        quantity ? <h1 className={qtyclass}>
                            {quantity}</h1> : <h3 className={nameclass}>{name}</h3>
                    } */}
                    <h1 className={qtyclass}>
                            {quantity}</h1>
                </div>
            </div>
        </div>
    )
}