
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment'

import { SyncLoader } from 'react-spinners';
import { css } from "@emotion/react";
import { Loader, LoadingOverlay } from "react-overlay-loader"
import { baseUrl } from '../../../../BaseURL/BaseURL';
import NavbarLetter from '../../../../Component/NavbarLetter/NavbarLetter';
import Header from '../../../../Component/Header/Header';
import Footer from '../../../../Component/Footer/Footer';
import { toast } from 'react-toastify';
import { GetUserToken, SendNotification } from '../../../../helper/NotificationAPI/NotificationAPI';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function ResendNewOutLetter() {

    const ResendObj = JSON.parse(sessionStorage.getItem('ResendObj'))
    

    const [EtrChecked, setEtrChecked] = React.useState(false)
    const [SaveLetter, setSaveLetter] = React.useState(false)

    const UserId = sessionStorage.getItem("User")
    const M_OfficeMasterID = sessionStorage.getItem("M_OfficeMasterID")
    const IsProtalAdmin = sessionStorage.getItem("IsPortalAdmin")

    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [isOfficeCheckAll, setOfficeIsCheckAll] = React.useState(false);
    const [isOfficeCheck, setOfficeIsCheck] = React.useState([]);
    const [isCheck, setIsCheck] = React.useState([]);
    const [DDL_LetterTypeStatus, setDDL_LetterTypeStatus] = React.useState([])
    const [LetterMediumIndicatorID, setLetterMediumIndicatorID] = React.useState(ResendObj.letterMediumIndicatorID ? ResendObj.letterMediumIndicatorID : '')
    const [M_ClassificationWiseLetterType, setM_ClassificationWiseLetterType] = React.useState([])
    const [M_ClassificationWiseLetterTypeID, setM_ClassificationWiseLetterTypeID] = React.useState(ResendObj.m_ClassificationWiseLetterTypeID ? ResendObj.m_ClassificationWiseLetterTypeID : '')
    const [M_LetterClassification, setM_LetterClassification] = React.useState([])
    const [M_LetterClassificationID, setM_LetterClassificationID] = React.useState(ResendObj.m_LetterClassificationID ? ResendObj.m_LetterClassificationID : '')
    const [M_OfficeMaster, setM_OfficeMaster] = React.useState([])

    const [DDL_Outward_Close_Type, setDDL_Outward_Close_Type] = React.useState([]);
    const [OfficeName, setOfficeName] = React.useState(ResendObj.officeDivisionName ? ResendObj.officeDivisionName : '')
    const [RelatedAddress, setRelatedAddress] = React.useState(ResendObj.officeDivisionAddress ? ResendObj.officeDivisionAddress : '')
    const [Email, setEmail] = React.useState(ResendObj.officeDivisionEmail ? ResendObj.officeDivisionEmail : '')
    const [OutwardDate, setOutwardDate] = React.useState(moment().format("YYYY-MM-DD"))
    const [LetterSubject, setLetterSubject] = React.useState(ResendObj.letterSubject ? ResendObj.letterSubject : '')
    const [fileName, setfileName] = React.useState(null)
    const [base64URL, setbase64URL] = React.useState(null)
    const [ImageFile, setImageFile] = React.useState(null)
    const [Remark, setRemark] = React.useState(ResendObj.remark ? ResendObj.remark : '')

    const [LetterMediumError, setLetterMediumError] = React.useState('')
    const [LetterClassificationError, setLetterClassificationError] = React.useState('')
    const [ClassificationError, setClassificationError] = React.useState('')
    const [OfficeNameError, setOfficeNameError] = React.useState('')
    const [RelatedAddressError, setRelatedAddressError] = React.useState('')
    const [EmailError, setEmailError] = React.useState('')
    const [ActitionError, setActitionError] = React.useState('')
    const [BranchError, setBranchError] = React.useState('')
    const [OutwardDateError, setOutwardDateError] = React.useState('')
    const [LetterSubjectError, setLetterSubjectError] = React.useState('')
    const [LetterDocError, setLetterDocError] = React.useState('कृपया पत्र PDF फॉरमॅट मध्ये आणि 2 MB पेक्षा कमी निवडा')
    const [M_BranchNameMasterID, setM_BranchNameMasterID] = React.useState(ResendObj.outwardBranchID ? ResendObj.outwardBranchID : '')
    const [DDL_M_BranchNameMaster, setDDL_M_BranchNameMaster] = React.useState([]);
    const [DDL_OutwardDivisonName, setDDL_OutwardDivisonName] = React.useState([]);

    const [KaryalayLen, setKaryalayLen] = React.useState(0)
    const [KaryalayPrakarLen, setKaryalayPrakarLen] = React.useState(0)
    const [DDL_Outward_M_OfficeMasterSelect, setDDL_Outward_M_OfficeMasterSelect] = React.useState(null)
    const [TableLoader, setTableLoader] = React.useState(false)
    const [TableUserCheckError, setTableUserCheckError] = React.useState("कार्यासनाचे नाव अनिवार्य आहे")
    const [OfficeTypeError, setOfficeTypeErrorError] = React.useState('')

    const [OfficeNameIDs, setOfficeNameIDs] = React.useState([])
    const [actionType, setactionType] = React.useState(ResendObj.closeTypeIndicatorID ? ResendObj.closeTypeIndicatorID : '')
    const [javakType, setjavakType] = React.useState("")
    const [JavakError, setJavakError] = React.useState('')
    const [otherDepartmentID, setotherDepartmentID] = React.useState(0)
    const [showAlert, setshowAlert] = React.useState(false)
    const [AfterPostshowAlert, setAfterPostshowAlert] = React.useState(false)
    const [AfterPostRes, setAfterPostRes] = React.useState('')
    const [loader, setLoader] = useState(false)
    const [contentLoader, setContentLoader] = useState(false)

    const [officescustomdata, setofficescustomdata] = React.useState([])
    const [officetypescustomdata, setofficetypescustomdata] = React.useState([])
    const [cbcheck, setcbcheck] = React.useState(false)
    const [cacheck, setcacheck] = React.useState(false)
    const [ctcheck, setctcheck] = React.useState(false)
    const [TrueData, setTrueData] = React.useState([])
    const [OutWardDivisionData, setOutWardDivisionData] = React.useState(0)
    const [TableData, setTableData] = React.useState([])
    const [APICALL, setAPICALL] = React.useState(false)
    const [ShowLable, setShowLable] = React.useState('')
    const [BranchCheckError, setBranchCheckError] = React.useState('')
    const [OutWardDivisionError, setOutWardDivisionError] = React.useState('')
    const [RoleMasterID, setRoleMasterID] = React.useState('')
    const [IsTableCheckAll, setIsTableCheckAll] = React.useState(false);
    const [branchescustomdata, setbranchescustomdata] = React.useState([])
    const [OutwardDivisoncustomdata, setOutwardDivisoncustomdata] = React.useState([])
    const [tablescustomdata, settablescustomdata] = React.useState([])
    const [Inward_Forward_M_TableUserMaster, setInward_Forward_M_TableUserMaster] = React.useState(null)
    const [DDL_Outward_Javak_Type, setDDL_Outward_Javak_Type] = React.useState([])
    const [UserData, setUserData] = React.useState()
    const [PostAmount, setPostAmount] = useState('')
    const [PostAmountError, setPostAmountError] = useState('')
    const [M_OfficeTypeMaster, setM_OfficeTypeMaster] = React.useState([])

    const [TrueData1, setTrueData1] = React.useState([])
    const [cbcheck1, setcbcheck1] = React.useState(false)
    const [TableLoader1, setTableLoader1] = React.useState(false)
    const [isCheckAll1, setIsCheckAll1] = React.useState(false);
    const [isCheck1, setIsCheck1] = React.useState([]);

    const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
        return (
            <div style={{ marginTop: '1%' }}>
                <input
                    style={{ marginBottom: '3%' }}
                    id={id}
                    name={name}
                    type={type}
                    onChange={handleClick}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    var allKaryalayPrakar
    var allkaryalay
    var KaryalayPrakar
    var karyalay

    React.useEffect(() => {
        if (ResendObj.officeDivisionAddress !== null && ResendObj.officeDivisionName !== null) {
            setEtrChecked(true)
        }
    }, [])

    React.useEffect(() => {

    }, [cbcheck, APICALL, ctcheck])

    const Get_DDL_M_BranchNameMaster = () => {
        // setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
       
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_OutwardM_OfficeTypeMaster?UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // setloading(false)
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setDDL_M_BranchNameMaster(result.data)
                    setbranchescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))

                } else {

                    setDDL_M_BranchNameMaster(null)
                }
            })
            .catch(error => {
                // alert.error("Something went wrong")
                // setloading(false)
            });
    }


    // -------------------------------------- कार्यालय प्रकार ----------------------------------

    const Get_DDL_OutwardDivisonName = () => {
        // setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_OutwardDivisonName?UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // setloading(false)
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setDDL_OutwardDivisonName(result.data)
                    setOutwardDivisoncustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))

                } else {

                    setDDL_OutwardDivisonName(null)
                }
            })
            .catch(error => {
                // alert.error("Something went wrong")
                // setloading(false)
            });
    }

    // शाखा निवडा Select all 
    const handleBranchSelectAll = (event) => {
        setcbcheck(cb.checked);
        // document.getElementById("selectAll7").checked = false;
        TrueData.splice(0, TrueData.length)
        if (cb.checked) {
            setbranchescustomdata(branchescustomdata.map(customdata => ({ ...customdata, isChecked: true })))
            branchescustomdata.map(g => {
                TrueData.push(g.id)
            })
            Get_DDL_Inward_Forward_M_TableUserMaster(OutWardDivisionData)
            setBranchCheckError("")
        } else {
            setTableData([])
            setbranchescustomdata(branchescustomdata.map(customdata => ({ ...customdata, isChecked: false })))
            settablescustomdata([])
            setBranchCheckError("विभाग अनिवार्य आहे")
        }

    };

    // शाखा निवडा Individual 
    const handleBranchChange = (event) => {
        setAPICALL(!APICALL)
        let branches = branchescustomdata;
        branches.forEach((branch) => {
            if (branch.officeTypeName === event.officeTypeName) {
                if (branch.isChecked === false) {
                    branch.isChecked = true;
                    TrueData.push(branch.id)
                    setBranchCheckError("")
                    // document.getElementById("selectAll7").checked = false;
                } else {
                    branch.isChecked = false;
                    for (var i = 0; i < TrueData.length; i++) {
                        if (TrueData[i] === branch.id) {
                            TrueData.splice(i, 1);
                        }
                    }
                    setBranchCheckError("")
                    settablescustomdata([])
                    setTableData([])
                    // document.getElementById("selectAll7").checked = false;
                }
                if (TrueData.length === 0) {
                    document.getElementById("selectAll6").checked = false;
                    // document.getElementById("selectAll7").checked = false;
                    setBranchCheckError("विभाग अनिवार्य आहे")
                }
                // document.getElementById("selectAll7").checked = false;
            }
        });
        Get_DDL_Inward_Forward_M_TableUserMaster(OutWardDivisionData)
        setbranchescustomdata(branches)
    };

    // शाखा निवडा Select all 
    const handleOutwardDivisonSelectAll = (event) => {
        console.log("OutwardDivisoncustomdata", ca.checked)
        setcacheck(ca.checked);

        TrueData.splice(0, TrueData.length)
        if (ca.checked) {
            document.getElementById("selectAll8").checked = true;
            setOutwardDivisoncustomdata(OutwardDivisoncustomdata.map(customdata => ({ ...customdata, isChecked: true })))
            OutwardDivisoncustomdata.map(g => {
                TrueData.push(g.id)
            })
            Get_DDL_Inward_Forward_M_TableUserMaster()
            setOutWardDivisionError("")
        } else {
            document.getElementById("selectAll8").checked = false;
            setTableData([])
            setOutwardDivisoncustomdata(OutwardDivisoncustomdata.map(customdata => ({ ...customdata, isChecked: false })))
            settablescustomdata([])
            setOutWardDivisionError("विभाग अनिवार्य आहे")
        }

    };

    // शाखा निवडा Individual 
    const handleOutWardDivisionChange = (event) => {
        setOutWardDivisionData(event.id)
        console.log("branch", event.id)
        // setAPICALL(!APICALL)
        let branches = OutwardDivisoncustomdata;
        branches.forEach((branch) => {

            if (branch.divisonName === event.divisonName) {
                if (branch.isChecked === false) {
                    branch.isChecked = true;
                    Get_DDL_Inward_Forward_M_TableUserMaster(event.id)
                } else {
                    branch.isChecked = false;
                    Get_DDL_Inward_Forward_M_TableUserMaster("0")
                }

                // if (branch.isChecked === false) {
                //     branch.isChecked = true;
                //     // OutWardDivisionData.push(branch.id)
                //     setOutWardDivisionData(branch.id)
                //     setOutWardDivisionError("")
                //     // document.getElementById("selectAll8").checked = false;
                // } else {
                //     branch.isChecked = false;
                //     for (var i = 0; i < OutWardDivisionData.length; i++) {
                //         if (OutWardDivisionData[i] === branch.id) {
                //             OutWardDivisionData.splice(i, 1);
                //         }
                //     }
                //     setOutWardDivisionError("")
                //     settablescustomdata([])
                //     setTableData([])
                //     // document.getElementById("selectAll8").checked = false;
                // }
                if (OutWardDivisionData.length === 0) {
                    // document.getElementById("selectAll6").checked = false;
                    // document.getElementById("selectAll7").checked = false;
                    // document.getElementById("selectAll8").checked = false;
                    setOutWardDivisionError("विभाग अनिवार्य आहे")
                }
                // document.getElementById("selectAll7").checked = false;
            } else {
                branch.isChecked = false;
                // setOutWardDivisionData('')
            }
        });

        // OutwardDivisoncustomdata(branches)
    };

    // कार्यासनाचे नाव Select all
    const handleTableSelectAll = e => {
        setctcheck(ct.checked);
        TableData.splice(0, TableData.length)
        if (ct.checked) {
            settablescustomdata(tablescustomdata.map(customdata => ({ ...customdata, isChecked: true })))
            tablescustomdata.map(g => {
                TableData.push(g.m_OfficeMasterID)
            })
            setTableUserCheckError("")
        } else {
            settablescustomdata(tablescustomdata.map(customdata => ({ ...customdata, isChecked: false })))
            setTableData([])
            setTableUserCheckError("कार्यासनाचे नाव अनिवार्य आहे")
        }
    };

    // कार्यासनाचे नाव Individual
    const handleTableChange = (event) => {
        setAPICALL(!APICALL)
        let tables = tablescustomdata;
        tables.forEach((table) => {
            if (table.officeName === event.officeName) {
                if (table.isChecked === false) {
                    table.isChecked = true;
                    TableData.push(table.m_OfficeMasterID)
                    setTableUserCheckError("")
                } else {
                    table.isChecked = false;
                    for (var i = 0; i < TableData.length; i++) {
                        if (TableData[i] === table.m_OfficeMasterID) {
                            TableData.splice(i, 1);
                        }
                    }
                    setTableUserCheckError("")
                }
                if (TableData.length === 0) {
                    document.getElementById("selectAll7").checked = false;
                    setTableUserCheckError("कार्यासनाचे नाव अनिवार्य आहे")
                }
            }
        });
        settablescustomdata(tables)
    }
    const Get_DDL_Inward_Forward_M_TableUserMaster = (ids) => {
        // console.log("TrueData", TrueData)
        settablescustomdata([])
        setOutWardDivisionData(ids)
        setTableData([])
        setTableUserCheckError("कार्यासनाचे नाव अनिवार्य आहे")
        setTableLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        // fetch(`${baseUrl}/CommonDLLData/GET_DDL_Outward_M_TableUserMaster?M_DepartmentNameMasterID=${ids}&M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
        // fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_TableUserMaster?M_DepartmentMasterID=${ids}&M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Outward_M_OfficeMasterSelect?M_OfficeTypeMaster_ID=${TrueData}&M_DivisonNameID=${ids && ids != undefined ? ids : '0'}&UserID=${UserId}`, requestOptions)
            .then(response => {
                setTableLoader(false)
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }
                else {
                    // alert.info("Unable to load resources")
                    settablescustomdata([])
                }
            })
            .then(result => {
                if (result.message === "No data found") {
                    setTableLoader(false)
                }
                else {
                    setTableLoader(false)
                    if (result.code >= 200 && result.code <= 300 && result.data) {
                        setInward_Forward_M_TableUserMaster(result.data)
                        settablescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))
                        setRoleMasterID(result.data[0].m_RoleManagerID)
                    } else {

                        setInward_Forward_M_TableUserMaster(null)
                        setRoleMasterID('')
                    }
                }

            })
            .catch(error => {
                // alert.error("Something went wrong")
                setTableLoader(false)
            });
    }
    const Get_DDL_Inward_Forward_M_TableUserMaster1 = (ids) => {
        setTableData([])
        setTableLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_TableUserMaster?M_DepartmentMasterID=${ids}&M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
            // fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_TableUserMaster?M_BranchNameMasterID=${ids}&M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
            .then(response => {
                setTableLoader(false)
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }
                else {
                    // alert.info("Unable to load resources")
                }
            })
            .then(result => {
                if (result.message === "No data found") {
                    setTableLoader(false)
                }
                else {
                    setTableLoader(false)
                    if (result.code >= 200 && result.code <= 300 && result.data) {
                        setInward_Forward_M_TableUserMaster(result.data)
                        settablescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))
                        setRoleMasterID(result.data[0].m_RoleManagerID)
                    } else {

                        setInward_Forward_M_TableUserMaster(null)
                        setRoleMasterID('')
                    }
                }

            })
            .catch(error => {
                // alert.error("Something went wrong")
                setTableLoader(false)
            });
    }

    React.useEffect(() => {
        // console.log(TrueData)
        // Get_DDL_Inward_Forward_M_TableUserMaster()
    }, [cbcheck, APICALL, ctcheck])

    var cb = document.querySelector('#selectAll6');
    var ct = document.querySelector('#selectAll7');
    var ca = document.querySelector('#selectAll8');

    const SaveSucess = () => {
        setSaveLetter(!SaveLetter)
    }

    const handleChange = (e) => {
        if (e.target.value === "on") {
            setotherDepartmentID(1)

        } else {
            setotherDepartmentID(0)
        }
        setEtrChecked(!EtrChecked)
        setOfficeName('')
        setOfficeNameError('')
        setRelatedAddress('')
        setRelatedAddressError('')
        setEmail('')
        setEmailError('')
    }



    const Get_DDL_LetterTypeStatus = () => {
        setLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_LetterTypeStatus`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setDDL_LetterTypeStatus(result.data)

                } else {
                    setDDL_LetterTypeStatus([])
                }
                setLoader(false)

            })
            .catch(error => {
                setLoader(false)

                console.log('error', error)
            });
    }

    const Get_DDL_M_ClassificationWiseLetterType = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        setLoader(true)
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_ClassificationWiseLetterType?M_OfficeMasterID=${M_OfficeMasterID}&LetterClassificationID=${M_LetterClassificationID}&IsPortalAdmin=${IsProtalAdmin}&UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setM_ClassificationWiseLetterType(result.data)

                } else {
                    setM_ClassificationWiseLetterType([])

                }
                setLoader(false)
            })
            .catch(error => console.log('error', error));
    }

    const Get_DDL_M_LetterClassification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_LetterClassification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setM_LetterClassification(result.data)

                } else {
                    setM_LetterClassification([])

                }
            })
            .catch(error => console.log('error', error));
    }

    const Get_DDL_M_OfficeTypeMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_DepartmentNameMaster?M_OfficeMasterID=${M_OfficeMasterID}&IsPortalAdmin=${IsProtalAdmin}&UserID=${UserId}`, requestOptions)
            // fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_OfficeTypeMaster`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setM_OfficeTypeMaster(result.data)
                    setofficescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))

                } else {
                    setM_OfficeTypeMaster([])

                }

            })
            .catch(error => console.log('error', error));
    }



    const Get_DDL_M_OfficeMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_OfficeMaster?M_OfficeTypeMasterID=0&UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setM_OfficeMaster(result.data)

                } else {
                    setM_OfficeMaster([])

                }
            })
            .catch(error => console.log('error', error));
    }

    const Get_DDL_M_BranchNameMaster1 = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        // fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_BranchNameMaster?M_OfficeMasterID=${M_OfficeMasterID}&IsPortalAdmin=${IsProtalAdmin}&UserID=${UserId}`, requestOptions)
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Form_M_BranchNameMaster?M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setDDL_M_BranchNameMaster(result.data)

                } else {
                    setDDL_M_BranchNameMaster([])
                }
            })
            .catch(error => {
                // alert.error("Something went wrong")
            });
    }

    const Get_DDL_Outward_Close_Type = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Outward_Close_Type?UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    // setDDL_Outward_Close_Type(result.data)
                    var data = result.data.splice(3, 1)
                    setDDL_Outward_Close_Type(result.data)

                } else {
                    setDDL_Outward_Close_Type([])

                }
            })
            .catch(error => console.log("Something went wrong"));
    }

    const Get_DDL_Outward_Javak_Type = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Letter_OutwardByIndicator_Select?UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setDDL_Outward_Javak_Type(result.data)

                } else {
                    setDDL_Outward_Javak_Type([])

                }
            })
            .catch(error => console.log("Something went wrong"));
    }


    // React.useEffect(() => {
    //     Get_DDL_LetterTypeStatus()
    //     Get_DDL_M_ClassificationWiseLetterType()
    //     Get_DDL_M_LetterClassification()
    //     Get_DDL_M_OfficeTypeMaster()
    //     Get_DDL_M_OfficeMaster()
    //     Get_DDL_M_BranchNameMaster()
    //     Get_DDL_Outward_Close_Type()
    //     setTableUserCheckError('')
    // }, [M_LetterClassificationID])

    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        Get_DDL_LetterTypeStatus()
        Get_DDL_M_LetterClassification()
        Get_DDL_M_OfficeTypeMaster()
        Get_DDL_M_OfficeMaster()
        Get_DDL_M_BranchNameMaster()
        Get_DDL_OutwardDivisonName()
        Get_DDL_Outward_Close_Type()
        // setTableUserCheckError('')
        Get_DDL_Outward_Javak_Type()
    }

    React.useEffect(() => {
        setShowLable('')
        if (M_LetterClassificationID !== '') {
            Get_DDL_M_ClassificationWiseLetterType()
        }
    }, [M_LetterClassificationID])

    const Get_DDL_Outward_M_OfficeMasterSelect = (checkdata) => {
        setTableData([])
        setTableLoader(true)
        setContentLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Inward_Forward_M_TableUserMaster?M_DepartmentMasterID=${checkdata}&M_OfficeMasterID=${M_OfficeMasterID}&UserID=${UserId}`, requestOptions)
            // fetch(`${baseUrl}/CommonDLLData/Get_DDL_Outward_M_BranchNameMaster_Select?M_OfficeTypeMasterID=${checkdata}&UserID=${UserId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setDDL_Outward_M_OfficeMasterSelect(result.data)
                    setofficetypescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))

                } else {

                    setDDL_Outward_M_OfficeMasterSelect(null)
                }

                setTableLoader(false)
                setContentLoader(false)

            })
            .catch(error => {
                // alert.error("Something went wrong")
                setTableLoader(false)
                setContentLoader(false)
                setDDL_Outward_M_OfficeMasterSelect(null)
            });
    }


    const handleLetterClassifcationType = (text) => {
        setM_ClassificationWiseLetterTypeID(text)
        if (text === "null") {
            setClassificationError("पत्र प्रकार अनिवार्य आहे")
        }
        else {
            setClassificationError('')
        }
    }

    const handleLetterMedium = (text) => {
        setLetterMediumIndicatorID(text)
        if (text === "null") {
            setLetterMediumError("पत्र माध्यम अनिवार्य आहे")
        }
        else {
            setLetterMediumError('')
        }
    }

    const handleLetterClassifcation = (text) => {
        setM_LetterClassificationID(text)
        if (text === "null") {
            setLetterClassificationError("पत्र वर्गवारी अनिवार्य आहे")
        }
        else {
            setLetterClassificationError('')
        }

    }

    const handleOfficeName = (text) => {
        setOfficeName(text)
        if (text.length === 0) {
            setOfficeNameError("कार्यालयाचे नाव अनिवार्य आहे")
        } else if (text.length >= 150) {

            setOfficeNameError("कार्यालयाचे नाव केवळ 150 वर्णांचा असणे आवश्यक आहे")
        }
        else {
            setOfficeNameError('')
        }

    }

    const handleRelatedAddress = (text) => {
        setRelatedAddress(text)
        if (text.length === 0) {
            setRelatedAddressError("संबंधितांचे नाव व पत्ता अनिवार्य आहे")
        } else if (text.length >= 400) {
            setRelatedAddressError("संबंधितांचे नाव व पत्ता केवळ 400 वर्णांचा असणे आवश्यक आहे")

        }
        else {
            setRelatedAddressError('')
        }

    }

    const handleEmail = (text) => {
        setEmail(text)
        let regx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (regx.test(text) !== false) {
            setEmailError('')
        }
        else if (text.length === 0) {
            setEmailError('')
        } else {
            setEmailError('ई-मेल अवैध आहे')
        }
        // console.log(text.length)

    }

    const handleBranchError = (text) => {
        setM_BranchNameMasterID(text)
        if (text === "null") {
            setBranchError("शाखा अनिवार्य आहे")
        }
        else {
            setBranchError('')
        }

    }

    const handleActionError = (e) => {
        if (e.target.text === "null") {
            setActitionError("कार्यवाही प्रकार अनिवार्य आहे")
        }
        else {
            setActitionError('')
            setactionType(e.target.value)

        }

    }

    const handlejavakError = (e) => {
        setPostAmount('')
        if (e.target.text === "null") {
            setJavakError("कार्यवाही प्रकार अनिवार्य आहे")
        }
        else {
            setJavakError('')
            setjavakType(e.target.value)

        }

    }

    const handleOutwardDate = (text) => {
        setOutwardDate(text)
        if (text === "") {
            setOutwardDateError("जावक दिनांक अनिवार्य आहे")
        }
        else {
            setOutwardDateError('')
        }
    }

    const handleSubject = (text) => {
        setLetterSubject(text)
        if (text.length === 0) {
            setLetterSubjectError("पत्राचा विषय अनिवार्य आहे")
        } else if (text.length >= 300) {
            setLetterSubjectError("पत्राचा विषय केवळ 300 वर्णांचा असणे आवश्यक आहे")
        }
        else {
            setLetterSubjectError('')
        }
    }

    var baseURL

    const getBase64 = (file) => {
        return new Promise(resolve => {
            baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                // console.log("Called", reader);
                baseURL = reader.result;
                // console.log(baseURL);
                resolve(baseURL);
            };
        });
    };

    const handleFileInputChange = (target) => {
        setImageFile(target.files[0])
        getBase64(target.files[0])
            .then(result => {
                setbase64URL(result.split(',')[1])
                // result.substr(result.indexOf(',') + 1)
            })
            .catch(err => {
                console.log(err);
            });
        setImageFile(target.files[0])
    };


    const RequiredSize = 5242880

    const DocumentValidator = (text) => {
        if (text.files.length !== 0) {
            if (LetterMediumIndicatorID === "8") {
                setfileName(text.files[0].name)
                var file1 = text.files[0].name.split('.').pop()
                var file = file1.toLowerCase()
                handleFileInputChange(text)
                if (text.files[0].size > RequiredSize && file !== "pdf") {
                    setLetterDocError("संबंधीत पत्राची साइज 5 mb पेक्षा कमी आणि .pdf फॉरमॅट मध्ये निवडावी.")
                }
                else if (text.files[0].size > RequiredSize) {
                    setLetterDocError("संबंधीत पत्राची साइज 5 mb पेक्षा कमी निवडावी.")
                }
                else if (text.files[0] === 0) {
                    setLetterDocError("संबंधीत पत्र अनिवार्य आहे")
                }
                else if (file !== "pdf") {
                    setLetterDocError('संबंधीत पत्र .pdf फॉरमॅट मध्ये असावे')
                }
                else {
                    setLetterDocError('')
                }
            }
            else {
                // setLetterDocError('')
                setImageFile('')
            }
        }

    }


    const clearFields = () => {
        setM_LetterClassificationID("")
        setM_ClassificationWiseLetterTypeID("")
        setLetterSubject("")
        setM_BranchNameMasterID("")
        setLetterMediumIndicatorID("")
        setbase64URL("")
        setfileName(null)
        setactionType("")
        setjavakType("")

        setOfficeNameIDs("")
        // setOutwardDate(moment().format("YYYY-MM-DD"))
        GetCurrentDate()
        setotherDepartmentID("")
        setRemark("")
        setOfficeName("")
        setRelatedAddress("")
        setEmail("")
        setIsCheckAll(false)
        setOfficeIsCheckAll(false)
        setEtrChecked(false)
        setIsCheck([])
        setOfficeIsCheck([])
        setDDL_Outward_M_OfficeMasterSelect(null)
        setTableData([])
        setTrueData([])
        setofficetypescustomdata([])
        loadData()
        setShowLable('')

        settablescustomdata([]);
        // setbranchescustomdata([]);

        document.getElementById("selectAll6").checked = false;
        // document.getElementById("selectAll7").checked = false;
        // window.location.reload()
    }
    const PostData = () => {
        const PostMsg = toast.loading("Please wait...")
        setLoader(true)
        setshowAlert(false)
        var formdata = new FormData();
        formdata.append("M_OfficeMaster_ID", M_OfficeMasterID);
        formdata.append("M_LetterClassificationID", M_LetterClassificationID);
        formdata.append("M_ClassificationWiseLetterTypeID", M_ClassificationWiseLetterTypeID);
        formdata.append("LetterSubject", LetterSubject);
        formdata.append("OutwardBranchID", M_BranchNameMasterID);
        formdata.append("LetterMediumIndicatorID", LetterMediumIndicatorID);
        formdata.append("OutwardByID", javakType);
        if (base64URL === null) {
            formdata.append("FilePath", '');
        }
        else {
            formdata.append("FilePath", base64URL);
        }
        formdata.append("CloseTypeIndicatorID", actionType);
        // formdata.append("M_BranchNameMasterID", TableData);
        formdata.append("M_TableUserMasterID", TableData);
        formdata.append("OutwardDate", OutwardDate);
        formdata.append("OutwardFlag", "New Outward");
        formdata.append("FlagIsOtherChecked", EtrChecked ? "1" : "0");
        formdata.append("Remark", Remark);
        formdata.append("PostTicketAmount", PostAmount);
        formdata.append("OfficeDivisionName", OfficeName);
        formdata.append("OfficeDivisionAddress", RelatedAddress);
        formdata.append("OfficeDivisionEmail", Email);
        formdata.append("TableUserMasterID", UserId);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: myHeaders,
        };
        // console.log("data===========",formdata)
        fetch(`${baseUrl}/OutwardMaster/Post_Outward_MasterNewLetterInsert`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300) {
                    if (result.status) {
                        setTimeout(() => {
                            toast.update(PostMsg, { render: result.message, type: "success", isLoading: false, autoClose: 2000, hideProgressBar: false });
                        }, 800);
                        setAfterPostshowAlert(true)
                        setAfterPostRes(result.data && result.data[0] ? result.data[0].outwardNumber : '')
                        if (result.data && result.data[0]) {
                            handleGetToken(result.data && result.data[0] ? result.data[0].notificationCount : '')
                        }
                    } else {
                        setTimeout(() => {
                            toast.update(PostMsg, { render: result.message, type: "error", isLoading: false, autoClose: 2000, hideProgressBar: false });
                        }, 800);
                    }
                    setTimeout(() => {
                        clearFields()
                    }, 200)
                } else {
                    // alert.error(result.message);
                    setShowLable('Error : ' + result.message)
                }

                setLoader(false)
            })
            .catch(error => console.log('error', error));

    }

    const handleGetToken = (NotificationCount) => {
        GetUserToken({
            NotificationCount: NotificationCount,
            TypeFlag: 'NewOutward',
            UserID: UserId,
            setUserData,
            handleSendNotification: handleSendNotification
        })
    }


    const handleSendNotification = (data) => {
        data.forEach(function (e, i) {
            SendNotification({ androidDeviceId: data[i].deviceID, body: data[i].messageBody, title: data[i].notificationTital })
        });
    }


    const checkValidation = () => {
        return TableUserCheckError !== "" || M_LetterClassificationID === "" || OutwardDate === "" || M_ClassificationWiseLetterTypeID === "" || LetterSubject === "" || LetterMediumIndicatorID === "" || actionType === "" || javakType === "" || (LetterMediumIndicatorID === "8" && base64URL === null) || Remark === "" || (LetterMediumIndicatorID === "8" && LetterDocError !== "" && fileName === null) || LetterSubjectError !== '' || Remark.length >= 300
        // || EmailError !== "" || OfficeName === "" || RelatedAddress === ""  || OfficeNameError !== '' || RelatedAddressError !== ''
    }


    const checkEterValidation = () => {
        if (tablescustomdata && tablescustomdata.length === 0) {
            return M_LetterClassificationID === "" || OutwardDate === "" || M_ClassificationWiseLetterTypeID === "" || LetterSubject === "" || LetterMediumIndicatorID === "" || actionType === "" || javakType === "" || (LetterMediumIndicatorID === "8" && base64URL === null) || Remark === "" || (LetterMediumIndicatorID === "8" && LetterDocError !== "" && fileName === null) || LetterSubjectError !== '' || Remark.length >= 300
                || EmailError !== "" || OfficeName === "" || RelatedAddress === "" || OfficeNameError !== '' || RelatedAddressError !== ''
        } else {
            return M_LetterClassificationID === "" || OutwardDate === "" || M_ClassificationWiseLetterTypeID === "" || LetterSubject === "" || LetterMediumIndicatorID === "" || actionType === "" || javakType === "" || (LetterMediumIndicatorID === "8" && base64URL === null) || Remark === "" || (LetterMediumIndicatorID === "8" && LetterDocError !== "" && fileName === null) || LetterSubjectError !== '' || Remark.length >= 300
                || EmailError !== "" || OfficeName === "" || RelatedAddress === "" || OfficeNameError !== '' || RelatedAddressError !== ''
                || TableUserCheckError !== ''
        }
    }

    const GetCurrentDate = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch("https://worldtimeapi.org/api/ip", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                setOutwardDate(moment(result.datetime).format('YYYY-MM-DD'))
            })
            .catch(error => {
                setOutwardDate(moment().format('YYYY-MM-DD'))
                console.log('error', error)
            });
    }

    React.useEffect(() => {
        GetCurrentDate()
    }, [])

    const handlePostAmount = (text) => {
        setPostAmount(text)
        // let regx1 = /^[०-९]*$/;
        let regx = /^[0-9]*$/;
        if (regx.test(text) !== false) {
            setPostAmountError("")
            if (text.length === 0) {
                // setPeriodDayError("संख्या अवैध आहे")
            }
        }
        else {
            setPostAmountError("संख्या अवैध आहे")
        }
    }


    // console.log("officetypescustomdata", officetypescustomdata)

    return (
        <>
            {loader && <Loader fullPage loading />}

            <div>
                {/* <Loader/> */}

                <div className="wrapper">

                    <NavbarLetter active="NewOutwardLetter" />
                    <div className="main">
                        <Header />
                        <main className="content">
                            {
                                SaveLetter ?
                                    <SweetAlert
                                        custom
                                        cancelBtnText="OK"
                                        // openAnim={{ name: 'showSweetAlert', duration: 1000 }}
                                        // closeAnim={{ name: 'hideSweetAlert', duration: 1000 }}
                                        customIcon="assets/img/thumbs-up.jpg"
                                        title="यशस्वीरित्या पूर्ण झाले !"
                                        onConfirm={SaveSucess}
                                    >
                                    </SweetAlert> : <></>
                            }

                            <div className="container-fluid p-0">

                                <div className="row mb-2 mb-xl-2 ml-auto" style={{ marginTop: '-2%' }}>
                                    <div className="col-auto d-sm-block">
                                        <h2>नविन जावक पत्र नोंद </h2>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12 d-flex">
                                        <div className="card w-100">
                                            <div className="card-body w-100">
                                                {/* <div className="col-auto d-sm-block">
                                                    <h2 style={{ marginLeft: '35%', top: '10%' }}>नविन जावक पत्र नोंद </h2>
                                                </div> */}
                                                <form role="form" method="POST" action="">
                                                    <input type="hidden" name="_token" value="" />
                                                    <div className="form-row row">



                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="LetterDate">जावक दिनांक<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <input
                                                                    readOnly
                                                                    max={moment().format("YYYY-MM-DD")}
                                                                    type="date"
                                                                    className="form-control input-lg datetime"
                                                                    name="LetterDate"
                                                                    id="LetterDate"
                                                                    value={OutwardDate ? OutwardDate : moment().format("YYYY-MM-DD")}
                                                                    onChange={(e) => handleOutwardDate(e.target.value)}
                                                                />
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{OutwardDateError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="priority">पत्र वर्गीकरण<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <select
                                                                    className="form-control input-lg"
                                                                    name="letterSorting"
                                                                    id="letterSorting"
                                                                    value={M_LetterClassificationID}
                                                                    onChange={(e) => {
                                                                        handleLetterClassifcation(e.target.value)
                                                                        setM_ClassificationWiseLetterTypeID('')
                                                                    }}
                                                                >
                                                                    <option value={""} disabled>निवडा...</option>
                                                                    {
                                                                        M_LetterClassification !== null ? M_LetterClassification.map(item =>
                                                                            <option value={item.id}>{item.classificationName}</option>
                                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                                    }

                                                                </select>
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{LetterClassificationError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="priority">पत्र प्रकार<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <select
                                                                    disabled={M_LetterClassificationID === '' || M_LetterClassificationID === "null"}
                                                                    className="form-control input-lg"
                                                                    name="priority"
                                                                    id="priority"
                                                                    value={M_ClassificationWiseLetterTypeID}
                                                                    onChange={(e) => handleLetterClassifcationType(e.target.value)}

                                                                >
                                                                    <option value={""} disabled>निवडा...</option>
                                                                    {
                                                                        M_ClassificationWiseLetterType !== null ? M_ClassificationWiseLetterType.map(item =>
                                                                            <option value={item.id}>{item.letterTypeName}</option>
                                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                                    }
                                                                </select>
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{ClassificationError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="received">पत्र माध्यम<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <select
                                                                    className="form-control input-lg"
                                                                    name="received"
                                                                    id="received"
                                                                    value={LetterMediumIndicatorID}
                                                                    onChange={(e) => handleLetterMedium(e.target.value)}
                                                                >
                                                                    <option value={""} disabled>निवडा...</option>
                                                                    {
                                                                        DDL_LetterTypeStatus !== null ? DDL_LetterTypeStatus.map(item =>
                                                                            <option value={item.indicatorId}>{item.indicatorName}</option>
                                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                                    }
                                                                </select>
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{LetterMediumError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 form-group">
                                                            <label className="control-label" for="officeName">पत्राचा विषय<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control input-lg"
                                                                    name="officeName"
                                                                    id="officeName"
                                                                    rows={1}
                                                                    value={LetterSubject}
                                                                    onChange={(e) => handleSubject(e.target.value)}
                                                                    maxLength={300}
                                                                />
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{LetterSubjectError}</text>

                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="priority">कार्यवाही प्रकार<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <select
                                                                    className="form-control input-lg"
                                                                    name="priority"
                                                                    id="priority"
                                                                    value={actionType}
                                                                    onChange={handleActionError}
                                                                >
                                                                    <option value={""} disabled>निवडा...</option>
                                                                    {
                                                                        DDL_Outward_Close_Type !== null ? DDL_Outward_Close_Type.map(item =>
                                                                            <option value={item.indicatorId}>{item.indicatorName}</option>) :
                                                                            <option disabled>माहिती उपलब्ध नाही</option>
                                                                    }

                                                                </select>
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{ActitionError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 form-group">
                                                            <label className="control-label" for="priority">जावक द्वारा<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <select
                                                                    className="form-control input-lg"
                                                                    name="priority"
                                                                    id="priority"
                                                                    value={javakType}
                                                                    onChange={handlejavakError}
                                                                >
                                                                    <option value={""} disabled>निवडा...</option>
                                                                    {
                                                                        DDL_Outward_Javak_Type !== null ? DDL_Outward_Javak_Type.map(item =>
                                                                            <option value={item.indicatorId}>{item.indicatorName}</option>) :
                                                                            <option disabled>माहिती उपलब्ध नाही</option>
                                                                    }

                                                                </select>
                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{JavakError}</text>
                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            javakType == 15 || javakType == 16 ?
                                                                <div className="col-md-2 form-group">
                                                                    <label className="control-label" for="outwardNo">Post Ticket Amount<text style={{ color: 'red' }}></text></label>
                                                                    <div>
                                                                        <input
                                                                            value={PostAmount}
                                                                            type="text"
                                                                            className="form-control input-lg"
                                                                            name="outwardNo"
                                                                            id="outwardNo"
                                                                            onChange={(e) => handlePostAmount(e.target.value)}
                                                                        // maxLength={300}
                                                                        />
                                                                        {
                                                                            <text style={{ color: 'red', fontSize: 13 }}>{PostAmountError}</text>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }

                                                        <div className="col-lg-4 col-sm-12" style={{}}>

                                                            <div id='div' style={{ marginBottom: '3%', marginTop: '1%', height: '48vh' }}>
                                                                <h1 id="ti"><span style={{ marginLeft: '2%', marginTop: '-2%' }}>&nbsp;&nbsp;कार्यालय प्रकार<text style={{ color: 'red' }}> *</text>&nbsp;&nbsp;</span></h1>

                                                                <div style={{ marginLeft: '2%' }}><input
                                                                    type='checkbox'
                                                                    id="selectAll6"
                                                                    name="selectAll"
                                                                    onChange={handleBranchSelectAll}
                                                                    isChecked={isCheckAll}
                                                                />
                                                                    <label className="control-label" for="officeName">&nbsp;&nbsp;सर्व</label>

                                                                </div>


                                                                <div style={{
                                                                    // borderWidth: '1px',
                                                                    // borderStyle: 'solid',
                                                                    // borderColor: 'grey',
                                                                    paddingTop: '1%',
                                                                    paddingLeft: '7%',
                                                                    marginBottom: '2%',
                                                                    overflowY: 'auto',
                                                                    height: '259px'
                                                                }}>
                                                                    {

                                                                        branchescustomdata !== null ?
                                                                            branchescustomdata.map(item => {
                                                                                // const main = isCheck[item.id]
                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            isCheckAll === true ?
                                                                                                <input
                                                                                                    type='checkbox'
                                                                                                    checked={isCheck}
                                                                                                /> :
                                                                                                <input
                                                                                                    key={item.id}
                                                                                                    checked={item.isChecked}
                                                                                                    value={item.officeTypeName}
                                                                                                    type='checkbox'
                                                                                                    // style={{wordBreak:'break-all'}}
                                                                                                    onChange={() => handleBranchChange(item)}
                                                                                                // onChange={(e) => console.log({ ...isCheck, [item.id]: !main }, (item.id))}
                                                                                                // disabled={}
                                                                                                />
                                                                                        }
                                                                                        <label className="control-label" style={{ display: 'inline', fontSize: '14px' }} for="officeName">&nbsp;&nbsp;{item.officeTypeName}</label>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            ) : <text>माहिती उपलब्ध नाही</text>
                                                                    }
                                                                </div>

                                                            </div>
                                                            {
                                                                !EtrChecked ?
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{BranchCheckError}</text> : ''
                                                            }
                                                        </div>

                                                        <div className="col-lg-4 col-sm-12" style={{}}>

                                                            <div id='div' style={{ marginBottom: '3%', marginTop: '1%', height: '48vh' }}>
                                                                <h1 id="ti"><span style={{ marginLeft: '2%', marginTop: '-2%' }}>&nbsp;&nbsp;विभाग<text style={{ color: 'red' }}> *</text>&nbsp;&nbsp;</span></h1>

                                                                {/* <div style={{ marginLeft: '2%' }}><input
                                                                    type='checkbox'
                                                                    id="selectAll8"
                                                                    name="selectAll"
                                                                    onChange={handleOutwardDivisonSelectAll}
                                                                    isChecked={isCheckAll}
                                                                />
                                                                    <label className="control-label" for="officeName">&nbsp;&nbsp;सर्व</label>

                                                                </div> */}


                                                                <div style={{
                                                                    // borderWidth: '1px',
                                                                    // borderStyle: 'solid',
                                                                    // borderColor: 'grey',
                                                                    paddingTop: '1%',
                                                                    paddingLeft: '7%',
                                                                    marginBottom: '2%',
                                                                    overflowY: 'auto',
                                                                    height: '259px'
                                                                }}>
                                                                    {
                                                                        OutwardDivisoncustomdata !== null ?
                                                                            OutwardDivisoncustomdata.map(item => {
                                                                                // const main = isCheck[item.id]
                                                                                return (
                                                                                    <div>
                                                                                        {/* {
                                                                                            isCheckAll === true ?
                                                                                                <input
                                                                                                    type='checkbox'
                                                                                                    checked={isCheck}
                                                                                                /> : */}
                                                                                        <input
                                                                                            key={item.id}
                                                                                            checked={item.isChecked}
                                                                                            value={item.divisonName}
                                                                                            type='checkbox'
                                                                                            // style={{wordBreak:'break-all'}}
                                                                                            onChange={() => handleOutWardDivisionChange(item)}
                                                                                        // onChange={() => setOutWardDivisionData(item.id)}
                                                                                        // onChange={(e) => console.log({ ...isCheck, [item.id]: !main }, (item.id))}
                                                                                        // disabled={}
                                                                                        />
                                                                                        {/* } */}
                                                                                        <label className="control-label" style={{ display: 'inline', fontSize: '14px' }} for="officeName">&nbsp;&nbsp;{item.divisonName}</label>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            ) : <text>माहिती उपलब्ध नाही</text>
                                                                    }
                                                                </div>

                                                            </div>
                                                            {
                                                                !EtrChecked ?
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{BranchCheckError}</text> : ''
                                                            }
                                                        </div>

                                                        <div className="col-lg-4 col-sm-12" style={{}}>
                                                            <div id='div' style={{ marginBottom: '3%', marginTop: '0.5%', height: '48vh' }}>
                                                                <h1 id="ti"><span style={{ marginLeft: '2%', marginTop: '-2%' }}>&nbsp;&nbsp;कार्यालयाचे नाव<text style={{ color: 'red' }}> *</text>&nbsp;&nbsp;</span></h1>

                                                                {/* <div className="form-group" style={{ marginBottom: '1%' }}>
    <label className="control-label" for="officeName">कार्यासनाचे नाव<text style={{ color: 'red' }}>*</text></label>
</div> */}
                                                                {
                                                                    tablescustomdata && tablescustomdata.length > 0 ?
                                                                        <div style={{ marginLeft: '2%' }}>
                                                                            <input
                                                                                type='checkbox'
                                                                                id="selectAll7"
                                                                                name="selectAll"
                                                                                onChange={handleTableSelectAll}
                                                                                isChecked={IsTableCheckAll}
                                                                                disabled={tablescustomdata.length === 0}
                                                                            />
                                                                            <label className="control-label" for="officeName">&nbsp;&nbsp;सर्व</label>
                                                                        </div>
                                                                        :
                                                                        ''
                                                                }


                                                                <div style={{
                                                                    // borderWidth: '1px',
                                                                    // borderStyle: 'solid',
                                                                    // borderColor: 'grey',
                                                                    paddingTop: '1%',
                                                                    paddingLeft: '7%',
                                                                    marginBottom: '2%',
                                                                    overflowY: 'auto',
                                                                    height: '259px'
                                                                }}>

                                                                    {
                                                                        (tablescustomdata && tablescustomdata.length > 0) ?
                                                                            tablescustomdata.map((item) => {
                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            IsTableCheckAll === true ?
                                                                                                <input
                                                                                                    type='checkbox'
                                                                                                    checked={IsTableCheckAll}
                                                                                                /> :

                                                                                                <input
                                                                                                    key={item.id}
                                                                                                    checked={item.isChecked}
                                                                                                    value={item.officeName}
                                                                                                    type='checkbox'
                                                                                                    onChange={() => handleTableChange(item)}

                                                                                                />
                                                                                        }
                                                                                        <label className="control-label" style={{ display: 'inline', fontSize: '14px' }} for="officeName">&nbsp;&nbsp;{item.officeName}</label>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            ) : <text>माहिती उपलब्ध नाही</text>
                                                                    }
                                                                    <div style={{ paddingLeft: '100px', paddingTop: '70px' }}>
                                                                        <SyncLoader color={'#7BAEE4'} loading={TableLoader} css={override} size={10} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                !EtrChecked && (tablescustomdata && tablescustomdata.length !== 0) ?
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{TableUserCheckError}</text> : ''
                                                            }
                                                        </div>

                                                        <div className="col-sm-12" style={{ marginTop: '1%', marginBottom: '-1%' }}>

                                                            <div className="form-group" >
                                                                <input type='checkbox' checked={EtrChecked} onChange={handleChange}
                                                                // disabled={tablescustomdata.length !== 0}
                                                                />
                                                                <label className="control-label mx-2" for="officeName">इतर विभागासाठी</label>
                                                            </div>
                                                        </div>
                                                        {
                                                            EtrChecked ?
                                                                <>
                                                                    <div className="col-md-4 form-group">
                                                                        <label className="control-label" for="priority">कार्यालयाचे नाव<text style={{ color: 'red' }}>*</text></label>
                                                                        <div>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control input-lg"
                                                                                name="officeName"
                                                                                id="officeName"
                                                                                value={OfficeName}
                                                                                onChange={(e) => handleOfficeName(e.target.value)}
                                                                                maxLength={150}
                                                                            />
                                                                            {
                                                                                <text style={{ color: 'red', fontSize: 13 }}>{OfficeNameError}</text>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 form-group">
                                                                        <label className="control-label" for="priority">संबंधितांचे नाव व पत्ता<text style={{ color: 'red' }}>*</text></label>
                                                                        <div>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control input-lg"
                                                                                name="officeName"
                                                                                id="officeName"
                                                                                value={RelatedAddress}
                                                                                maxLength={400}
                                                                                onChange={(e) => handleRelatedAddress(e.target.value)}
                                                                            />
                                                                            {
                                                                                <text style={{ color: 'red', fontSize: 13 }}>{RelatedAddressError}</text>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 form-group">
                                                                        <label className="control-label" for="priority">इ मेल</label>
                                                                        <div>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control input-lg"
                                                                                name="officeName"
                                                                                id="officeName"
                                                                                value={Email}
                                                                                onChange={(e) => handleEmail(e.target.value)}
                                                                                maxLength={100}
                                                                            />
                                                                            {
                                                                                <text style={{ color: 'red', fontSize: 13 }}>{EmailError}</text>
                                                                            }
                                                                            {Email.length >= 100 && <p style={{ color: 'red', fontSize: 13 }}>ई-मेल केवळ 100 वर्णांचा असणे आवश्यक आहे</p>}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''
                                                        }


                                                        {LetterMediumIndicatorID === "8" &&
                                                            <div className="col-md-6 form-group">
                                                                <label className="control-label" for="uploadLetter">संबंधीत पत्र अपलोड करा<text style={{ color: 'red' }}>*</text></label>
                                                                <div className="custom-file">
                                                                    <input
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        id="uploadLetter"
                                                                        accept="application/pdf"
                                                                        onChange={(e) => { DocumentValidator(e.target); }}
                                                                    // onChange={(e)=>console.log(e.target.files[0])}
                                                                    />
                                                                    <label className="custom-file-label" for="uploadLetter" data-browse="अपलोड करा">
                                                                        {
                                                                            fileName === null ? "पत्र अपलोड करा" : fileName
                                                                        }
                                                                    </label>
                                                                </div>
                                                                {/* <text style={{ color: 'red', fontSize: 13 }}>कृपया पत्र PDF फॉरमॅट मध्ये आणि 2 एमबी पेक्षा कमी निवडा</text><br /> */}

                                                                {
                                                                    <text style={{ color: 'red', fontSize: 13 }}>{LetterDocError}</text>
                                                                }
                                                            </div>

                                                        }

                                                        <div className="col-md-6 form-group">
                                                            <label className="control-label" for="officeName">शेरा<text style={{ color: 'red' }}>*</text></label>
                                                            <div>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="body"
                                                                    rows="1"
                                                                    value={Remark}
                                                                    onChange={(e) => setRemark(e.target.value)}
                                                                    maxLength={300}
                                                                />
                                                                {Remark.length >= 300 && <p style={{ color: 'red', fontSize: 13 }}>शेरा केवळ 300 वर्णांचा असणे आवश्यक आहे</p>}
                                                            </div>
                                                        </div>

                                                        <div className="form-group mt-5 mt-md-1">
                                                            <div style={{ position: 'absolute', right: '110px', bottom: '2%' }}>
                                                                {/* {console.log( M_LetterClassificationID==="" || OutwardDate==="" || M_ClassificationWiseLetterTypeID==="" || M_BranchNameMasterID==="" || LetterSubject==="" || LetterMediumIndicatorID==="" || actionType==="" || OfficeNameIDs===null || base64URL===null || Remark==="" || (EtrChecked && (OfficeNameError==="" && RelatedAddressError ==="")) )} */}
                                                                <button
                                                                    // disabled={checkValidation()}
                                                                    disabled={!EtrChecked ? checkValidation() : checkEterValidation()}
                                                                    type="button" className="btn btn-primary btn-md"
                                                                    onClick={() => { setLoader(true); setshowAlert(true) }}
                                                                >Outward</button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div style={{ position: 'absolute', right: '35px', bottom: '2%' }}>
                                                                <button type="button" className="btn btn-danger btn-md control-label" onClick={clearFields}>Clear</button>
                                                            </div>
                                                        </div>

                                                        {
                                                            ShowLable ?

                                                                <div className="col-md-9 form-group" style={{ backgroundColor: '#effb74cc', padding: '1.5%' }}>
                                                                    <lable className="control-label">
                                                                        <span style={{ color: 'orangered', textTransform: 'uppercase' }}>
                                                                            {ShowLable ? ShowLable : ''}
                                                                        </span>
                                                                    </lable>
                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                        <Footer />
                    </div >
                </div >
            </div >
            {
                showAlert ?

                    <SweetAlert
                        type="info"
                        title="तुम्हाला खात्री आहे की तुम्ही नविन जावक पत्र नोंद करू इच्छिता ? "
                        onConfirm={PostData}
                        confirmBtnText="Outward"
                        onCancel={() => { setLoader(false); setshowAlert(false) }}
                        // openAnim={{ name: 'showSweetAlert', duration: 1000 }}
                        // closeAnim={{ name: 'hideSweetAlert', duration: 1000 }}
                        showCloseButton
                        showCancel={true}
                        cancelBtnText="Cancel"
                        cancelBtnCssClass="btn btn-danger"
                    >
                    </SweetAlert> : <></>

            }

            {
                AfterPostshowAlert ?

                    <SweetAlert
                        // type="info"
                        title={`जावक क्रमांक \n --> ${AfterPostRes}`}
                        onConfirm={() => {
                            setAfterPostshowAlert(false)
                        }}
                        confirmBtnText="OK"
                        onCancel={() => {
                            setAfterPostshowAlert(false)
                        }}
                        // openAnim={{ name: 'showSweetAlert', duration: 1000 }}
                        // closeAnim={{ name: 'hideSweetAlert', duration: 1000 }}
                        showCloseButton
                        showCancel={false}

                        cancelBtnText="Cancel"
                        cancelBtnCssClass="btn btn-danger"
                        focusConfirmBtn
                    >
                    </SweetAlert> : <></>

            }
        </>
    )
}
