
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom"
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Loader } from "react-overlay-loader";
// import ViewSingleLetterDetails from "./ViewSingleLetterDetails";
import ExportViewData from "./ExportViewData";
import { baseUrl } from "../../../../../BaseURL/BaseURL";
import ViewSingleLetterDetails from "./ViewSingleLetterDetails";
import Track from "../../../Commisioner/SPDashboard/Dashboard/PopUp/Track";
import $ from 'jquery'

const ViewLettersPopUp = ({
    sethideShowViewLetterPopUp,
    allPropsPopUpData,
    setallPropsPopUpData,

}) => {
    let UserID = sessionStorage.getItem("User")
    const [gridData, setgridData] = useState([])
    const [exportgridData, setexportgridData] = useState([])
    const [exportg, setexportg] = useState(false)

    const [PerPage, setPerPage] = useState(20)
    const [loading, setloading] = useState(false)
    let {
        heading,
        heading2,
        filterID,
        flag,
        showBy,
        letterClassificationID,
        tabledata,
        type,
        fileflag
    } = allPropsPopUpData

    $(function () {
        $(".modal-header").on("mousedown", function (e) {
            var $draggable = $(this);
            var x = e.pageX - $draggable.offset().left,
                y = e.pageY - $draggable.offset().top;
            $("body").on("mousemove", function (mousemoveEvt) {
                $draggable.closest(".modal-dialog").offset({
                    "left": mousemoveEvt.pageX - x,
                    "top": mousemoveEvt.pageY - y
                });
            });
            $("body").one("mouseup", function () {
                $("body").off("mousemove");
            });
        })
    })

    const [singleLetter, setsingleLetter] = useState({})

    var month = (new Date().getMonth()) + 1
    var year = new Date().getFullYear()

    const loadGridData = () => {
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        // console.log(tabledata)


        var url
        if (tabledata === 'table') {
            url = `${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalLetterClassificationDetails_Select?FilterID=${filterID}&M_LetterTypeID=${letterClassificationID}&Flag=${flag}&UserID=${UserID}&Top=${PerPage}`
        }
        else if (type === 'file') {
            url = `${baseUrl}/CommDashBoard/Get_DB_ALL_COMM_File_FinalDrill_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${fileflag}&ShowBy=ReferenceType&FilterID=${filterID}&UserID=${UserID}&M_ReferenceTypeID=0&Top=${PerPage}`
        }
        else {
            url = `${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalDetails_Select?FilterID=${filterID}&Flag=${flag}&ShowBy=${showBy}&M_LetterClassificationID=${letterClassificationID}&UserID=${UserID}&Top=${PerPage}`
        }

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data) {
                    setgridData(result.data)

                }
                setloading(false)


            })
            .catch(error => {
                console.log('error', error)
                setloading(false)

            });
    }

    React.useEffect(() => {
        ExportloadGridData()
    }, [])

    const ExportloadGridData = () => {
        setloading(true)
        setexportg(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        var url
        if (tabledata === 'table') {
            url = `${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalLetterClassificationDetails_Select?FilterID=${filterID}&M_LetterTypeID=${letterClassificationID}&Flag=${flag}&UserID=${UserID}&Top=99999`
        } else {
            url = `${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalDetails_Select?FilterID=${filterID}&Flag=${flag}&ShowBy=${showBy}&M_LetterClassificationID=${letterClassificationID}&UserID=${UserID}&Top=99999`
        }

        fetch(url, requestOptions)
            // fetch(`${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalDetails_Select?FilterID=${filterID}&Flag=${flag}&ShowBy=${showBy}&M_LetterClassificationID=${letterClassificationID}&UserID=${UserID}&Top=9000`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data) {
                    setexportgridData(result.data)

                } else {
                    setexportgridData([])
                }
                setloading(false)
                setexportg(false)


            })
            .catch(error => {
                console.log('error', error)
                setloading(false)

            });
    }

    useEffect(() => {
        loadGridData()
    }, [PerPage])

    const handleContainerOnBottom = () => {

        if (gridData) {
            if (PerPage <= gridData.length) {
                setloading(false)

                setPerPage(PerPage + 50)
                // }, 500);
            } else {


            }
        }

    };
    const containerRef = useBottomScrollListener(handleContainerOnBottom, {
        offset: 20
    });


    const [ShohideTrackData, setShohideTrackData] = React.useState({
        createdByUserID: '',
        mainCreatedInward_MasterID: '',
        inwardNumber: '',
        parentInwardID: '',
    })

    const [ShohideTrack, setShohideTrack] = React.useState(false)


    return (<>

        <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">

                    <div className="modal-header mt-2 col-12 justify-content-between">

                        <div className="col-5">
                            <h2 className="modal-title" id="staticBackdropLabel">{heading}</h2>
                        </div>

                        <div className="col-5">
                            {
                                exportg ?
                                    <text style={{ color: 'green' }}>Loading Excel ...</text>
                                    :
                                    (exportgridData && exportgridData.length > 0) ?
                                        <span><ExportViewData exportgridData={exportgridData} heading={heading} /></span>
                                        : ''

                            }
                        </div>

                        {/* <div className="col-2"> */}
                            <button onClick={() => {
                                sethideShowViewLetterPopUp(false)
                            }} className="btn-close" type="button">x</button>
                        {/* </div> */}
                    </div>

                    <div className="modal-body">


                        <div>
                            {heading2}

                            <div className="row">
                                <div className="col-xl-12 col-xl-12">




                                    {
                                        loading ?
                                            <Loader fullPage loading />
                                            : <></>

                                    }
                                    <div className="row">
                                        <div className="col-xl-12 col-xl-12">

                                            <div
                                                id='nestedMain1'
                                                className="table-responsive"
                                                ref={containerRef}
                                                style={{ height: '450px', width: 'auto', overflowY: "auto" }}
                                            >
                                                <table className="table table-bordered data-table">
                                                    <thead>
                                                        <tr>
                                                            <th>अ.क्र</th>
                                                            <th >आवक क्रमांक</th>
                                                            <th >पत्र दिनांक/मिळाल्याची दिनांक </th>
                                                            <th >पत्र क्रमांक </th>
                                                            {/* <th >पत्र दिनांक व वेळ </th> */}
                                                            <th >कार्यवाही प्रकार </th>
                                                            <th>कोणाकडून आले </th>
                                                            <th >कोणास पाठवले </th>
                                                            <th >वर्गवारी /पत्र प्रकार</th>
                                                            <th >पत्र स्थिती</th>
                                                            <th >पहा</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {gridData.length > 0 ?
                                                            gridData.map((item, index) => (
                                                                <tr>
                                                                    <td align='center' id="srwidth">{index + 1}</td>
                                                                    <td style={{ width: '8%', whiteSpace: 'nowrap' }} >{item.inwardNumber ? item.inwardNumber : '-'}</td>
                                                                    <td style={{ width: '9%', whiteSpace: 'nowrap' }} >
                                                                        <>
                                                                            <tr style={{ border: 'none' }}>{moment(item.letterDate).format('DD-MM-YYYY')}</tr>
                                                                            <hr style={{ margin: '0' }}></hr>
                                                                            <tr style={{ border: 'none' }}>{moment(item.letterReceivedDate).format('DD-MM-YYYY')}</tr>
                                                                        </>
                                                                    </td>


                                                                    <td style={{ whiteSpace: 'nowrap' }}>{item.receivedLetterNumber ? item.receivedLetterNumber : '-'}</td>


                                                                    <td>{item.actionTypeIndicator ? item.actionTypeIndicator : '-'}</td>
                                                                    <td id="breakword" >{item.letterSenderOfficeName}</td>
                                                                    <td style={{ width: '9%' }}>{item.transfer_To_Name}</td>
                                                                    <td style={{ width: '12%' }}>
                                                                        <>
                                                                            <tr style={{ border: 'none' }}>{item.classificationName}</tr>
                                                                            <hr style={{ margin: '0' }}></hr>
                                                                            <tr style={{ border: 'none' }}>{item.letterTypeName}</tr>
                                                                        </>
                                                                    </td>
                                                                    <td>{item.statusName}</td>
                                                                    <td style={{ width: '6%' }}>

                                                                        <Link
                                                                            data-dismiss="modal"
                                                                            to="#paha"
                                                                            data-toggle="modal"
                                                                            data-target="#paha"
                                                                            onClick={() => {
                                                                                setsingleLetter(item)

                                                                            }}
                                                                        >
                                                                            <button type="button" className="btn btn-primary btn-md" style={{ fontSize: 10 }}>पहा</button>
                                                                        </Link>


                                                                        {
                                                                            sessionStorage.getItem('flagPending') === 'Pending' && item.statusID !== 6 ?
                                                                                <button type="button" className="btn btn-success mt-1"
                                                                                    onClick={() => {
                                                                                        setShohideTrackData({
                                                                                            createdByUserID: item.createdByUserID,
                                                                                            mainCreatedInward_MasterID: item.mainCreatedInward_MasterID,
                                                                                            inwardNumber: item.inwardNumber,
                                                                                            parentInwardID: item.parentID,
                                                                                        })
                                                                                        setShohideTrack(true)
                                                                                    }}
                                                                                >ट्रॅक</button>


                                                                                : ""
                                                                        }


                                                                    </td>

                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={9}> No records found...</td>
                                                            </tr>}


                                                    </tbody>

                                                </table>


                                                {ShohideTrack ?
                                                    <Track
                                                        setShohideTrack={setShohideTrack}
                                                        ShohideTrackData={ShohideTrackData}
                                                    />
                                                    : <></>}

                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {singleLetter.srNo ? <ViewSingleLetterDetails singleLetter={singleLetter} setsingleLetter={setsingleLetter} /> : <></>}
    </>);
}

export default ViewLettersPopUp;