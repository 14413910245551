
// export const baseUrl = "http://apidemo.nmmclfms.in"

var url = window.location.origin
// console.log("url", url)

export const baseUrl =
    // "http://jdalaturapi.elfms.in"
    // "http://jdasambhajinagarapi.elfms.in"


    // url == "http://mahaagri.elfms.in" ? "http://apimahaagri.elfms.in" :
    //     "http://demomahaagri.elfms.in" ? "http://demoapimahaagri.elfms.in" :
    //         "http://jdalatur.elfms.in" ? "http://jdalaturapi.elfms.in" :
    //             "http://jdasambhajinagar.elfms.in" ? "http://jdasambhajinagarapi.elfms.in" :
    //                 // : "http://jdasambhajinagarapi.elfms.in"

    //                 // "http://jdalaturapi.elfms.in"
    //                 // "http://apimahaagri.elfms.in"
    // "http://demoapimahaagri.elfms.in"

    url == "https://jdalatur.elfms.in" ? "https://apimahaagri.elfms.in" :
        url == "http://jdalatur.elfms.in" ? "http://apimahaagri.elfms.in" :
            url == "https://jdasambhajinagar.elfms.in" ? "https://apimahaagri.elfms.in" :
                url == "http://jdasambhajinagar.elfms.in" ? "http://apimahaagri.elfms.in" :
                    url == "http://demomahaagri.elfms.in" ? "http://demoapimahaagri.elfms.in" :
                        "http://demoapimahaagri.elfms.in"


export const UserIDAPI = sessionStorage.getItem("User")





