import AllRoutes from "./AllRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from "react";
import { baseUrl } from "./BaseURL/BaseURL";
// import Notification from "./helper/firebaseNotifications/Notification";
// import { Noti } from "./helper/firebaseNotifications/Noti";



function App() {

  React.useEffect(() => {
    getAppName()
    // clearCacheData()
  }, [])

  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  //   // alert('Complete Cache Cleared')
  // };

  const getAppName = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    let domainName = window.location.hostname.split(".", 1)
    fetch(`${baseUrl}/CommonDLLData/GET_DomainWiseClient_Info_Select?DomainName=${domainName}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code >= 200 && result.code <= 300 && result.data) {
          if (baseUrl === "http://apipmrda.elfms.in") {
            localStorage.setItem("applicationName1", 'PMRDA')
            sessionStorage.setItem("applicationName", result.data[0].clientName)
          }
          else {
            sessionStorage.setItem("applicationName", result.data[0].clientName)
            localStorage.setItem("applicationName1", result.data[0].clientName)
          }

        } else {
          sessionStorage.setItem("applicationName", "")
          localStorage.setItem("applicationName1", "")
        }
      })
      .catch(error => {
        sessionStorage.setItem("applicationName", "")
        localStorage.setItem("applicationName1", "")


        console.log('error', error)
      });
  }

  const [UserToken, setUserToken] = useState('')


  return (
    <>
      <AllRoutes UserToken={UserToken} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      // limit={1}
      />
      {/* <Notification setUserToken={setUserToken} /> */}

      {/* {
        window.location.protocol === "https:" ?
          <Noti setUserToken={setUserToken} />
          : <></>
      } */}
    </>
  );
}

export default App;
