
import React, { lazy, Suspense } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation,
    Outlet,
} from "react-router-dom";
import { AppContext } from "./Context/AppContext";
import { useState } from "react";
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './Component/ErrorBoundary/ErrorBoundary';
import ForgotPassword from './Pages/Public/ForgotPassword/ForgotPassword';
import LetterAhval from './Pages/Private/Letter/LetterAhaval/LetterAhval';
import FileAhval from './Pages/Private/File/FileAhval/FileAhval';
import ForgotPassword1 from './Pages/Private/ForgotPassword/ForgotPassword';
import UpdatedProfile from './Pages/Private/UpdatedProfile/UpdatedProfile';

import BranchDashboard from './Pages/Private/BranchMasterDashboard/BranchDashboard/BranchDashboard'
import { MultipleBranch } from './Pages/Private/AdminPages/MultipleBranch/MultipleBranch';
import ResendNewOutLetter from './Pages/Private/Letter/NewOutwardLetter/ResendNewOutLetter';

// import OtpSend from './Pages/Public/ForgotPassword/OtpSend';

const OtpSend = lazy(() => import('./Pages/Public/ForgotPassword/OtpSend'))
const DeptDashboard = lazy(() => import('./Pages/Private/DeptUsers/DeptDashboard/DeptDashboard'))
const KaryalayPrakarTayarKarne = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/CreateKaryalay/KaryalayPrakarTayarKarne'))
const ViewKaryalayPrakarTayarKarne = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/CreateKaryalay/ViewKaryalayPrakarTayarKarne'))
const Dashboard = lazy(() => import("./Pages/Private/Dashboard/Dashboard"))
const InboxNasti = lazy(() => import("./Pages/Private/File/InboxNasti/InboxNasti"))
const NastiNond = lazy(() => import("./Pages/Private/File/NastiNond/NastiNond"))
const NastiQRCode = lazy(() => import("./Pages/Private/File/NastiQRCode/NastiQRCode"))
const InboxLetter = lazy(() => import("./Pages/Private/Letter/InboxLetter/InboxLetter"))
const PatraNond = lazy(() => import("./Pages/Private/Letter/PatraNond/PatraNond"))
const Login = lazy(() => import("./Pages/Public/Login/Login"))
const LoginPage = lazy(() => import("./Pages/Public/Login/LoginPage"))
const CreateVibhag = lazy(() => import("./Pages/Private/AdminPages/CreateVibhag/CreateVibhag"))
const AddVibhag = lazy(() => import("./Pages/Private/AdminPages/CreateVibhag/AddVibhag"))
const Padnaam = lazy(() => import("./Pages/Private/AdminPages/Padnaam/Padnaam"))
const AddPadnaam = lazy(() => import("./Pages/Private/AdminPages/Padnaam/AddPadnaam"))
const Info = lazy(() => import("./Pages/Private/AdminPages/AdhikariInfo/Info"))
const CreateUser = lazy(() => import("./Pages/Private/AdminPages/CreateUser/CreateUser"))
const AddUser = lazy(() => import("./Pages/Private/AdminPages/CreateUser/AddUser"))
const CreateKaryalay = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/CreateKaryalay/CreateKaryalay'))
const AddKarayalay = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/AddKaryalay/AddKarayalay'))
const ViewCreateKarayalay = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/ViewCreateKarayalay/ViewCreateKarayalay'))
const ViewAddKarayalay = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/ViewCreateKarayalay/ViewAddKarayalay'))
const ViewRole = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/Role/ViewRole'))
const ViewPatra = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/PatraSandharbh/ViewPatra'))
const AddPatra = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/PatraSandharbh/AddPatra'))
const ViewForm = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/ViewForm/ViewForm'))
const AddForm = lazy(() => import('./Pages/Private/AdminPages/Role2Pages/ViewForm/AddForm'))
const OutwardFileNasti = lazy(() => import('./Pages/Private/File/OutwardNasti/OutwardFileNasti'))
const NewLetter = lazy(() => import('./Pages/Private/Letter/NewLetter/NewLetter'))
const RegisterLetter = lazy(() => import('./Pages/Private/Letter/RegisterLetter/RegisterLetter'))
const AckInboxOutward = lazy(() => import('./Pages/Private/Letter/InboxLetter/AckInboxOutward'))
const InboxOutwardLetter = lazy(() => import('./Pages/Private/Letter/InboxLetter/InboxOutwardLetter'))
const OutwardLetter = lazy(() => import('./Pages/Private/Letter/OutwardLetter/OutwardLetter'))
const CommDashboard = lazy(() => import('./Pages/Private/Commisioner/SPDashboard/Dashboard/CommDashboard'))
const NewOutwardLetter = lazy(() => import('./Pages/Private/Letter/NewOutwardLetter/NewOutwardLetter'))
const Software = lazy(() => import('./Pages/Public/Software/Software'))
const ForwardReport = lazy(() => import('./Pages/Private/Letter/ForwardReport/ForwardReport'))
const AddToFile = lazy(() => import('./Pages/Private/Letter/InboxLetter/AddToFile'))
const CreateBranch = lazy(() => import('./Pages/Private/AdminPages/CreateBranch/CreateBranch'))
const ApprovalUser = lazy(() => import('./Pages/Private/AdminPages/ApprovalUser/ApprovalUser'))



function RequireAuth() {
    let auth = sessionStorage.getItem("User");
    let location = useLocation();

    if (!auth) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
}



const AllRoutes = ({ UserToken }) => {
    const [dashboardID, setdashboardID] = useState(1)

    return (
        <>
            <AppContext.Provider value={{ dashboardID, setdashboardID }}>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                >
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <Router>
                            <Routes>
                                {/* <Route
                                    exact path="/"
                                    element={<Login />}
                                /> */}

                                {/* <Route exact path="/" element={<Login UserToken={UserToken} />} /> */}
                                <Route exact path="/" element={<LoginPage UserToken={UserToken} />} />

                                <Route
                                    exact path="/otpsend"
                                    element={<OtpSend />}
                                />

                                <Route
                                    exact path="/software"
                                    element={<Software />}
                                />

                                <Route element={<RequireAuth />}>
                                    {/* <Route > */}
                                    <Route
                                        exact path="/forgot-password"
                                        element={<ForgotPassword1 />}
                                    />

                                    <Route
                                        exact path="/updatedprofile"
                                        element={<UpdatedProfile />}
                                    />

                                    <Route
                                        exact path="/dashboard"
                                        element={<Dashboard />}
                                    />

                                    <Route
                                        exact path="/Commdashboard"
                                        element={<CommDashboard />}
                                    />



                                    {/* FILE========================================================== */}
                                    <Route
                                        exact path="/nastinond"
                                        element={<NastiNond />}
                                    />

                                    <Route
                                        exact path="/inboxnasti"
                                        element={<InboxNasti />}
                                    />

                                    <Route
                                        exact path="/outwardnasti"
                                        element={<OutwardFileNasti />}
                                    />

                                    <Route
                                        exact path="/nastiqrcode"
                                        element={<NastiQRCode />}
                                    />

                                    <Route
                                        exact path="/FileAhval"
                                        element={<FileAhval />}
                                    />

                                    {/* LETTER======================================================= */}
                                    <Route
                                        exact path="/patranond"
                                        element={<PatraNond />}
                                    />

                                    <Route
                                        exact path="/newletter"
                                        element={<NewLetter />}
                                    />

                                    <Route
                                        exact path="/registerletter"
                                        element={<RegisterLetter />}
                                    />

                                    <Route
                                        exact path="/inboxletter"
                                        element={<InboxLetter />}
                                    />

                                    <Route
                                        exact path="/letterahval"
                                        element={<LetterAhval />}
                                    />

                                    <Route
                                        exact path="/ackinboxoutward"
                                        element={<AckInboxOutward />}
                                    />

                                    <Route
                                        exact path="/inboxoutward"
                                        element={<InboxOutwardLetter />}
                                    />

                                    <Route
                                        exact path="/outwardletter"
                                        element={<OutwardLetter />}
                                    />

                                    <Route
                                        exact path="/newOutLetter"
                                        element={<NewOutwardLetter />}
                                    />
                                    <Route exact path="/ResendNewOutLetter"
                                        element={<ResendNewOutLetter />} />

                                    <Route
                                        exact path="/forward-report"
                                        element={<ForwardReport />}
                                    />

                                    <Route
                                        exact path="/add-to-file"
                                        element={<AddToFile />}
                                    />

                                    {/* ========================== Admin ============================= */}

                                    <Route
                                        exact path="/createvibhag"
                                        element={<CreateVibhag />}
                                    />
                                    <Route
                                        exact path="/createbranch"
                                        element={<CreateBranch />}
                                    />

                                    <Route
                                        exact path='/addvibhag'
                                        element={<AddVibhag />}
                                    />

                                    <Route
                                        exact path='/padnaam'
                                        element={<Padnaam />}
                                    />

                                    <Route
                                        exact path='/addpadnaam'
                                        element={<AddPadnaam />}
                                    />

                                    <Route
                                        exact path='/info'
                                        element={<Info />}
                                    />

                                    <Route
                                        exact path='/createuser'
                                        element={<CreateUser />}
                                    />

                                    <Route
                                        exact path='/approvaluser'
                                        element={<ApprovalUser />}
                                    />
                                    <Route
                                        exact path="/MultipleBranch"
                                        element={<MultipleBranch />}
                                    />
                                    <Route
                                        exact path='/adduser'
                                        element={<AddUser />}
                                    />

                                    <Route
                                        exact path='/KaryalayPrakarTayarKarne'
                                        element={<KaryalayPrakarTayarKarne />}
                                    />

                                    {/* ######################## Role 2 ######################## */}

                                    <Route
                                        exact path='/viewcreatekaryalayprakar'
                                        // element={<CreateKaryalay />}
                                        element={<ViewKaryalayPrakarTayarKarne />}
                                    />

                                    <Route
                                        exact path='/createkaryalayprakar'
                                        element={<AddKarayalay />}
                                    />

                                    <Route
                                        exact path='/viewcreatekaryalay'
                                        element={<ViewCreateKarayalay />}
                                    />

                                    <Route
                                        exact path='/createkaryalay'
                                        element={<ViewAddKarayalay />}
                                    />

                                    <Route
                                        exact path='/viewrole'
                                        element={<ViewRole />}
                                    />

                                    <Route
                                        exact path='/viewpatrasandharbh'
                                        element={<ViewPatra />}
                                    />

                                    <Route
                                        exact path='/letterinfo'
                                        element={<AddPatra />}
                                    />

                                    <Route
                                        exact path='/viewform'
                                        element={<ViewForm />}
                                    />

                                    <Route
                                        exact path='/createform'
                                        element={<AddForm />}
                                    />

                                    {/* ========================== Admin ============================= */}


                                    {/* ======================== Department ================================= */}
                                    <Route
                                        exact path='/deptdashboard'
                                        element={<DeptDashboard />}
                                    />

                                    {/* ======================== Branch Master ================================= */}
                                    <Route
                                        exact path='/branchdashboard'
                                        element={<BranchDashboard />}
                                    />


                                </Route>

                            </Routes>
                        </Router>
                    </Suspense>
                </ErrorBoundary>
            </AppContext.Provider>
        </>
    );
};

export default AllRoutes;
