
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

export const ExportExcelFileAhval = ({ Exceldata, StatusName, TableUserMasterName }) => {

    return (
        <div style={{ display: 'inline', marginLeft: '20px', width: '100%' }}>


            <ReactHTMLTableToExcel
                id="test-table-xls-buttonनस्ती अहवाल"
                className="download-table-xls-button btn btn-success btn-md"
                table="table-to-xlsनस्ती अहवाल"
                filename={`नस्ती अहवाल - ${TableUserMasterName}`}
                sheet={`नस्ती अहवाल - ${TableUserMasterName}`}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-xlsनस्ती अहवाल" style={{ display: 'none' }}>
                <h5><span>&emsp;</span></h5>
                <h4 style={{ textAlign: 'center' }}>नस्ती अहवाल - {TableUserMasterName}</h4>
                <h5><span>&emsp;</span></h5>

                <h5 style={{ textAlign: 'center' }}>
                    {/* {LetterDate ? ( */}
                    <>
                        {/* <span>पत्र दिनांक:{moment(LetterDate).format("DD-MM-YYYY")}</span> */}
                        {/* <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span> */}
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    </>
                    {/* ) */}
                    {/* : ""} */}
                    {StatusName ? (<span>नस्ती स्थिती :{StatusName}</span>) : ""}
                </h5>

                {/* <h5 style={{ textAlign: 'center' }}>
                        <span>पत्र वर्गीकरण:  </span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>पत्र प्रकार: </span>
                    </h5> */}
                <h5><span>&emsp;</span></h5>



                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th id='srwidth1'>अ.क्र.</th>

                        <th>नस्ती क्रमांक</th>

                        <th>नस्ती विषय</th>

                        <th>कोणी पाठवली</th>

                        <th>कोणास पाठवली</th>

                        <th>नस्ती पोच दिनांक</th>

                        <th>नस्ती संदर्भ प्रकार</th>


                    </tr>
                </thead>
                <tbody>
                    {
                        Exceldata ? Exceldata.map((item, index) =>
                            <tr style={{ borderStyle: 'groove' }}>

                                <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>

                                <td style={{ verticalAlign: 'middle' }}>{item.fileNumber}</td>

                                <td style={{ verticalAlign: 'middle' }}>
                                    <text>{item.letterSubject}</text>
                                </td>

                                <td style={{ verticalAlign: 'middle' }}>{item.transfer_From_Name}</td>
                                <td style={{ verticalAlign: 'middle' }}>{item.transfer_To_Name}</td>
                                <td style={{ verticalAlign: 'middle' }}>{moment(item.lastModifiedDate).format('DD-MM-YYYY')}</td>
                                <td style={{ verticalAlign: 'middle' }}>{item.referenceType}</td>


                            </tr>
                        )
                            : <></>
                    }

                </tbody>
            </table>
        </div>
    )
}
