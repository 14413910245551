import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

export default class SP_Pie_Chart extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     chartData66: {
        //         labels: this.props.labels7,
        //         // labels:['नोंदणी शाखा', 'अर्ज शाखा', 'आस्थापना शाखा', 'विशेष शाखा', 'वाचक शाखा', 'स्थानिक गुन्हे शाखा', 'पत्रव्यवहार शाखा', 'पोलिस मुख्यालय', 'अति.पोलिस अधीक्षक अ नगर ', 'दक्षिण विभाग मोबाइल सेल-अहमदनगर', 'सायबर ', 'केंद्रीय जावक शाखा', 'बिनतारी सं.विभाग', 'उत्तर मोबाईल सेल श्रीरामपूर ', 'दहशद वाद वि.पथक ', 'आर्थिक गुन्हे शाखा ', 'नियंत्रण शाखा', 'लेखा शाखा', 'सी.सी.टी.एन.एस.संगणक विभाग', 'मानव संसाधन विभाग', 'अंगुलीमुद्रा', 'भरोसा', 'श्‍वानपथक', 'पोलीस कल्याण', 'बीडीडीएस', 'अ.व्यापार प्रतिबंधक कक्ष', 'स्टेनो', 'महिला व बाल अपराध प्रतिबंधक', 'चौकशी विभाग ', 'मोटर प.शाखा', 'शहर वाहतूक शाखा नगर ', 'ट्रायल मॉनिटरिंग सेल', 'पोलिस उप अधीक्षक(मुख्यालय)', 'शिर्डी वाहतूक शाखा '],
        //         datasets: [{
        //             data: this.props.data7,
        //             backgroundColor: this.props.bgColor7
        //         }]

        //     }
        // }
    }

    render() {
        var labelLength = (this.props.chart2 && this.props.chart2.labels ? this.props.chart2.labels.length : 0)
        // console.log(labelLength)
        return (
            <div className="Chart">
                <Pie
                    data={this.props.chart2}
                    width={900}
                    height={350}

                    options={{
                        plugins: {
                            labels: {
                                render:'label',
                                fontColor:(labelLength && labelLength !== 0 && labelLength <= 7 ? 'black' : 'transparent'),
                                position: 'outside',
                                arc: false,
                                textMargin: 5,
                                // overlap:false
                            },
                            outlabels: {
                                display:false,
                                color: "white",
                                // text: "%l\n%v (%p)",
                                stretch: 15,
                                font: {
                                    resizable: true,
                                    minSize: 12,
                                    maxSize: 16
                                }
                            },
                        },
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        },
                        legend: {
                            display: false,
                            position: "right"
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return tooltipItem?.value;
                                }
                            }
                        }
                    }}

                />

            </div>
        )
    }

}
