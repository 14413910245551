
import React from 'react'
// import { useAlert } from 'react-alert'
import { Link, useNavigate } from 'react-router-dom'
// import Footer from '../Footer/Footer'
import { baseUrl } from '../../../BaseURL/BaseURL'
import { toastError, toastSuccess } from '../../../helper/Toasthelper'


export default function ForgotPassword1() {
    // let history = useHistory()
    // let alert = useAlert()
    const navigate = useNavigate()
    const UserID = sessionStorage.getItem("User")

    const [OldPassword, setOldPassword] = React.useState('')
    const [CurrentPassword, setCurrentPassword] = React.useState('')
    const [NewPassword, setNewPassword] = React.useState('')
    const [passerror, setpasserror] = React.useState('')

    const ChangePassword = () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));
        if (CurrentPassword !== NewPassword) {
            setpasserror("नवीन पासवर्ड आणि पुन्हा प्रविष्ट केलेला पासवर्ड जुळत नाहीत")
            setCurrentPassword('')
            setNewPassword('')
        }
        else {
            var formdata = new FormData();
            formdata.append("M_TableUserMasterID", UserID);
            formdata.append("CurrentPassword", OldPassword);
            formdata.append("Confirm_Password", NewPassword);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${baseUrl}/LFMS/Master/Post_M_TableUserMaster_Password_Reset`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "Saved Successfully") {
                        toastSuccess(result.message)
                        setTimeout(() => {
                            sessionStorage.clear();
                            localStorage.clear();
                            navigate('/')
                        }, 1500);

                    } else {
                        alert.info(result.message)
                    }

                })
                .catch(error => {
                    toastError("Something went wrong!")
                    console.log('error', error)
                });
        }
    }

    const Clear = () => {
        setNewPassword('')
        setOldPassword('')
        setCurrentPassword('')
        setpasserror('')
    }

    const Back = () => {
        navigate('/')
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye pr-3") {
            y.className = "fa fa-eye-slash pr-3";
        } else {
            y.className = "fa fa-eye pr-3";
        }
    }

    return (
        <div className="wrapper">
            <div className="main">
                {/* <HeaderSP /> */}
                <main className="content">
                    <div className="container-fluid p-0">

                        <div className="container-fluid page-body-wrapper">

                            <div className="main-panel ml-5">
                                <div className="content-wrapper">



                                    <div className="card main-card col-12"
                                    // style={{width:'60%', display:'flex', justifyContent:'center', alignItems:'center'}}
                                    >
                                        <div className="form-row mt-3 " >
                                            <div className="col-12 col-md-6 col-lg-6" style={{ marginLeft: '45%' }}>
                                                <div className="row page-heading">
                                                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                        <h4 className="font-weight-bold d-flex ml-5">पासवर्ड बदला</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body row">
                                            <div className="col-12 col-lg-3"></div>

                                            <div className="col-12 col-lg-6">

                                                <div className="form-row p-3">


                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>सद्य पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
                                                    </div>


                                                    <div className="col-lg-8 form-group">
                                                        <span className="btn-show-pass">
                                                            <span id='eye' className="fa fa-eye-slash pr-3"
                                                                onClick={() => showPass()}

                                                            ></span>
                                                        </span>

                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id='pass'
                                                            placeholder="सद्य पासवर्ड"
                                                            value={OldPassword}
                                                            onChange={(e) => setOldPassword(e.target.value)}
                                                        />
                                                    </div>
                                                    {/* </div> */}

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>नवीन पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
                                                    </div>
                                                    <div className="col-lg-8 form-group">
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="Nameofagency "
                                                            placeholder="नवीन पासवर्ड"
                                                            value={CurrentPassword}
                                                            onChange={(e) => {
                                                                setCurrentPassword(e.target.value)
                                                                setpasserror('')
                                                            }}
                                                        />
                                                    </div>
                                                    {/* </div> */}

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>पासवर्ड पुन्हा प्रविष्ट करा <span style={{ color: 'red' }}> *</span>:</h4>
                                                    </div>

                                                    <div className="col-lg-8 form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="gstno "
                                                            placeholder="पासवर्ड पुन्हा प्रविष्ट करा"
                                                            value={NewPassword}
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value)
                                                                setpasserror('')
                                                            }}
                                                        />
                                                    </div>
                                                    {/* </div> */}
                                                </div>

                                                {/* <div className='col-lg-12 mb-3' style={{color:'red'}}>{passerror}gfgh</div> */}
                                                <div className="form-row">
                                                    <div className="col-12 col-md-12 col-lg-12 mb-3 mt-n3" style={{ marginLeft: '35%' }}>
                                                        <span className="txt2"
                                                            // onClick={() => history.goBack()}
                                                            style={{ color: 'red' }}>
                                                            {passerror}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="form-row" >
                                                    <div className="col-12 col-md-6 col-lg-6" style={{ marginLeft: '35%' }}>
                                                        <button
                                                            disabled={OldPassword === '' || NewPassword === '' || CurrentPassword === ''}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => ChangePassword()}
                                                        >
                                                            Submit
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-danger ml-2"
                                                            onClick={() => Clear()}
                                                        >
                                                            Clear
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-row mt-3">
                                                    <div className="col-12 col-md-12 col-lg-12" style={{ marginLeft: '35%' }}>
                                                        <span className="txt2"
                                                            onClick={() => Back()}
                                                            style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                                                            Go Back<i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-12 col-lg-3"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </main>
                {/* <Footer /> */}
            </div>
        </div>
    )
}
