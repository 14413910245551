import { baseUrl } from "../../../../BaseURL/BaseURL";
import { NotitoastInfologin } from "../../../../helper/Toasthelper";

var month = (new Date().getMonth()) + 1
var year = new Date().getFullYear()

export const NotificationForCloseData = ({ UserID, Flag, setCloseData }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // console.log("Flag", Flag)

    fetch(`${baseUrl}/CommDashBoard/Get_M_NotificationForCloseDataPush_Select?Flag=${Flag && Flag == '1' ? 'Letter' : Flag == '0' ? 'File' : ''}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                setCloseData(result.data)
                NotitoastInfologin(<div>
                    <b>{result.data[0]?.notificationTital}</b>
                    <br />
                    {result.data[0]?.messageBody}
                </div>)
            }
        })

        .catch(error => console.log('error', error));
}

export function GetDeptDashCount({ UserID, setDashCount, setdashloading }) {
    setdashloading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    
    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_TabMainTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setDashCount(result.data)
            }
            setdashloading(false)
        })
        .catch(error => {
            console.log('error', error)
            setdashloading(false)

        });
}

export const DeptReferenceTypeDDLAPI = (setReferenceTypeDDL, setddlloader, UserID) => {
    setddlloader(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_M_ReferenceTypeDDL_Select?UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setReferenceTypeDDL(result.data)
            } else {
                setReferenceTypeDDL([])
            }
            setddlloader(false)

        })
        .catch(error => {
            console.log('error', error)
            setddlloader(false)

        });
}

export const DeptPieChartTop3MostGenerated = (flag, filterflag, showby, setTop3MostGeneratedFiles, setTopMostlabels, setTopMostCount, setTop3MostLoader, UserID) => {
    setTop3MostLoader(true)
    let TopMostlabels = []
    let TopMostCount = []
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_TableUserWiseMaxMinFileGeneratedAndCloseTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${flag}&ShowBy=${showby}&FilterFlag=${filterflag}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                if (result.data) {
                    setTop3MostGeneratedFiles(result.data.slice(0, 3))
                    for (var i = 0; i < result.data.slice(0, 3).length; i++) {
                        TopMostlabels.push(result.data[i].tableUserName)
                        if (showby == 'Close') {
                            TopMostCount.push(result.data[i].totalCloseCount)
                        } else {
                            TopMostCount.push(result.data[i].totalInwardCount)
                        }

                    }
                    setTopMostlabels(TopMostlabels)
                    setTopMostCount(TopMostCount)
                } else {
                    setTop3MostGeneratedFiles([])
                    setTopMostlabels([])
                    setTopMostCount([])
                }
            } else {
                setTop3MostGeneratedFiles([])
                setTopMostlabels([])
                setTopMostCount([])
            }
            setTimeout(() => {
                setTop3MostLoader(false)
            }, 1000);
        })
        .catch(error => {
            console.log('error', error)
            setTop3MostLoader(false)
        });
}


export const DeptReferenceTypeWiseDeptAndTable = (setReferenceTypeTableData, settableloader, ddlid, setPendingCount, setReflabels, setPColor, UserID, setddlloader) => {
    let id = sessionStorage.getItem('refid')
    // console.log(id)
    setddlloader(true)
    settableloader(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_ReferenceTypeWiseTableUserCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=ReferenceType&ShowBy=TableUser&M_ReferenceTypeID=${id ? id : '0'}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result) {
                setReferenceTypeTableData(result)
                // console.log(result)
            } else {
                setReferenceTypeTableData([])
            }
            settableloader(false)
            setddlloader(false)
        })
        .catch(error => {
            console.log('error', error)
            settableloader(false)
            setddlloader(false)
        });
}

export const DeptTop3PralambitAPI = (setTopPralambit, setTopPralmbitLables, setTopPralmbitCount, setTopPralmbitLoader) => {
    let TopMostlabels = []
    let TopMostCount = []
    setTopPralmbitLoader(true)
    const UserID = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_TableUserWiseCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=All&ShowBy=TableUserWise&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setTopPralambit(result.data.slice(0, 5))
                for (var i = 0; i < result.data.slice(0, 5).length; i++) {
                    TopMostlabels.push(result.data[i].entityName)
                    TopMostCount.push(result.data[i].totalPendingCount)
                }
                setTopPralmbitLables(TopMostlabels)
                setTopPralmbitCount(TopMostCount)
            } else {
                setTopPralambit([])
            }
            setTopPralmbitLoader(false)

        })
        .catch(error => {
            console.log('error', error)
            setTopPralmbitLoader(false)
        });
}

// export const DeptTop3PralambitAPI2 = (setTopPralambit2, setTopPralmbitLables2, setTopPralmbitCount2, setTopPralmbitLoader2) => {
//     let TopMostlabels = []
//     let TopMostCount = []
//     setTopPralmbitLoader2(true)
//     let User = sessionStorage.getItem("User")
//     var requestOptions = {
//         method: 'GET',
//         redirect: 'follow'
//     };
//     fetch(`${baseUrl}/CommDashBoard/Get_DB_All_COMM_DeptAndTableUserWiseCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=COMM&ShowBy=DeptWise&M_DepartmentNameMasterID=0&UserID=${User}`, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//             if (result.status) {
//                 setTopPralambit2(result.data.slice(0, 5))
//                 for (var i = 0; i < result.data.slice(0, 5).length; i++) {
//                     TopMostlabels.push(result.data[i].entityName)
//                     TopMostCount.push(result.data[i].totalPendingCount)
//                 }
//                 setTopPralmbitLables2(TopMostlabels)
//                 setTopPralmbitCount2(TopMostCount)
//             } else {
//                 setTopPralambit2([])
//             }
//             setTopPralmbitLoader2(false)

//         })
//         .catch(error => {
//             console.log('error', error)
//             setTopPralmbitLoader2(false)
//         });
// }



export const DeptKaryasanWorkAccuracyTable = (setWorkTableData, setWorkLoader, flag, ShowBy, UserID) => {
    let id = sessionStorage.getItem('refid')
    // console.log(id)
    setWorkLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_TableUserWiseCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${flag}&ShowBy=${ShowBy}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result) {
                setWorkTableData(result)
            } else {
                setWorkTableData([])
            }
            setWorkLoader(false)
        })
        .catch(error => {
            console.log('error', error)
            setWorkLoader(false)
        });
}