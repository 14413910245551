


import React from 'react'
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { baseUrl } from '../../../../../../BaseURL/BaseURL';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import $ from 'jquery'
import moment from 'moment';
import CommFourthPaha from './CommFourthPaha';
import Track from '../PopUp/Track';
import TrackNasti from '../../../../File/PopUp/Track/TrackNasti';
import ExportCommFourthDrillPopUp from './ExportCommFourthDrillPopUp';
import TrackNastiModal from '../../../../File/PopUp/Track/TrackNastiModal';
import LineGraph from './LineGraph';



function CommFourthDrillPopUp({ flag, FourthData, setFourthShow, screen, PendancyData, heading, DDLDataID, ReferenceTypeDDL, DDLDataIDHeading }) {
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
    `;


    const UserID = sessionStorage.getItem("User")
    const RoleID = sessionStorage.getItem("RoleID")
    var month = (new Date().getMonth()) + 1
    var year = new Date().getFullYear()

    const [loading, setloading] = React.useState(false)
    const [FourthTableData, setFourthTableData] = React.useState([])
    const [ExportFourthTableData, setExportFourthTableData] = React.useState([])
    const [PerPage, setPerPage] = React.useState(20)
    const [NoRecords, setNoRecords] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)

    React.useEffect(() => {
        if (PerPage > 0) {
            FourthTable()
            // ExportFourthTable()
        }
    }, [PerPage])


    const ExportFourthTable = () => {
        let id = sessionStorage.getItem('refid')
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommDashBoard/Get_DB_ALL_COMM_File_FinalDrill_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${FourthData ? FourthData.fourthflag : ''}&ShowBy=${FourthData ? FourthData.fourthShowby : ''}&FilterID=${FourthData ? FourthData.fourthfilterID : ''}&UserID=${UserID}&M_ReferenceTypeID=${flag == 'show' ? id : '0'}&Top=99999`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result.data)
                if (result.status) {
                    setExportFourthTableData(result.data)
                } else {
                    setExportFourthTableData([])
                }
                setloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setloading(false)

            });
    }
    const FourthTable = () => {
        let id = sessionStorage.getItem('refid')
        console.log(flag, id)
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommDashBoard/Get_DB_ALL_COMM_File_FinalDrill_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${FourthData ? FourthData.fourthflag : ''}&ShowBy=${FourthData ? FourthData.fourthShowby : ''}&FilterID=${FourthData ? FourthData.fourthfilterID : ''}&UserID=${UserID}&M_ReferenceTypeID=${flag == 'show' ? id : '0'}&Top=${PerPage}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result.data)
                if (result.status) {
                    setFourthTableData(result.data)
                } else {
                    setFourthTableData([])
                }
                setloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setloading(false)

            });
    }

    const handleContainerOnBottom = () => {

        if (FourthTableData) {
            if (PerPage <= FourthTableData.length) {
                setloading(false)
                setLoaded(true)
                setTimeout(() => {
                    setPerPage(PerPage + 50)
                }, 500);
            } else {
                setNoRecords(true)
                setloading(false)
                setLoaded(false)
            }
        }

    };
    const containerRef = useBottomScrollListener(handleContainerOnBottom);

    const showMore = (id) => {

        $('#moretext' + id).slideToggle();

        var x = document.getElementById('qw' + id);
        if (x.innerHTML === "Read more") {
            x.innerHTML = "Read less";
            document.getElementById('dot' + id).style.display = 'none'
        }
        else if (x.innerHTML === "Read less") {
            x.innerHTML = "Read more";
            document.getElementById('dot' + id).style.display = 'block'
        }
    }

    const [ModalData, setModalData] = React.useState([])
    const [ModalShow, setModalShow] = React.useState(false)

    const handlehideShowPaha = (data, show) => {
        setModalData(data)
        setModalShow(show)
    }

    const [TrackModalData, setTrackModalData] = React.useState([])
    const [TrackModalShow, setTrackModalShow] = React.useState(false)

    const hideshowTrackNastiPopUp = (data, show) => {
        setTrackModalData(data)
        setTrackModalShow(show)
    }
    const [ShohideTrackData, setShohideTrackData] = React.useState({
        createdByUserID: '',
        mainCreatedInward_MasterID: '',
        inwardNumber: '',
        parentInwardID: '',
    })

    const [ShohideTrack, setShohideTrack] = React.useState(false)
    const [showhide, setshowhide] = React.useState(false)

    return (
        <>
            <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between" >
                            <div className="mt-2">
                                <h2>{FourthData && FourthData.name ? FourthData.name : ''}
                                    <span className='ml-5'>
                                        {/* <ExportCommFourthDrillPopUp FourthTableData={FourthTableData} FourthData={FourthData}  ExportFourthTableData={ExportFourthTableData} /> */}

                                    </span>
                                </h2>

                            </div>

                            {
                                screen && screen == 'KaryasanAvg' &&
                                <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 mt-2 mb-3 gx-2">
                                    {
                                        FourthTableData && FourthTableData.length > 0 &&
                                        <button type='button' className='btn btn-primary'
                                            onClick={() => setshowhide(true)}
                                            style={{ fontSize: '12px', paddingTop: '2px' }}
                                        >{!showhide ? 'Show Graph' : 'Hide Graph'}</button>
                                    }
                                </div>
                            }

                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setFourthShow(false)
                                }}
                            >x</button>

                        </div>

                        <div className="modal-body" style={{ overflowY: 'hidden' }} >


                            <div >

                                {
                                    loading ?
                                        <Loader fullPage loading />
                                        : <></>

                                }
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12">
                                        {/* {
                                            flag == 'show' ?
                                                <h2>{DDLDataIDHeading + ' ' + heading}</h2>
                                                : ''
                                        } */}


                                        <div className="card">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row" >
                                                        <div className="col-xl-12 col-xl-12">

                                                            <div
                                                                id='totalpendingmodal1'
                                                                className="table-responsive"
                                                                ref={containerRef}
                                                                style={{ height: '90vh', width: 'auto', overflowY: "auto" }}
                                                            >
                                                                <table className="table table-bordered data-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th id='srwidth1'>अ.क्र</th>
                                                                            <th>नस्ती क्रमांक</th>
                                                                            <th>नस्ती विषय</th>
                                                                            <th>कोणी पाठवली</th>
                                                                            <th>कोणास पाठवली</th>
                                                                            <th>नस्ती पोच दिनांक व वेळ</th>
                                                                            <th>नस्ती स्थिती</th>
                                                                            {
                                                                                FourthData && (FourthData.fourthShowby == 'All_Accuracy' && FourthData.fourthflag == 'totalCloseCount') &&
                                                                                <th>एकूण दिवस</th>
                                                                            }

                                                                            {
                                                                                FourthData && (FourthData.fourthShowby == 'All_Accuracy' && FourthData.fourthflag == 'TotalDurationCount') &&
                                                                                <>
                                                                                    <th>नस्ती कालावधी</th>
                                                                                    <th>एकूण दिवस</th>
                                                                                </>
                                                                            }

                                                                            <th>ट्रॅक</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            FourthTableData ? FourthTableData.map((i, j) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td align='center' style={{ width: '6%' }}>{j + 1}</td>

                                                                                        <td id="breakword" style={{ width: '15%' }}>{i.fileNumber ? i.fileNumber : '-'}</td>

                                                                                        {/* <td>{i.letterSubject ? i.letterSubject : '-'}</td> */}
                                                                                        <td style={{ width: "16%", cursor: "pointer" }}>
                                                                                            {i.letterSubject.length > 50 ?
                                                                                                <>
                                                                                                    <text style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>{i.letterSubject.slice(0, 50)} <text id={'dot' + j}>...</text></text>
                                                                                                    <text id={"moretext" + j} style={{ display: 'none' }}>
                                                                                                        {i.letterSubject.slice(50)}
                                                                                                    </text>
                                                                                                    <br />
                                                                                                    <text>
                                                                                                        <a onClick={() => showMore(j)} class="moreless-button" style={{ color: 'blue', textDecoration: 'underline', fontSize: 11 }}><text id={'qw' + j}>Read more</text></a>
                                                                                                    </text>
                                                                                                </>
                                                                                                : i.letterSubject
                                                                                            }


                                                                                        </td>

                                                                                        <td style={{ width: '18%' }}>{i.transfer_From_Name ? i.transfer_From_Name : '-'}</td>

                                                                                        <td style={{ width: '18%' }}>{i.transfer_To_Name ? i.transfer_To_Name : '-'}</td>

                                                                                        <td align='center' style={{ width: '6%', whiteSpace: 'nowrap' }}>
                                                                                            {i.lastModifiedDate ?
                                                                                                moment(i.lastModifiedDate).format('DD-MM-YYYY')
                                                                                                : "-"
                                                                                            }
                                                                                            <hr style={{ padding: 0, margin: 0 }} />
                                                                                            {i.lastModifiedDate ?
                                                                                                moment(i.lastModifiedDate).format('h:mm:ss a')
                                                                                                : "-"
                                                                                            }
                                                                                        </td>

                                                                                        <td align='center' style={{ width: '6%' }}>{i.statusName ? i.statusName : '-'}</td>

                                                                                        {
                                                                                            FourthData && (FourthData.fourthShowby == 'All_Accuracy' && FourthData.fourthflag == 'totalCloseCount') &&
                                                                                            <td align='center' style={{ width: '6%' }}>{i.totalDays ? i.totalDays : '-'}</td>
                                                                                        }

                                                                                        {
                                                                                            FourthData && (FourthData.fourthShowby == 'All_Accuracy' && FourthData.fourthflag == 'TotalDurationCount') &&
                                                                                            <>
                                                                                                <td align='center' style={{ width: '6%' }}>{i.periodInDays ? i.periodInDays : '-'}</td>
                                                                                                <td align='center' style={{ width: '6%' }}>{i.totalDays ? i.totalDays : '-'}</td>
                                                                                            </>
                                                                                        }

                                                                                        <td style={{ width: "6%", padding: 0 }} align='center'>
                                                                                            {
                                                                                                i.statusID !== 11 ?

                                                                                                    <button type="button" className="btn btn-primary btn-md" style={{ fontSize: 10 }}
                                                                                                        onClick={() => {
                                                                                                            // document.getElementById('totalpendingmodal1').scroll({ top: 0 })
                                                                                                            handlehideShowPaha({
                                                                                                                fileNumber: i.fileNumber,
                                                                                                                letterSubject: i.letterSubject,
                                                                                                                correspondRemark: i.correspondRemark,
                                                                                                                letterMediumIndicator: i.letterMediumIndicator,
                                                                                                                letterMediumIndicatorID: i.letterMediumIndicatorID,
                                                                                                                correspondLetterFilePath: i.correspondLetterFilePath
                                                                                                            }, true)
                                                                                                        }}
                                                                                                    >पहा</button>
                                                                                                    : '-'
                                                                                            }
                                                                                            {/* 
                                                                                            {
                                                                                                sessionStorage.getItem('flagPending1') === 'Pending' ?
                                                                                                    <button type="button" className="btn btn-success mt-1"
                                                                                                        onClick={() => {
                                                                                                            setShohideTrackData({
                                                                                                                createdByUserID: i.createdByUserID,
                                                                                                                mainCreatedInward_MasterID: i.mainCreatedFile_Inward_MasterID,
                                                                                                                inwardNumber: i.inwardNumber,
                                                                                                                parentInwardID: i.parentID,
                                                                                                            })
                                                                                                            setShohideTrack(true)
                                                                                                        }}
                                                                                                    >ट्रॅक</button>


                                                                                                    : ""
                                                                                            } */}
                                                                                            {
                                                                                                i.statusID !== 11 ?

                                                                                                    <button data-tooltip="tooltip" title="ट्रॅक"
                                                                                                        className="btn btn-success btn-md mt-2"
                                                                                                        style={{ cursor: 'pointer', color: "#fff" }}
                                                                                                        onClick={() =>
                                                                                                            hideshowTrackNastiPopUp({
                                                                                                                fileNumber: i.fileNumber,
                                                                                                                createdByUserID: i.createdByUserID,
                                                                                                                mainCreatedInward_MasterID: i.file_Inward_MasterID,
                                                                                                                parentInwardID: i.parentInwardID
                                                                                                            }, true)
                                                                                                        }
                                                                                                    >
                                                                                                        ट्रॅक
                                                                                                    </button>
                                                                                                    // <TrackNasti
                                                                                                    //     // data={data}
                                                                                                    //     //  inwardNumber={i.inwardNumber}
                                                                                                    //     fileNumber={i.fileNumber}
                                                                                                    //     createdByUserID={i.createdByUserID}
                                                                                                    //     mainCreatedInward_MasterID={i.file_Inward_MasterID}
                                                                                                    //     parentInwardID={i.parentInwardID}
                                                                                                    // /> 
                                                                                                    : ''
                                                                                            }

                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                                :
                                                                                <tr><label style={{ whiteSpace: 'nowrap' }}>No Record Found ...</label></tr>
                                                                        }

                                                                    </tbody>

                                                                </table>

                                                                {
                                                                    showhide &&
                                                                    <LineGraph
                                                                        FourthData={FourthData}
                                                                        showhide={showhide}
                                                                        setshowhide={setshowhide}
                                                                        type='File'
                                                                    />
                                                                }

                                                                {
                                                                    TrackModalShow ?
                                                                        <TrackNastiModal
                                                                            setTrackModalShow={setTrackModalShow}
                                                                            TrackModalShow={TrackModalShow}
                                                                            TrackModalData={TrackModalData}
                                                                        />
                                                                        : ''
                                                                }

                                                                {ShohideTrack ?
                                                                    <Track
                                                                        setShohideTrack={setShohideTrack}
                                                                        ShohideTrackData={ShohideTrackData}
                                                                    />
                                                                    : <></>}

                                                                {
                                                                    ModalShow ?
                                                                        <CommFourthPaha
                                                                            ModalData={ModalData}
                                                                            setModalShow={setModalShow}
                                                                        />
                                                                        : ''
                                                                }

                                                                <div style={{ marginBottom: '12%' }}>
                                                                    <text style={{ textAlign: 'center' }}>
                                                                        <ScaleLoader color='#293990' loading={loaded} css={override} size={150} />
                                                                    </text>
                                                                    {
                                                                        NoRecords ?
                                                                            <text style={{ textAlign: 'center' }}>No More Records ...</text>
                                                                            : ''
                                                                    }
                                                                </div>





                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default CommFourthDrillPopUp
