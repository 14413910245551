
import React from 'react'
import Popup from 'reactjs-popup';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { baseUrl } from '../../../../../../BaseURL/BaseURL';
import $ from 'jquery'

export default function ({ setShohideTrack, ShohideTrackData, title, image, type, inwardNumber, createdByUserID, mainCreatedInward_MasterID, parentInwardID }) {

    const [tableData, settableData] = React.useState([])
    const UserID = sessionStorage.getItem("User")
    const [loader, setloader] = React.useState(false)

    $(function () {
        $(".modal-header").on("mousedown", function (e) {
            var $draggable = $(this);
            var x = e.pageX - $draggable.offset().left,
                y = e.pageY - $draggable.offset().top;
            $("body").on("mousemove", function (mousemoveEvt) {
                $draggable.closest(".modal-dialog").offset({
                    "left": mousemoveEvt.pageX - x,
                    "top": mousemoveEvt.pageY - y
                });
            });
            $("body").one("mouseup", function () {
                $("body").off("mousemove");
            });
        })
    })

    React.useEffect(() => {
        GetTrack()
    }, [])

    const GetTrack = () => {
        setloader(true)
        var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

        fetch(`${baseUrl}/InwardMaster/Get_Track_Select?MainCreatedInward_MasterID=${ShohideTrackData && ShohideTrackData.mainCreatedInward_MasterID}&CreatedByUserID=${ShohideTrackData && ShohideTrackData.createdByUserID}&UserID=${UserID}&ParentInwardID=${ShohideTrackData && ShohideTrackData.parentInwardID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status && result.data) {
                    settableData(result.data)
                } else {
                    settableData([])
                }
                setloader(false)
            })
            .catch(error => {
                setloader(false)
                console.log('error', error)
            });
    }

    const ExportToExcel2 = () => {
        return (
            <div style={{ display: 'inline', marginLeft: '20px' }}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button2"
                    className="download-table-xls-button btn btn-success btn-md mt-1"
                    table="table-to-xls2"
                    filename={`पत्र ट्रॅक(${ShohideTrackData && ShohideTrackData.inwardNumber})`}
                    sheet={`पत्र ट्रॅक(${ShohideTrackData && ShohideTrackData.inwardNumber})`}
                    buttonText="Export"
                    style={{ borderColor: 'black' }}
                />


                <table id="table-to-xls2" style={{ display: 'none' }}>
                    <h4 style={{ textAlign: 'center' }}>पत्र ट्रॅक</h4>
                    <h5><span>&emsp;</span></h5>

                    <h5 style={{ textAlign: 'center' }}>

                        <>
                            <span>{ShohideTrackData && ShohideTrackData.inwardNumber && `आवक क्रमांक: ${ShohideTrackData && ShohideTrackData.inwardNumber} `}</span>
                        </>

                        <h5><span>&emsp;</span></h5>
                    </h5>
                    <thead className="thead-dark" >
                        <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                            <th id='srwidth1'>अ.क्र</th>
                            <th style={{ width: '5%' }}>आवक क्रमांक </th>
                            <th style={{ width: '5%' }}>पत्र क्रमांक </th>
                            <th>पत्र दिनांक/मिळाल्याची दिनांक</th>
                            <th>कोणाकडून आले</th>
                            <th>कोणास पाठवले</th>
                            <th>पत्र दिनांक व वेळ</th>
                            {/* <th>पत्र</th> */}
                            <th>शेरा</th>
                            <th>पत्र स्थिती</th>

                        </tr>
                    </thead>
                    <tbody>
                        {tableData ? tableData.map((item, index) =>
                            <tr key={item.inward_TransferID} style={{ border: 'groove' }}>
                                <td align='center' id="srwidth">{index + 1}</td>
                                <td align='center' style={{ width: '8%' }} id="breakword">{item.inwardNumber ? item.inwardNumber : "-"}</td>
                                <td align='center' style={{ width: '8%' }} id="breakword">&nbsp;{item.receivedLetterNumber ? item.receivedLetterNumber : "-"}</td>
                                <td>
                                    {item.letterDate ? moment(item.letterDate).format("DD-MM-YYYY") : ""}
                                    <br />
                                    {item.letterReceivedDate ? moment(item.letterReceivedDate).format("DD-MM-YYYY") : ""}

                                </td>
                                <td>{item.transfer_From_Name ? item.transfer_From_Name : ""}</td>
                                <td>{item.transfer_To_Name ? item.transfer_To_Name : ""}</td>
                                <td>
                                    {item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                        : "-"
                                    }
                                    <br />
                                    {item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('h:mm:ss a')
                                        : "-"
                                    }
                                </td>
                                {/* <td>
                                    {item.correspondLetterFilePath ? <a href={item.correspondLetterFilePath}>View</a> : ""}

                                </td> */}
                                <td>{item.correspondRemark ? item.correspondRemark : ""}</td>
                                <td>{item.statusName ? item.statusName : ""}</td>

                            </tr>
                        )
                            : <></>
                        }

                    </tbody>
                </table>
            </div>
        )
    }


    return (


        <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className=" modal-dialog-scrollable " style={{ width: "100%" }}>
                        <div className="modal-content" style={{ width: "100%" }}>

                            <div className="modal-header" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "flex-end" }}>
                                <h2 className="modal-title" id="staticBackdropLabel">पत्र ट्रॅक </h2>
                                <span style={{ fontSize: 12, fontWeight: "600", wordBreak: "break-all" }}>{inwardNumber}</span>
                                <ExportToExcel2 />
                                <button type="button" className="btn-close mr-2 mt-1" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                    onClick={() => {
                                        setShohideTrack(false)
                                    }}>
                                    <h1 aria-hidden="true" style={{ color: 'white' }}>&times;</h1>
                                </button>

                                {/* <button onClick={() => setShohideTrack(false)} className="btn-close" type="button">x</button> */}
                            </div>

                            <div className="modal-body" style={{ width: "100%" }}>

                                {
                                    loader ?
                                        <Loader fullPage loading />
                                        : <></>

                                }
                                <div
                                    className="table-responsive"
                                    // onScroll={handleScroll}
                                    style={{ height: '450px', width: 'auto', overflowY: "auto" }}
                                >
                                    <table className="table table-bordered data-table">
                                        <thead>
                                            <tr>
                                                <th id='srwidth1'>अ.क्र</th>
                                                <th>आवक क्रमांक </th>
                                                <th>पत्र क्रमांक </th>
                                                <th>पत्र दिनांक/मिळाल्याची दिनांक</th>
                                                <th>कोणाकडून आले</th>
                                                <th>कोणास पाठवले</th>
                                                <th>पत्र दिनांक व वेळ</th>
                                                {/* <th>पत्र</th> */}
                                                <th>शेरा</th>
                                                <th>पत्र स्थिती</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {tableData ?
                                                tableData.map((item, index) => (

                                                    <tr key={item.inward_TransferID}>
                                                        <td align='center' id="srwidth">{index + 1}</td>
                                                        <td align='center' style={{ width: '8%',whiteSpace:'nowrap' }} >{item.inwardNumber ? item.inwardNumber : "-"}</td>
                                                        <td align='center' style={{ width: '8%',whiteSpace:'nowrap' }} >{item.receivedLetterNumber ? item.receivedLetterNumber : "-"}</td>
                                                        <td align='center' style={{ whiteSpace: 'nowrap' }}>
                                                            {item.letterDate ? moment(item.letterDate).format("DD-MM-YYYY") : "-"}
                                                            <hr style={{ padding: 0, margin: 0 }} />
                                                            {item.letterReceivedDate ? moment(item.letterReceivedDate).format("DD-MM-YYYY") : "-"}

                                                        </td>
                                                        <td>{item.transfer_From_Name ? item.transfer_From_Name : "-"}</td>
                                                        <td>{item.transfer_To_Name ? item.transfer_To_Name : "-"}</td>
                                                        <td align='center' style={{ width: '10%', whiteSpace: 'nowrap' }}>
                                                            {item.lastModifiedDate ?
                                                                moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                                                : "-"
                                                            }
                                                            <hr style={{ padding: 0, margin: 0 }} />
                                                            {item.lastModifiedDate ?
                                                                moment(item.lastModifiedDate).format('h:mm:ss a')
                                                                : "-"
                                                            }
                                                        </td>

                                                        <td style={{ width: '10%' }}>{item.correspondRemark ? item.correspondRemark : "-"}</td>
                                                        <td align='center' id="srwidth">{item.statusName ? item.statusName : "-"}</td>

                                                    </tr>
                                                )) :
                                                <></>
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >


    )
}