

import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';
import { Eye, Search } from 'react-feather';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Loader } from 'react-overlay-loader';
import { baseUrl } from '../../BaseURL/BaseURL';
import moment from 'moment';
import InboxLetterModal from '../../Pages/Private/File/PopUp/InboxLetterModal/InboxLetterModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ExportToExcel } from '../../Pages/Private/Letter/InboxLetter/ExportToExcel';
import { ExportSearchLetter } from '../../Pages/Private/Letter/InboxLetter/ExportSearchLetter';
import { ExportToExcelInboxLetter } from '../../Pages/Private/Letter/InboxLetter/ExportToExcelInboxLetter';

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function SearchPage({ title, remark }) {
    const [SaveLetter, setSaveLetter] = React.useState(false)
    const [PerPage, setPerPage] = React.useState(20)
    const [TableData, setTableData] = React.useState([])
    const [loading, setloading] = React.useState(false)
    const [loaded, setloaded] = React.useState(false)

    const [M_LetterClassification, setM_LetterClassification] = React.useState([])
    const [M_LetterClassificationID, setM_LetterClassificationID] = React.useState('0')

    const [M_ClassificationWiseLetterType, setM_ClassificationWiseLetterType] = React.useState([])
    const [M_ClassificationWiseLetterTypeID, setM_ClassificationWiseLetterTypeID] = React.useState('0')

    const [DepartmentNameMaster, setDepartmentNameMaster] = React.useState([])
    const [DepartmentNameMasterID, setDepartmentNameMasterID] = React.useState('0')

    const SaveSucess = () => {
        setSaveLetter(!SaveLetter)
    }

    const handleContainerOnBottom = () => {

        if (TableData) {
            if (PerPage <= TableData.length) {
                setloading(false)
                setloaded(true)
                setPerPage(PerPage + 20)
            }
        }
    };

    const containerRef = useBottomScrollListener(handleContainerOnBottom);


  





    const Get_DDL_M_LetterClassification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DB_DDL_M_LetterClassification?UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setM_LetterClassification(result.data)

            })
            .catch(error => console.log('error', error));
    }

    React.useEffect(() => {
        if (M_LetterClassificationID !== '0') {
            Get_DDL_M_ClassificationWiseLetterType()
        }
    }, [M_LetterClassificationID])

    const M_OfficeMasterID = sessionStorage.getItem('M_OfficeMasterID')

    const Get_DDL_M_ClassificationWiseLetterType = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/CommonDLLData/Get_DB_DDL_M_ClassificationWiseLetterType?M_OfficeMasterID=${M_OfficeMasterID}&LetterClassificationID=${M_LetterClassificationID}&UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setM_ClassificationWiseLetterType(result.data)
            })
            .catch(error => console.log('error', error));
    }



    const Get_DB_DDL_M_DepartmentNameMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DB_DDL_M_DepartmentNameMaster?UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setDepartmentNameMaster(result.data)
                } else {
                    setDepartmentNameMaster([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const [OutwardNumber, setOutwardNumber] = React.useState('')
    const [InwardNumber, setInwardNumber] = React.useState('')
    const [ReceivedLetterNumber, setReceivedLetterNumber] = React.useState('')
    const [LetterDate, setLetterDate] = React.useState('')
    const [LetterReceivedDate, setLetterReceivedDate] = React.useState('')
    const [LetterSubject, setLetterSubject] = React.useState('')
    const [SenderMobileNo, setSenderMobileNo] = React.useState('')
    const [LetterSenderName_Designation, setLetterSenderName_Designation] = React.useState('')
    const [LetterSenderOfficeName, setLetterSenderOfficeName] = React.useState('')
    const [IsClear, setIsClear] = React.useState(false)

    const Clear = () => {
       setIsClear(!IsClear)
        setPerPage(20)
        setOutwardNumber('')
        setReceivedLetterNumber('')
        setLetterDate('')
        setLetterReceivedDate('')
        setLetterSubject('')
        setSenderMobileNo('')
        setLetterSenderName_Designation('')
        setLetterSenderOfficeName('')
        setM_LetterClassificationID('0')
        setM_ClassificationWiseLetterTypeID('0')
        setDepartmentNameMasterID('0')
        Get_DB_DDL_M_DepartmentNameMaster()
        Get_DDL_M_LetterClassification()
        
        // setTimeout(() => {
        //     GetSearchTableData()
        // }, 500);
    }

    React.useEffect(() => {
        // if (PerPage > 20) {
            GetSearchTableData()
        // }
    }, [PerPage, IsClear])

    const UserID = sessionStorage.getItem("User")

    const [tableExcelData, setTableExcelData] = React.useState('')

    const GetSearchTableExcelData = () => {
        if (loaded) {
            setloading(false)
        } else {
            setloading(true)
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/Dashboard/Get_DB_All_InwardSearchSelect?M_DepartmentNameMasterID=${DepartmentNameMasterID ? DepartmentNameMasterID : ''}&OutwardNumber=${OutwardNumber ? OutwardNumber : ''}&InwardNumber=${InwardNumber ? InwardNumber : ''}&ReceivedLetterNumber=${ReceivedLetterNumber ? ReceivedLetterNumber : ''}&M_LetterClassificationID=${M_LetterClassificationID ? M_LetterClassificationID : '0'}&M_ClassificationWiseLetterTypeID=${M_ClassificationWiseLetterTypeID ? M_ClassificationWiseLetterTypeID : '0'}&LetterDate=${LetterDate ? LetterDate : ''}&LetterReceivedDate=${LetterReceivedDate ? LetterReceivedDate : ''}&LetterSubject=${LetterSubject ? LetterSubject : ''}&SenderMobileNo=${SenderMobileNo ? SenderMobileNo : ''}&LetterSenderName_Designation=${LetterSenderName_Designation ? LetterSenderName_Designation : ''}&LetterSenderOfficeName=${LetterSenderOfficeName ? LetterSenderOfficeName : ''}&UserID=${UserID}&Flag=&Top=${PerPage}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setTableExcelData(result.data)
                } else {
                    setTableExcelData([])
                    // toast.loading(result.message)
                }
                setloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setTableExcelData([])
                setloading(false)
            });
    }
    const GetSearchTableData = () => {
        if (loaded) {
            setloading(false)
        } else {
            setloading(true)
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/Dashboard/Get_DB_All_InwardSearchSelect?M_DepartmentNameMasterID=${DepartmentNameMasterID ? DepartmentNameMasterID : ''}&OutwardNumber=${OutwardNumber ? OutwardNumber : ''}&InwardNumber=${InwardNumber ? InwardNumber : ''}&ReceivedLetterNumber=${ReceivedLetterNumber ? ReceivedLetterNumber : ''}&M_LetterClassificationID=${M_LetterClassificationID ? M_LetterClassificationID : '0'}&M_ClassificationWiseLetterTypeID=${M_ClassificationWiseLetterTypeID ? M_ClassificationWiseLetterTypeID : '0'}&LetterDate=${LetterDate ? LetterDate : ''}&LetterReceivedDate=${LetterReceivedDate ? LetterReceivedDate : ''}&LetterSubject=${LetterSubject ? LetterSubject : ''}&SenderMobileNo=${SenderMobileNo ? SenderMobileNo : ''}&LetterSenderName_Designation=${LetterSenderName_Designation ? LetterSenderName_Designation : ''}&LetterSenderOfficeName=${LetterSenderOfficeName ? LetterSenderOfficeName : ''}&UserID=${UserID}&Flag=&Top=${PerPage}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    setTableData(result.data)
                } else {
                    setTableData([])
                    // toast.loading(result.message)
                }
                setloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setTableData([])
                setloading(false)
            });
    }
    // const GetSearchTableExport = () => {
    //     if (loaded) {
    //         setloading(false)
    //     } else {
    //         setloading(true)
    //     }
    //     var requestOptions = {
    //         method: 'GET',
    //         redirect: 'follow'
    //     };
    //     fetch(`${baseUrl}/Dashboard/Get_DB_All_InwardSearchSelect?M_DepartmentNameMasterID=${DepartmentNameMasterID ? DepartmentNameMasterID :''}&OutwardNumber=${OutwardNumber ? OutwardNumber : ''}&InwardNumber=${InwardNumber ? InwardNumber : ''}&ReceivedLetterNumber=${ReceivedLetterNumber ? ReceivedLetterNumber : ''}&M_LetterClassificationID=${M_LetterClassificationID ? M_LetterClassificationID : '0'}&M_ClassificationWiseLetterTypeID=${M_ClassificationWiseLetterTypeID ? M_ClassificationWiseLetterTypeID : '0'}&LetterDate=${LetterDate ? LetterDate : ''}&LetterReceivedDate=${LetterReceivedDate ? LetterReceivedDate : ''}&LetterSubject=${LetterSubject ? LetterSubject : ''}&SenderMobileNo=${SenderMobileNo ? SenderMobileNo : ''}&LetterSenderName_Designation=${LetterSenderName_Designation ? LetterSenderName_Designation : ''}&LetterSenderOfficeName=${LetterSenderOfficeName ? LetterSenderOfficeName : ''}&UserID=${UserID}&Flag=&Top=20}`, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             console.log(result)
    //             if (result.code >= 200 && result.code <= 300 && result.data) {
    //                 setTableData(result.data)
    //             } else {
    //                 setTableData([])
    //                 // toast.loading(result.message)
    //             }
    //             setloading(false)
    //         })
    //         .catch(error => {
    //             console.log('error', error)
    //             setTableData([])
    //             setloading(false)
    //         });
    // }


    return (

        <Popup
            onOpen={() => { Clear() }}
            contentStyle={{ width: '85vw', height: '95vh', overflow: 'hidden' }}
            trigger={

                <span>
                    <button className="btn btn-success p-2" >
                        <Search size={16} />
                        &nbsp;Search
                    </button>
                    {/* <a data-tooltip="tooltip" title="Search"
                        className="edit-btn text-primary alert-primary m-1"
                        style={{ cursor: 'pointer' }}
                    >
                        <Search color="green" size={18} />
                    </a> */}
                </span>

            } modal={true} >
            {
                close => (
                    // <Fade>
                    <div className="modal-dialog modal-dialog-scrollable modal-xl ">
                        <div className="modal-content mt-n3">
                            <div className="modal-header">
                                <h3 className="modal-title mt-2" id="staticBackdropLabel">पत्र शोधा</h3>
                                <button onClick={() => close()} className="btn-close mt-1" type="button">x</button>
                            </div>
                            {
                                loading ?
                                    <Loader fullPage loading />
                                    : <></>

                            }
                            <div className="modal-body" style={{ overflow: 'auto' }}>
                                <main>
                                    <div className="filter mt-n4">
                                        <div className="form-row">

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="priority">विभागाचे नाव <span style={{ color: 'red' }}> </span></label>
                                                <div>
                                                    <select
                                                        id="1"
                                                        className="form-control input-lg"
                                                        name="letterSorting"
                                                        // id="letterSorting"
                                                        value={DepartmentNameMasterID}
                                                        onChange={(e) => {
                                                            setDepartmentNameMasterID(e.target.value)
                                                            // document.getElementById('Prakar').selectedIndex = 0
                                                        }}
                                                    >
                                                        <option value={"0"} disabled>निवडा...</option>
                                                        {
                                                            DepartmentNameMaster && DepartmentNameMaster !== null ? DepartmentNameMaster.map(item =>
                                                                <option value={item.id}>{item.departmentName}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }

                                                    </select>
                                                </div>
                                            </div>


                                            {/* <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">कार्यालय</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setLetterSenderOfficeName(e.target.value)}
                                                        value={LetterSenderOfficeName}
                                                    // autoFocus={true}
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">आवक क्रमांक</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setInwardNumber(e.target.value)}
                                                        value={InwardNumber}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">जावक क्रमांक</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setOutwardNumber(e.target.value)}
                                                        value={OutwardNumber}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">पत्र दिनांक</label>
                                                <div>
                                                    <input
                                                        type="date"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setLetterDate(e.target.value)}
                                                        value={LetterDate}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">पत्र मिळाल्याचा दिनांक</label>
                                                <div>
                                                    <input
                                                        type="date"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        value={LetterReceivedDate}
                                                        min={LetterDate}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        onChange={(e) => setLetterReceivedDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">पत्र क्रमांक</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        id="letterSubject"
                                                        onChange={(e) => setReceivedLetterNumber(e.target.value)}
                                                        value={ReceivedLetterNumber}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3 form-group">
                                                <label className="control-label" for="letterSubject">पत्राचा विषय</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setLetterSubject(e.target.value)}
                                                        value={LetterSubject}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="priority">पत्र वर्गीकरण <span style={{ color: 'red' }}> *</span></label>
                                                <div>
                                                    <select
                                                        id="1"
                                                        className="form-control input-lg"
                                                        name="letterSorting"
                                                        // id="letterSorting"
                                                        value={M_LetterClassificationID}
                                                        onChange={(e) => {
                                                            setM_LetterClassificationID(e.target.value)
                                                            // document.getElementById('Prakar').selectedIndex = 0
                                                        }}
                                                    >
                                                        <option value={"0"} disabled>निवडा...</option>
                                                        {
                                                            M_LetterClassification && M_LetterClassification !== null ? M_LetterClassification.map(item =>
                                                                <option value={item.id}>{item.classificationName}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="priority">पत्र प्रकार <span style={{ color: 'red' }}> *</span></label>
                                                <div>
                                                    <select
                                                        id="Prakar"
                                                        className="form-control input-lg"
                                                        name="priority"
                                                        // id="priority"
                                                        value={M_ClassificationWiseLetterTypeID}
                                                        onChange={(e) => setM_ClassificationWiseLetterTypeID(e.target.value)}

                                                    >
                                                        <option value={"0"} selected disabled>निवडा...</option>
                                                        {
                                                            M_ClassificationWiseLetterType !== null ? M_ClassificationWiseLetterType.map(item =>
                                                                <option value={item.id}>{item.letterTypeName}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }
                                                    </select>
                                                </div>
                                            </div>



                                            <div className="col-12 col-md-6 col-lg-3 form-group">
                                                <label className="control-label" for="letterSubject">पत्र पाठविणाऱ्याचे नाव व पदनाम</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="LetterSenderN"
                                                        id="LetterSenderN"
                                                        onChange={(e) => setLetterSenderName_Designation(e.target.value)}
                                                        value={LetterSenderName_Designation}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">मोबाईल क्रमांक</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setSenderMobileNo(e.target.value)}
                                                        value={SenderMobileNo}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>
                                                <button
                                                    // disabled={M_LetterClassificationID == '0' || M_ClassificationWiseLetterTypeID == '0' || M_LetterClassificationID == '' || M_ClassificationWiseLetterTypeID == ''}
                                                    type="button"
                                                    className='btn btn-primary'
                                                    onClick={() => GetSearchTableData()}
                                                >
                                                    Search
                                                </button>
                                            </div> */}

                                            {/* <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>
                                                <button type="button" className='btn btn-primary' onClick={() => Clear()}>Clear</button>
                                            </div> */}

                                            <div className=" col-md-6 col-lg-2 mt-1 mt-md-2 pt-md-1">

                                                <button type="button" className="btn btn-primary ml-1 ml-md-3 "
                                                    onClick={() => GetSearchTableData()}
                                                >Search</button>

                                                <button type="button" className="btn btn-primary ml-3"
                                                    onClick={() => {Clear(); setInwardNumber('')}}
                                                >Clear</button>

                                            </div>

                                            <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>
                                                {/* <ExportSearchLetter  */}

                                                {/* GetSearchTableExport={GetSearchTableExport}
                                                    <ExportToExcelInboxLetter
                                    Excelbranchescustomdata={Excelbranchescustomdata}
                                    StatusName={StatusName}
                                    LetterDate={LetterDate}
                                /> */}




                                            </div>
                                            <div className="col-auto ml-auto mr-1 text-right mt-n1">



                                            </div>

                                        </div>
                                    </div>

                                    <div className="table-responsive"
                                        ref={containerRef}
                                        style={{ height: '40vh', width: 'auto', overflowY: "auto", marginBottom: '0%' }}>


                                        <table className="table table-bordered data-table">
                                            <thead>
                                                <tr>

                                                    <th id='srwidth1' style={{ width: '1%' }}>अ.क्र</th>
                                                    <th>आवक क्रमांक</th>
                                                    <th>पत्र क्रमांक</th>
                                                    <th>पत्र दिनांक/मिळाल्याची दिनांक</th>
                                                    <th>पत्र कोणाकडून आले</th>
                                                    <th>पत्र कोणास पाठवले</th>
                                                    <th>पत्राचा विषय</th>
                                                    <th align='center'>पहा</th>
                                                    <th>पत्र वर्गीकरण/ प्रकार</th>
                                                    <th>पत्र पाठविणाऱ्याचे नाव व पदनाम</th>
                                                    <th>मोबाईल क्रमांक</th>
                                                    <th>स्त्तिथी</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length !== 0 ? TableData.map((i, j) => {
                                                        return (
                                                            <tr>

                                                                <td align='center'>{j + 1}</td>
                                                                <td style={{ width: '15%' }} id="breakword">{i.inwardNumber ? i.inwardNumber : '-'}</td>
                                                                <td style={{ width: '8%' }} id="breakword">{i.receivedLetterNumber ? i.receivedLetterNumber : '-'}</td>
                                                                <td style={{ width: '8%', whiteSpace: 'nowrap' }}>
                                                                    <>
                                                                        <tr style={{ borderColor: 'transparent' }}>{i.letterDate ? moment(i.letterDate).format('DD-MM-YYYY') : ''}</tr>
                                                                        <hr style={{ margin: '0' }}></hr>
                                                                        <tr style={{ borderColor: 'transparent' }}>{i.letterReceivedDate ? moment(i.letterReceivedDate).format('DD-MM-YYYY') : ''}</tr>
                                                                    </>
                                                                </td>
                                                                <td style={{ width: '10%' }}>
                                                                    {i.transfer_From_Name ? i.transfer_From_Name : ''}
                                                                </td>

                                                                <td style={{ width: '10%' }}>
                                                                    {i.transfer_To_Name ? i.transfer_To_Name : ''}
                                                                </td>

                                                                <td style={{ width: '30%' }} id="breakword">{i.letterSubject ? i.letterSubject : ''}</td>
                                                                <td style={{ width: '5%' }} align='center'>
                                                                    {
                                                                        i.inwardLetterFilePath !== null ?
                                                                            <InboxLetterModal
                                                                                image={i.inwardLetterFilePath}
                                                                                inwardNumber={i.inwardNumber}

                                                                            />
                                                                            : <text style={{ textAlign: 'center' }}>-</text>
                                                                    }


                                                                </td>

                                                                <td style={{ width: '8%' }}>
                                                                    {i.classificationName ? i.classificationName + ' ' + i.letterTypeName : '-'}
                                                                </td>

                                                                <td align='center'>{i.letterSenderOfficeName ? i.letterSenderOfficeName : '-'}</td>
                                                                <td align='center'>{i.senderMobileNo ? i.senderMobileNo : '-'}</td>

                                                                <td style={{ width: '5%' }} align='center'>
                                                                    {i.statusName ? i.statusName : '-'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })


                                                        : <tr>
                                                            <td colSpan={9}> माहिती उपलब्ध नाही
                                                                <hr style={{ height: "10px", color: "#000", backgroundColor: "#000", margin: 0 }} />
                                                            </td>
                                                        </tr>
                                                }



                                            </tbody>
                                        </table>

                                        <div style={{ marginBottom: '8%' }}>
                                            <text style={{ textAlign: 'center' }}>
                                                <ScaleLoader color='#293990' loading={loaded} css={override} size={150} />
                                            </text>
                                        </div>

                                    </div>


                                </main>

                            </div>

                        </div>
                    </div>
                    // </Fade>
                )
            }
        </Popup>
    )
}

export default SearchPage
