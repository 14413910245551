
import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
// import {Chart, ArcElement} from 'chart.js'
// Chart.register(ArcElement);
import "chartjs-plugin-piechart-outlabels";

export default class Max_Pendancy_BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: {
                // labels: ["Good", "Fair", "Bad", "No Feedback"],
                labels: this.props.labels,
                datasets: [{
                    // data: [45, 22, 21, 10],
                    data: this.props.data,
                    // backgroundColor: ["#fe974a", "#0063ff", "#3dd598", "#ffc442"]
                    backgroundColor: this.props.bgColor,

                }],
                barWidth: this.props.barWidth

            }
        }
    }


    render() {
        // console.log(this.props.chart3.labels.length)
        // var labelLength = (this.props.chart3.labels ? this.props.chart3.labels.length : 0)
        const RoleID = sessionStorage.getItem('RoleID')
        return (
            <div className="Chart">
                <Bar
                    id={this.props.id}
                    data={this.state.chartData}
                    width={800}
                    height={420}

                    options={{
                        elements: {
                            arc: {
                                borderWidth: 0
                            },

                        },
                        legend: {
                            // display:false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    drawOnChartArea: false
                                },
                                // barPercentage: 0.2,
                                barThickness: 30,
                                ticks: {
                                    autoSkip: false,
                                    display: false
                                },
                                // labels:
                            }],
                            yAxes: [{
                                gridLines: {
                                    drawOnChartArea: false
                                },
                                // barPercentage: 0.2,
                                ticks: {
                                    // autoSkip:false
                                    beginAtZero: true,
                                    stepSize: 1
                                }
                            }]
                        },
                        // plugins: {
                        //     labels: {
                        //         render: 'label',
                        //         fontColor: 'transparent',
                        //         position: 'outside',
                        //         arc: false,
                        //         textMargin: 5,
                        //         // overlap:false
                        //     },
                        //     outlabels: {
                        //         display: false,
                        //         color: "white",
                        //         // text: "%l\n%v (%p)",
                        //         stretch: 15,
                        //         font: {
                        //             resizable: true,
                        //             minSize: 12,
                        //             maxSize: 16
                        //         }
                        //     },
                        // },
                        plugins: {
                            labels: {
                                render: 'label',
                                fontColor: (RoleID && RoleID === '4' ? 'transparent' : 'black'),
                                position: 'outside',
                                arc: false,
                                textMargin: 5,
                                // overlap:false
                            },
                            outlabels: {
                                display: (RoleID && RoleID === '4' ? false : true),
                                color: "white",
                                // text: "%l\n%v (%p)",
                                stretch: 1,
                                font: {
                                    resizable: true,
                                    minSize: 10,
                                    maxSize: 10
                                }
                            },
                        },
                        layout: {
                            padding: {
                                top: 25,
                                bottom: 28
                            }
                        },
                        legend: {
                            display: false,
                            position: "right"
                        },
                        // segmentShowStroke: false,
                        // borderWidth:'-2',
                        // borderColor:'rgba(0, 0, 0, 0)',
                        // tooltips: {
                        //     callbacks: {
                        //         label: function (tooltipItem, data) {
                        //             //get the concerned dataset
                        //             var dataset = data.datasets[tooltipItem.datasetIndex];
                        //             //calculate the total of this data set
                        //             var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                        //                 return previousValue + currentValue;
                        //             });
                        //             //get the current items value
                        //             var currentValue = dataset.data[tooltipItem.index];
                        //             //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                        //             var percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                        //             return percentage + "%" + "test";
                        //         }
                        //     }
                        // }

                        tooltip: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    //   console.log(tooltipItem)
                                    return tooltipItem?.value;
                                }
                            }
                        }
                    }}

                />





            </div>
        )
    }

}
