

export default function ErrorFallback() {

    const Logout = () => {
        // sessionStorage.clear();
        // localStorage.clear();
        // navigate('/', { replace: true })
        window.location.reload()
    };

    return (
        <div role="alert" className="text-center">
            <p>Something went wrong:</p>
          
            <button type="button" onClick={Logout}>Try again</button>
        </div>
    )
}