import React, { lazy, Suspense } from 'react';
import { Loader } from 'react-overlay-loader';
import { DeptKaryasanWorkAccuracyTable, DeptPieChartTop3MostGenerated, DeptReferenceTypeDDLAPI, DeptReferenceTypeWiseDeptAndTable, ReferenceTypeDDLAPI } from '../BranchAPI/BranchFileAPI';
import DeptTable from '../BranchTable/BranchTable';
import DeptTableChart from '../BranchTable/BranchTableChart';
import $ from 'jquery'
import WorkAccuracyTable from '../BranchWorkAccuracy/BranchWorkAccuracyTable';
import WorkAccuracyPieChart from '../BranchWorkAccuracy/BranchWorkAccuracyPieChart';
import BranchFileMianCard from './BranchFileMianCard';
import BranchTable from '../BranchTable/BranchTable';
import BranchTableChart from '../BranchTable/BranchTableChart';
import BranchWorkAccuracyTable from '../BranchWorkAccuracy/BranchWorkAccuracyTable';

const BranchPerformer = lazy(() => import('../BranchPerformer/BranchPerformer'))
// const BranchFileMianCard = lazy(() => import('./BranchFileMianCard'))



export default function BranchFile({ flag, dashloading, DashCount, IsBranchSelect, Countdata }) {

    const UserID = sessionStorage.getItem("User")

    const [Top3MostGeneratedFiles, setTop3MostGeneratedFiles] = React.useState([])
    const [Top3MostLoader, setTop3MostLoader] = React.useState(false)
    const [TopMostlabels, setTopMostlabels] = React.useState([])
    const [TopMostCount, setTopMostCount] = React.useState([])


    const [Top3MINGeneratedFiles, setTop3MINGeneratedFiles] = React.useState([])
    const [Top3MINLoader, setTop3MINLoader] = React.useState(false)
    const [TopMINlabels, setTopMINlabels] = React.useState([])
    const [TopMINCount, setTopMINCount] = React.useState([])

    const [Top3MAXCloseGeneratedFiles, setTop3MAXCloseGeneratedFiles] = React.useState([])
    const [Top3MAXCloseLoader, setTop3MAXCloseLoader] = React.useState(false)
    const [TopMAXCloselabels, setTopMAXCloselabels] = React.useState([])
    const [TopMAXCloseCount, setTopMAXCloseCount] = React.useState([])

    const [Top3MINCloseGeneratedFiles, setTop3MINCloseGeneratedFiles] = React.useState([])
    const [Top3MINCloseLoader, setTop3MINCloseLoader] = React.useState(false)
    const [TopMINCloselabels, setTopMINCloselabels] = React.useState([])
    const [TopMINCloseCount, setTopMINCloseCount] = React.useState([])

    const [ReferenceTypeDDL, setReferenceTypeDDL] = React.useState([])
    const [ddlloader, setddlloader] = React.useState(false)

    const [showhide, setshowhide] = React.useState(false)
    const [Workshowhide, setWorkshowhide] = React.useState(false)
    const [ReferenceTypeTableData, setReferenceTypeTableData] = React.useState([])
    const [tableloader, settableloader] = React.useState(false)
    const [PendingCount, setPendingCount] = React.useState([])
    const [Reflabels, setReflabels] = React.useState([])
    const [PColor, setPColor] = React.useState([])
    const [PieLoader, setPieLoader] = React.useState(false)
    const [SPPieFlag, setSPPieFlag] = React.useState('All-Day')

    const [clickflag, setclickflag] = React.useState('MAX')
    const [clickfilterflag, setclickfilterflag] = React.useState('this_month')

    const [MINclickflag, setMINclickflag] = React.useState('MIN')
    const [MINclickfilterflag, setMINclickfilterflag] = React.useState('this_month')

    const [CloseMaxclickflag, setCloseMaxclickflag] = React.useState('MAX')
    const [CloseMaxclickfilterflag, setCloseMaxclickfilterflag] = React.useState('this_month')

    const [CloseMinclickflag, setCloseMinclickflag] = React.useState('MIN')
    const [CloseMinclickfilterflag, setCloseMinclickfilterflag] = React.useState('this_month')

    const [WorkTableData, setWorkTableData] = React.useState([])
    const [WorkLoader, setWorkLoader] = React.useState(false)
    const [WorkPieLoader, setWorkPieLoader] = React.useState(false)


    React.useEffect(() => {
        DeptPieChartTop3MostGenerated('MAX', 'this_month', 'Created', setTop3MostGeneratedFiles, setTopMostlabels, setTopMostCount, setTop3MostLoader, UserID)
        DeptPieChartTop3MostGenerated('MIN', 'this_month', 'Created', setTop3MINGeneratedFiles, setTopMINlabels, setTopMINCount, setTop3MINLoader, UserID)
        DeptPieChartTop3MostGenerated('MAX', 'this_month', 'Close', setTop3MAXCloseGeneratedFiles, setTopMAXCloselabels, setTopMAXCloseCount, setTop3MAXCloseLoader, UserID)
        DeptPieChartTop3MostGenerated('MIN', 'this_month', 'Close', setTop3MINCloseGeneratedFiles, setTopMINCloselabels, setTopMINCloseCount, setTop3MINCloseLoader, UserID)
        DeptReferenceTypeDDLAPI(setReferenceTypeDDL, setddlloader, UserID)
        DeptKaryasanWorkAccuracyTable(setWorkTableData, setWorkLoader, 'All_Accuracy', 'TableUserWise', UserID)
    }, [IsBranchSelect])



    const onChangeTop3MostGenerated = (flag, filter, showby) => {
        setclickflag(flag)
        setclickfilterflag(filter)
        DeptPieChartTop3MostGenerated(flag, filter, showby, setTop3MostGeneratedFiles, setTopMostlabels, setTopMostCount, setTop3MostLoader, UserID)
    }


    const onChangeTop3MINGenerated = (flag, filter, showby) => {
        setMINclickflag(flag)
        setMINclickfilterflag(filter)
        DeptPieChartTop3MostGenerated(flag, filter, showby, setTop3MINGeneratedFiles, setTopMINlabels, setTopMINCount, setTop3MINLoader, UserID)
    }

    const onChangeTop3MAXCloseGenerated = (flag, filter, showby) => {
        setCloseMaxclickflag(flag)
        setCloseMaxclickfilterflag(filter)
        DeptPieChartTop3MostGenerated(flag, filter, showby, setTop3MAXCloseGeneratedFiles, setTopMAXCloselabels, setTopMAXCloseCount, setTop3MAXCloseLoader, UserID)
    }

    const onChangeTop3MINCloseGenerated = (flag, filter, showby) => {
        setCloseMinclickflag(flag)
        setCloseMinclickfilterflag(filter)
        DeptPieChartTop3MostGenerated(flag, filter, showby, setTop3MINCloseGeneratedFiles, setTopMINCloselabels, setTopMINCloseCount, setTop3MINCloseLoader, UserID)
    }


    const onChnageDDL = () => {
        let id = sessionStorage.getItem('refid')
        setSPPieFlag('All-Day')
        DeptReferenceTypeWiseDeptAndTable(setReferenceTypeTableData, settableloader, id, setPendingCount, setReflabels, setPColor, UserID, setddlloader)
        if (showhide) {
            OnChangePie(SPPieFlag)
        }
    }

    const OnChangePie = (DaysFilter) => {
        let id = sessionStorage.getItem('refid')
        // ReferenceTypeWiseDeptAndPie(setReferenceTypeTableData, settableloader, id, setPendingCount, setReflabels, setPColor, DaysFilter, setPieLoader, UserID)
    }


    return (
        <div>

            <div className="container-fluid p-0">


                <div className="row">
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchFileMianCard
                            flag={flag}
                            DashCount={DashCount}
                            Countdata={Countdata}
                            dashloading={dashloading}
                            IsBranchSelect={IsBranchSelect}
                        />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchPerformer
                            title="TOP 3 MOST FILES GENERATED"
                            flagButton='MAX'
                            showby='Created'
                            clickable='total'

                            fourthflag='TotalInwardCount'
                            fourthShowby='All'
                            fourthReferenceTypeID='0'

                            clickfilterflag={clickfilterflag}
                            clickflag={clickflag}
                            TopMostlabels={TopMostlabels}
                            TopMostCount={TopMostCount}
                            onChangeTop3MostGenerated={onChangeTop3MostGenerated}
                            Top3MostLoader={Top3MostLoader}
                            Top3MostGeneratedFiles={Top3MostGeneratedFiles}
                        />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchPerformer
                            title="TOP 3 MINIMUM FILES GENERATED"
                            flagButton='MIN'
                            showby='Created'
                            clickable='total'

                            fourthflag='TotalInwardCount'
                            fourthShowby='All'
                            fourthReferenceTypeID='0'

                            clickfilterflag={MINclickfilterflag}
                            clickflag={MINclickflag}
                            TopMostlabels={TopMINlabels}
                            TopMostCount={TopMINCount}
                            onChangeTop3MostGenerated={onChangeTop3MINGenerated}
                            Top3MostLoader={Top3MINLoader}
                            Top3MostGeneratedFiles={Top3MINGeneratedFiles}
                        />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchPerformer
                            title="TOP 3 - MAXIMUM FILES CLOSED"
                            flagButton='MAX'
                            showby='Close'
                            clickable='closed'

                            fourthflag='TotalCloseCount'
                            fourthShowby='All'
                            fourthReferenceTypeID='0'

                            clickfilterflag={CloseMaxclickfilterflag}
                            clickflag={CloseMaxclickflag}
                            TopMostlabels={TopMAXCloselabels}
                            TopMostCount={TopMAXCloseCount}
                            onChangeTop3MostGenerated={onChangeTop3MAXCloseGenerated}
                            Top3MostLoader={Top3MAXCloseLoader}
                            Top3MostGeneratedFiles={Top3MAXCloseGeneratedFiles}
                        />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchPerformer
                            title="TOP 3 - MINIMUM FILES CLOSED"
                            flagButton='MIN'
                            showby='Close'
                            clickable='closed'

                            fourthflag='TotalCloseCount'
                            fourthShowby='All'
                            fourthReferenceTypeID='0'


                            clickfilterflag={CloseMinclickfilterflag}
                            clickflag={CloseMinclickflag}
                            TopMostlabels={TopMINCloselabels}
                            TopMostCount={TopMINCloseCount}
                            onChangeTop3MostGenerated={onChangeTop3MINCloseGenerated}
                            Top3MostLoader={Top3MINCloseLoader}
                            Top3MostGeneratedFiles={Top3MINCloseGeneratedFiles}
                        />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchTable
                            ReferenceTypeDDL={ReferenceTypeDDL}
                            ddlloader={ddlloader}
                            ReferenceTypeTableData={ReferenceTypeTableData}

                            tableloader={tableloader}
                            onChnageDDL={onChnageDDL}
                            showhide={showhide}
                            setshowhide={setshowhide}
                            flag="show"
                            IsBranchSelect={IsBranchSelect}

                        />
                    </Suspense>

                    {
                        showhide ?
                            <Suspense fallback={<div><Loader fullPage loading /></div>}>
                                <BranchTableChart
                                    ReferenceTypeTableData={ReferenceTypeTableData}
                                    PendingCount={PendingCount}
                                    Reflabels={Reflabels}
                                    PColor={PColor}
                                    OnChangePie={OnChangePie}
                                    PieLoader={PieLoader}
                                    setSPPieFlag={setSPPieFlag}
                                    SPPieFlag={SPPieFlag}
                                    flag='file'
                                    IsBranchSelect={IsBranchSelect}
                                />
                            </Suspense>
                            : ''
                    }

                    {/* कार्यासनाच्या कामाची सरासरी */}
                    {/* अचूकता */}
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchWorkAccuracyTable
                            ReferenceTypeDDL={ReferenceTypeDDL}
                            ddlloader={ddlloader}
                            ReferenceTypeTableData={ReferenceTypeTableData}
                            WorkLoader={WorkLoader}
                            WorkTableData={WorkTableData}
                            tableloader={tableloader}
                            onChnageDDL={onChnageDDL}
                            Workshowhide={Workshowhide}
                            setWorkshowhide={setWorkshowhide}
                            flag="show"
                            IsBranchSelect={IsBranchSelect}

                        />
                    </Suspense>

                    {/* {
                        Workshowhide ?
                            <Suspense fallback={<div><Loader fullPage loading /></div>}>
                                <WorkAccuracyPieChart
                                    ReferenceTypeTableData={ReferenceTypeTableData}
                                    WorkPieLoader={WorkPieLoader}
                                    WorkTableData={WorkTableData}
                                    PendingCount={PendingCount}
                                    Reflabels={Reflabels}
                                    PColor={PColor}
                                    OnChangePie={OnChangePie}
                                    PieLoader={PieLoader}
                                    setSPPieFlag={setSPPieFlag}
                                    SPPieFlag={SPPieFlag}
                                    flag='file'
                                    IsBranchSelect={IsBranchSelect}
                                />
                            </Suspense>
                            : ''
                    } */}


                </div>
            </div >

        </div >
    );
}

