
import React from 'react'
// import { useAlert } from 'react-alert'
import { Link, useNavigate } from 'react-router-dom'
// import Footer from '../Footer/Footer'
import { baseUrl } from '../../../BaseURL/BaseURL'
import { toastError, toastSuccess } from '../../../helper/Toasthelper'
import Header from '../../../Component/Header/Header'


export default function UpdatedProfile() {
    // let history = useHistory()
    // let alert = useAlert()
    const navigate = useNavigate()
    const UserID = sessionStorage.getItem("User")

    const [OldPassword, setOldPassword] = React.useState('')
    const [CurrentPassword, setCurrentPassword] = React.useState('')
    const [NewPassword, setNewPassword] = React.useState('')
    const [passerror, setpasserror] = React.useState('')
    const [AddData, setAddData] = React.useState(false)

    // const ChangePassword = () => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));
    //     if (CurrentPassword !== NewPassword) {
    //         setpasserror("नवीन पासवर्ड आणि पुन्हा प्रविष्ट केलेला पासवर्ड जुळत नाहीत")
    //         setCurrentPassword('')
    //         setNewPassword('')
    //     }
    //     else {
    //         var formdata = new FormData();
    //         formdata.append("M_TableUserMasterID", UserID);
    //         formdata.append("CurrentPassword", OldPassword);
    //         formdata.append("Confirm_Password", NewPassword);

    //         var requestOptions = {
    //             method: 'POST',
    //             body: formdata,
    //             headers: myHeaders,
    //             redirect: 'follow'
    //         };

    //         fetch(`${baseUrl}/LFMS/Master/Post_M_TableUserMaster_Password_Reset`, requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.message === "Saved Successfully") {
    //                     toastSuccess(result.message)
    //                     setTimeout(() => {
    //                         sessionStorage.clear();
    //                         localStorage.clear();
    //                         navigate('/')
    //                     }, 1500);

    //                 } else {
    //                     alert.info(result.message)
    //                 }

    //             })
    //             .catch(error => {
    //                 toastError("Something went wrong!")
    //                 console.log('error', error)
    //             });
    //     }
    // }

    const Clear = () => {
        setNewPassword('')
        setOldPassword('')
        setCurrentPassword('')
        setpasserror('')
    }

    const Back = () => {
        navigate('/')
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye pr-3") {
            y.className = "fa fa-eye-slash pr-3";
        } else {
            y.className = "fa fa-eye pr-3";
        }
    }

    var UserLoginData
    const LoginDataa = (value) => {
        UserLoginData = value
    }

    return (
        <div className="wrapper">
            <div className="main">
                <Header LoginDataa={LoginDataa} />
                {/* <HeaderSP /> */}
                <main className="content">
                    <div className="container-fluid p-0">

                        <div className="container-fluid page-body-wrapper">

                            <div className="main-panel ml-5">
                                <div className="content-wrapper">



                                    <div className="card main-card col-12"
                                    // style={{width:'60%', display:'flex', justifyContent:'center', alignItems:'center'}}
                                    >
                                        <div className="form-row mt-3 " >
                                            <div className="col-12 col-md-6 col-lg-6" style={{ marginLeft: '45%' }}>
                                                <div className="row page-heading">
                                                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                        <h4 className="font-weight-bold d-flex ml-5">Profile Updated</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body row">
                                            <div className="col-12 col-lg-3"></div>

                                            <div className="col-12 col-lg-6">

                                                <div className="form-row p-3">

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>कार्यासनाचे नाव:</h4>
                                                    </div>
                                                    <div className="col-lg-8 form-group">
                                                        <span >
                                                            -
                                                        </span>
                                                    </div>
                                                    {/* </div> */}

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>अधिकारी / कर्मचारी नाव :</h4>
                                                    </div>

                                                    <div className="col-lg-8 form-group">
                                                        <span >
                                                            -
                                                        </span>
                                                    </div>
                                                    {/* </div> */}

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>युजर आयडी :</h4>
                                                    </div>

                                                    <div className="col-lg-8 form-group">
                                                        <span >
                                                            -
                                                        </span>
                                                    </div>
                                                    {/* </div> */}
                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>प्रणाली कार्यरत दिनांक :</h4>
                                                    </div>

                                                    <div className="col-lg-8 form-group">
                                                        <span >
                                                            -
                                                        </span>
                                                    </div>

                                                    <div className='col-lg-12 mb-3'></div>

                                                    <div className="col-lg-4 mt-2">
                                                        <h4 id='floatright'>मोबाईल क्रमांक:</h4>
                                                    </div>

                                                    <div className="col-lg-8 form-group">
                                                        <span >
                                                            -
                                                        </span>
                                                    </div>

                                                </div>




                                                {/* <div className='col-lg-12 mb-3' style={{color:'red'}}>{passerror}gfgh</div> */}
                                                <div className="form-row">
                                                    <div className="col-12 col-md-12 col-lg-12 mb-3 mt-n3" style={{ marginLeft: '35%' }}>
                                                        <span className="txt2"
                                                            // onClick={() => history.goBack()}
                                                            style={{ color: 'red' }}>
                                                            {passerror}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="form-row" >
                                                    <div className="col-12 col-md-6 col-lg-6" style={{ marginLeft: '35%' }}>

                                                        <Link
                                                            to="#tender"
                                                            data-toggle="modal"
                                                            data-target="#tender"
                                                            data-label="N95 Masks"
                                                            onClick={() => {
                                                                setAddData(true)

                                                            }}
                                                        >

                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary mb-1 ml-2"

                                                            > Profile Update</button>

                                                        </Link>

                                                        {/* <button
                                                            // disabled={OldPassword === '' || NewPassword === '' || CurrentPassword === ''}
                                                            type="button"
                                                            className="btn btn-primary"
                                                        // onClick={() => ChangePassword()}
                                                        >
                                                            Profile Update
                                                        </button> */}

                                                        <button
                                                            type="button"
                                                            className="btn btn-danger ml-2"
                                                            onClick={() => Clear()}
                                                        >
                                                            Clear
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* <div className="form-row mt-3">
                                                    <div className="col-12 col-md-12 col-lg-12" style={{ marginLeft: '35%' }}>
                                                        <span className="txt2"
                                                            onClick={() => Back()}
                                                            style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                                                            Go Back<i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div> */}


                                            </div>
                                            <div className="col-12 col-lg-3"></div>
                                        </div>

                                        <div className="modal fade" id="tender" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content">

                                                    <div className="modal-header py-2">
                                                        <h4 className="modal-title">
                                                            {
                                                                AddData ?
                                                                    'Profile Updated' : ''
                                                            }
                                                        </h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body">
                                                        <form action="#">
                                                            <div className="form-row m-3">


                                                                <div className="col-12 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label for="totalBal">कार्यासनाचे नाव <span style={{ color: 'red' }}> *</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            // id="EditBranchName"
                                                                            placeholder='कार्यासनाचे नाव'
                                                                        // value={EditBranchName}
                                                                        // onChange={(e) => setEditBranchName(e.target.value)}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label for="totalBal">अधिकारी / कर्मचारी नाव <span style={{ color: 'red' }}> *</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            // id="EditBranchCode"
                                                                            placeholder='अधिकारी / कर्मचारी नाव'
                                                                        // value={EditBranchCode}
                                                                        // onChange={(e) => setEditBranchCode(e.target.value)}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label for="totalBal">युजर आयडी<span style={{ color: 'red' }}> *</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            // id="EditBranchCode"
                                                                            placeholder='युजर आयडी'
                                                                        // value={EditBranchCode}
                                                                        // onChange={(e) => setEditBranchCode(e.target.value)}

                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label for="totalBal">प्रणाली कार्यरत दिनांक <span style={{ color: 'red' }}> *</span></label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            // id="EditBranchCode"
                                                                            placeholder='प्रणाली कार्यरत दिनांक'
                                                                        // value={EditBranchCode}
                                                                        // onChange={(e) => setEditBranchCode(e.target.value)}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label for="totalBal">मोबाईल क्रमांक<span style={{ color: 'red' }}> *</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            // id="EditBranchCode"
                                                                            placeholder='मोबाईल क्रमांक'
                                                                        // value={EditBranchCode}
                                                                        // onChange={(e) => setEditBranchCode(e.target.value)}

                                                                        />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div className="modal-footer">
                                                        {
                                                            AddData ?
                                                                <button
                                                                    type="button"
                                                                    // disabled={VibhagModalValue == null || VibhagModalValue == "" || EditBranchName == "" || EditBranchCode == ''}
                                                                    className="btn btn-primary"
                                                                    data-dismiss="modal"
                                                                // onClick={() => InsertData()}
                                                                >submit</button>
                                                                :
                                                                <button
                                                                    // disabled={VibhagModalValue == null || VibhagModalValue == "" || EditBranchName == "" || EditBranchCode == ''}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                // data-dismiss="modal"
                                                                // onClick={() => UpdateData()}

                                                                >Update</button>
                                                        }

                                                        <button type="button" className="btn btn-dark" data-dismiss="modal">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </main >
                {/* <Footer /> */}
            </div >
        </div >
    )
}
