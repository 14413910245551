import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { baseUrl } from '../../../BaseURL/BaseURL'


export default function ForgotPassword({ MobileNumber }) {




    const navigate = useNavigate()

    const [OldPassword, setOldPassword] = React.useState('')
    const [CurrentPassword, setCurrentPassword] = React.useState('')
    const [NewPassword, setNewPassword] = React.useState('')

    const ChangePassword = () => {
        if (CurrentPassword !== OldPassword) {
            toast.error("Password Does not Match")
            setCurrentPassword('')
            setNewPassword('')
        }
        else {
            var formdata = new FormData();
            formdata.append("EmployeeMobile", MobileNumber ? MobileNumber : '');
            formdata.append("CurrentPassword", OldPassword ? OldPassword : '');
            formdata.append("Confirm_Password", CurrentPassword ? CurrentPassword : '');
            // var myHeaders = new Headers();
            // myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
                // headers: myHeaders,
            };
            fetch(`${baseUrl}/LFMS/Master/PostM_TableUserMaster_Password_ForgetReset`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code >= 200 && result.code <= 300 && result.data) {
                        setTimeout(() => {
                            sessionStorage.clear();
                            localStorage.clear();
                            navigate('/')
                        }, 1500);
                        toast.success(result.message)
                    }
                    else {
                        toast.error(result.message)
                    }
                })
                .catch(error => {
                    toast.error("Something went wrong!")
                    console.log('error', error)
                });
        }
    }

    const Clear = () => {
        setNewPassword('')
        setOldPassword('')
        setCurrentPassword('')
        navigate('/')
    }


    const handlekeyChange = (e) => {
        if (e.key === "Enter") {
            ChangePassword()
        }
    }



    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye pr-3") {
            y.className = "fa fa-eye-slash pr-3";
        } else {
            y.className = "fa fa-eye pr-3";
        }
    }

    return (
        <>
            <div className="container mt-5">
                <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 text-center">
                    <form className="rounded bg-white shadow p-5 ">
                        {/* <h3 className="text-dark fw-bolder fs-4 mb-2">Forgot Password</h3> */}
                        <h3 className="text-dark fw-bolder fs-4 mb-2">पासवर्ड बदला</h3>

                        {/* <div className="text-dark fw-bolder fs-4 mb-2">
                            पासवर्ड बदला
                        </div> */}

                        <div className="col-8 mt-4 wrap-input100 validate-input" data-validate="Please Enter Username"
                            style={{ textAlign: 'center', margin: 'auto' }}
                        >
                            <span className="btn-show-pass">
                                <span id='eye' className="fa fa-eye-slash pr-3"
                                    onClick={() => showPass()}

                                ></span>
                            </span>
                            <input
                                onKeyDown={handlekeyChange}
                                className="input100 sendotpborder"
                                id='pass'
                                type="password"
                                name="password"
                                placeholder="New Password"
                                autocomplete="new-password"
                                onChange={(e) => {
                                    setOldPassword(e.target.value.trim())
                                    document.getElementById('pass').classList.remove('inputValidation')
                                }}
                            />
                            <span className="focus-input100"></span>

                            <span className="symbol-input100 ">
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>

                        </div>
                        <div className="col-8 mt-4 wrap-input100 validate-input" data-validate="Please Enter Username"
                            style={{ textAlign: 'center', margin: 'auto' }}
                        >

                            <input
                                type="password"
                                className="input100 sendotpborder"
                                id="Nameofagency "
                                placeholder="Confirm Password"
                                value={CurrentPassword}
                                // autoComplete="new-password"
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>
                        </div>
                        {/* {mobileError !== '' &&
                            <Fade bottom collapse>
                                <label style={{ color: 'red', marginTop: '2%' }}>{mobileError}</label>
                            </Fade>
                        } */}


                        <div className="col-8  wrap-input100 validate-input"
                            style={{ textAlign: 'center', margin: 'auto' }} >
                            <button
                                onClick={ChangePassword}
                                disabled={OldPassword == '' || CurrentPassword == ''}
                                type='button'
                                style={(OldPassword === '' || CurrentPassword == '') ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                                className="login100-form-btn mt-4 col-12 "
                            // onClick={handlepostmobileno}
                            >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between" >
                            <div className="mt-2">
                                <h4 className="font-weight-bold d-flex">पासवर्ड बदला</h4>
                            </div>



                            <button type="button" className="close-btn mr-2" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                onClick={() => navigate('/')}
                            >
                                <h1 aria-hidden="true">&times;</h1>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div>
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 col-xl-12">

                                                <div className="card main-card">
                                                    <div className="card-body row">
                                                        <div className="col-12 col-lg-3"></div>

                                                        <div className="col-12 col-lg-6">

                                                            <div className="form-row p-3">


                                                                <div className="col-lg-5 mt-2">
                                                                    <h4 id='floatright'>सद्य पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
                                                                </div>


                                                                <div className="col-lg-7 form-group">
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="NameofSupplier "
                                                                        placeholder="सद्य पासवर्ड"
                                                                        value={OldPassword}
                                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                            

                                                                <div className='col-lg-12 mb-3'></div>

                                                                <div className="col-lg-5 mt-2">
                                                                    <h4 id='floatright'>नवीन पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
                                                                </div>
                                                                <div className="col-lg-7 form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Nameofagency "
                                                                        placeholder="नवीन पासवर्ड"
                                                                        value={CurrentPassword}
                                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                               

                                                                <div className='col-lg-12 mb-3'></div>

                                                                <div className="col-lg-5 mt-2">
                                                                    <h4 id='floatright'>पासवर्ड पुन्हा प्रविष्ट करा <span style={{ color: 'red' }}> *</span>:</h4>
                                                                </div>

                                                                <div className="col-lg-7 form-group">
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="gstno "
                                                                        placeholder="पासवर्ड पुन्हा प्रविष्ट करा"
                                                                        value={NewPassword}
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                                
                                                            </div>

                                                            <div className='col-lg-12 mb-3'></div>

                                                            <div className="form-row col-12 col-md-12 col-lg-12" style={{ justifyContent: 'space-evenly' }}>


                                                                <div className="">
                                                                    <button
                                                                        disabled={OldPassword === '' || NewPassword === '' || CurrentPassword === ''}
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={() => ChangePassword()}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>

                                                                <div className="">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger ml-2"
                                                                        onClick={() => Clear()}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            


                                                        </div>
                                                        <div className="col-12 col-lg-3"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>


        // <div className="wrapper">
        //     <div className="main">
        //         {/* <Heade /> */}
        //         <main className="content">
        //             <div className="container-fluid p-0">

        //                 <div className="container-fluid page-body-wrapper">

        //                     <div className="main-panel ml-5">
        //                         <div className="content-wrapper">

        //                             <div className="row">
        //                                 <div className="col-md-12 grid-margin">
        //                                     <div className="row page-heading">
        //                                         <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
        //                                             <h4 className="font-weight-bold d-flex">पासवर्ड बदला</h4>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                             <div className="card main-card">
        //                                 <div className="card-body row">
        //                                     <div className="col-12 col-lg-3"></div>

        //                                     <div className="col-12 col-lg-6">

        //                                         <div className="form-row p-3">


        //                                             <div className="col-lg-4 mt-2">
        //                                                 <h4 id='floatright'>सद्य पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
        //                                             </div>


        //                                             <div className="col-lg-8 form-group">
        //                                                 <input
        //                                                     type="password"
        //                                                     className="form-control"
        //                                                     id="NameofSupplier "
        //                                                     placeholder="सद्य पासवर्ड"
        //                                                     value={OldPassword}
        //                                                     onChange={(e) => setOldPassword(e.target.value)}
        //                                                 />
        //                                             </div>
        //                                             {/* </div> */}

        //                                             <div className='col-lg-12 mb-3'></div>

        //                                             <div className="col-lg-4 mt-2">
        //                                                 <h4 id='floatright'>नवीन पासवर्ड <span style={{ color: 'red' }}> *</span>:</h4>
        //                                             </div>
        //                                             <div className="col-lg-8 form-group">
        //                                                 <input
        //                                                     type="password"
        //                                                     className="form-control"
        //                                                     id="Nameofagency "
        //                                                     placeholder="नवीन पासवर्ड"
        //                                                     value={CurrentPassword}
        //                                                     onChange={(e) => setCurrentPassword(e.target.value)}
        //                                                 />
        //                                             </div>
        //                                             {/* </div> */}

        //                                             <div className='col-lg-12 mb-3'></div>

        //                                             <div className="col-lg-4 mt-2">
        //                                                 <h4 id='floatright'>पासवर्ड पुन्हा प्रविष्ट करा <span style={{ color: 'red' }}> *</span>:</h4>
        //                                             </div>

        //                                             <div className="col-lg-8 form-group">
        //                                                 <input
        //                                                     type="text"
        //                                                     className="form-control"
        //                                                     id="gstno "
        //                                                     placeholder="पासवर्ड पुन्हा प्रविष्ट करा"
        //                                                     value={NewPassword}
        //                                                     onChange={(e) => setNewPassword(e.target.value)}
        //                                                 />
        //                                             </div>
        //                                             {/* </div> */}
        //                                         </div>

        //                                         <div className='col-lg-12 mb-3'></div>

        //                                         <div className="form-row" >
        //                                             <div className="col-12 col-md-6 col-lg-6" style={{ marginLeft: '35%' }}>
        //                                                 <button
        //                                                     disabled={OldPassword === '' || NewPassword === '' || CurrentPassword === ''}
        //                                                     type="button"
        //                                                     className="btn btn-primary"
        //                                                     onClick={() => ChangePassword()}
        //                                                 >
        //                                                     Submit
        //                                                 </button>

        //                                                 <button
        //                                                     type="button"
        //                                                     className="btn btn-danger ml-2"
        //                                                     onClick={() => Clear()}
        //                                                 >
        //                                                     Cancel
        //                                                 </button>
        //                                             </div>
        //                                         </div>

        //                                         <div className="form-row mt-3">
        //                                             <div className="col-12 col-md-12 col-lg-12" style={{ marginLeft: '35%' }}>
        //                                                 <span className="txt2" onClick={() => navigate('/')} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
        //                                                     Go Back<i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
        //                                                 </span>
        //                                             </div>
        //                                         </div>


        //                                     </div>
        //                                     <div className="col-12 col-lg-3"></div>
        //                                 </div>
        //                             </div>

        //                         </div>

        //                     </div>
        //                 </div>
        //             </div>
        //         </main>
        //         {/* <Footer /> */}
        //     </div>
        // </div>
    )
}
