
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaCodeBranch, FaAddressCard, FaEnvelopeOpenText, FaInfoCircle, FaUserPlus, FaExchangeAlt, FaUserTag } from "react-icons/fa";
import $ from 'jquery'
import { BiLogIn } from "react-icons/bi";
import { LogOut } from 'react-feather';


export default function AdminNavbar({ active }) {
    const navigation = useNavigate()
    const [isclickedclass, setisclickedclass] = React.useState(active)

    var width = $("body").width()

    const Logout = () => {
        sessionStorage.clear()
        navigation('/')
    }

    return (
        <div>

            <div className={width > 873 ? "sidebar collapse show" : "sidebar collapse "} id="sidebartoggler">

                <div className="sidebar-content js-simplebar">
                    <a className="sidebar-brand" to="index.html">
                        &nbsp;
                    </a>

                    <ul className="sidebar-nav" id="navv">


                        {/* <li
                            // className="sidebar-item active"
                            className={isclickedclass === "Dashboard" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/admin">
                                <i className="align-middle" data-feather="home" />
                                <i dangerouslySetInnerHTML={{ __html: window.feather.icons.home.toSvg() }} />
                                <span id="span" className="align-middle">डॅशबोर्ड</span>
                            </Link>
                        </li> */}

                        {/* <li
                            className={isclickedclass === "viewcreatekaryalay" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/viewcreatekaryalay">
                                <i className="align-middle" data-feather="file">
                                <i className="align-middle" dangerouslySetInnerHTML={{ __html: window.feather.icons.file.toSvg() }} ></i>
                                <span id="span" className="align-middle">कार्यालय तयार करणे </span>
                            </Link>
                        </li>

                        <li
                            className={isclickedclass === "viewcreatekaryalayprakar" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/viewcreatekaryalayprakar">
                                <i className="align-middle" data-feather="file">
                                <i className="align-middle" dangerouslySetInnerHTML={{ __html: window.feather.icons.file.toSvg() }} ></i>
                                <span id="span" className="align-middle">कार्यालय प्रकार तयार करणे </span>
                            </Link>
                        </li> */}

                        {
                            sessionStorage.getItem("RoleID") === '14' ? ''
                                :
                                <>
                                    <li
                                        className={isclickedclass === "createvibhag" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/createvibhag">
                                            {/* <i className="align-middle" data-feather="book"></i>  */}
                                            {/* <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} /> */}
                                            <FaCodeBranch />
                                            <span id="span" className="align-middle">विभाग तयार करणे</span>
                                        </Link>
                                    </li>
                                    <li
                                        className={isclickedclass === "createbranch" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/createbranch">
                                            {/* <i className="align-middle" data-feather="book"></i>  */}
                                            {/* <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} /> */}
                                            <FaCodeBranch />
                                            <span id="span" className="align-middle">शाखा तयार करणे</span>
                                        </Link>
                                    </li>
                                    <li
                                        className={isclickedclass === "padnaam" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/padnaam">
                                            {/* <i className="align-middle" data-feather="book"></i> */}
                                            {/* <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} /> */}
                                            <FaAddressCard />
                                            <span id="span" className="align-middle">पदनाम</span>
                                        </Link>
                                    </li>
                                </>
                        }



                        {/* <li
                            className={isclickedclass === "createform" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/viewform">
                                <i className="align-middle" data-feather="book"></i> 
                                <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} />
                                <span id="span" className="align-middle">फॉर्म यादी</span>
                            </Link>
                        </li> */}



                        {/* <li
                            className={isclickedclass === "letterinfo" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/viewpatrasandharbh">
                                <FaEnvelopeOpenText/>
                                <span id="span" className="align-middle">पत्र संदर्भ</span>
                            </Link>
                        </li> */}

                        {/* <li
                            className={isclickedclass === "patrapradhanyakram" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/patrapradhanyakram">
                                <i className="align-middle" data-feather="book"></i> 
                                <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} />
                                <span id="span" className="align-middle">पत्र प्राधान्यक्रम</span>
                            </Link>
                        </li> */}

                        <li
                            className={isclickedclass === "info" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/info">
                                {/* <i className="align-middle" data-feather="book"></i>  */}
                                {/* <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} /> */}
                                <FaInfoCircle />
                                <span id="span" className="align-middle">अधिकारी / कर्मचारी माहिती</span>
                            </Link>
                        </li>

                        <li
                            className={isclickedclass === "createuser" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/createuser">
                                <FaUserPlus />
                                <span id="span" className="align-middle">User तयार करणे</span>
                            </Link>
                        </li>
                        <li
                            className={isclickedclass === "approvaluser" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/approvaluser">
                                <FaUserPlus />
                                <span id="span" className="align-middle">User Approval करणे</span>
                            </Link>
                        </li>
                        <li
                            className={isclickedclass === "MultipleBranch" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/MultipleBranch">
                                <FaExchangeAlt />
                                <span id="span" className="align-middle">Multiple Branch</span>
                            </Link>
                        </li>



                        {/* <li
                            className={isclickedclass === "usertransfer" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/usertransfer">
                                <FaExchangeAlt/>
                                <span id="span" className="align-middle">User ट्रान्सफर</span>
                            </Link>
                        </li>

                        <li
                            className={isclickedclass === "rolenihayform" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/rolenihayform">
                                <FaUserTag/>
                                <span id="span" className="align-middle">रोल निहाय फॉर्म</span>
                            </Link>
                        </li> */}

                        <li className="sidebar-item" id="sidebarlogoutbutton">
                            <a className="sidebar-link" onClick={() => Logout()}>
                                <LogOut size={18} />
                                लॉग आउट

                            </a>
                        </li>

                        {/* <li 
                        className={isclickedclass === "InboxOutwardLetter" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <a className="sidebar-link" to="/inboxOutwardLetter">
                                <i dangerouslySetInnerHTML={{ __html: window.feather.icons.book.toSvg() }} />
                                <span id="span" className="align-middle">इनबॉक्स जावक पत्र नोंद</span>
                            </a>
                        </li> */}

                    </ul>
                </div>
            </div>


        </div>
    )
}
