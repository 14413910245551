import React from "react";
import { ArrowRight, Bell, Calendar, Clock, Lock, LogOut, Mail, User } from "react-feather";
import $ from 'jquery'
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../BaseURL/BaseURL";
import SearchPage from "../SearchPage/SearchPage";
import FileSearchPage from "../SearchPage/FileSearchPage";
import { BiLeftArrow } from "react-icons/bi";
import { ExcelGetNotificationList, GetNotificationList, ReadPostNotification } from "../../helper/NotificationAPI/NotificationAPI";
import moment from "moment";
import NotiExcel from "../../helper/firebaseNotifications/NotiExcel";
import { requestForToken, onMessageListener } from '../../helper/firebaseNotifications/firebase';
import { Loader } from "react-overlay-loader";
import { NotitoastInfo } from "../../helper/Toasthelper";
import { LetterNotification } from "./LetterNotification";
import { FileNotification } from "./FileNotification";
import { LetterFileCloseNotification } from "./LetterFileCloseNotification";
import { NotificationForCloseData } from "../../Pages/Private/DeptUsers/DeptAPI/DeptFileAPI";


export default function Header({ flag, CallCloseNofication }) {
    const navigation = useNavigate()
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });
    const UserID = sessionStorage.getItem("User")
    const RoleID = sessionStorage.getItem("RoleID")
    const dashboardID = sessionStorage.getItem('dashboardID')
    const [NotificationList, setNotificationList] = React.useState([])
    const [CloseData, setCloseData] = React.useState([])
    const [Top, setTop] = React.useState(10)
    const [Top1, setTop1] = React.useState(10)
    const [loader, setloader] = React.useState(false)
    const [Excelloader, setExcelloader] = React.useState(false)
    const [SeeAll, setSeeAll] = React.useState(false)
    const [SeeAll1, setSeeAll1] = React.useState(false)
    const [ExcelNotificationList, setExcelNotificationList] = React.useState([])

    const [notification, setNotification] = React.useState({ title: '', body: '' });
    const [NotificationID, setNotificationID] = React.useState('')

    const readCookie = async (name) => {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        // console.log(result.RoleID)
        // LoginDataa(result)
    }


    React.useEffect(() => {
        readCookie('nmmclfms')
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        if (window.innerWidth <= 873) {
            $('#sidebartoggler').removeClass('show');
            $("#sidebartoggler").css({ "position": "fixed" })

        }
        if (window.innerWidth > 873) {
            $('#sidebartoggler').addClass('show');
            $("#sidebartoggler").css({ "position": "relative" })

        }
        if (window.innerWidth <= 580) {
            $("#sidebarlogoutbutton").css({ "display": "block" })
        }
        if (window.innerWidth > 580) {
            $("#sidebarlogoutbutton").css({ "display": "none" })
        }
        return () => window.removeEventListener("resize", handleResize);
    }, [window.innerWidth]);


    const Logout = () => {
        sessionStorage.clear()
        navigation('/')
    }

    const PostReadNotification = (M_NotificationDataID) => {
        ReadPostNotification({ UserID: UserID, M_NotificationDataID: M_NotificationDataID, handleGetNotiAPI: handleGetNotiAPI })
    }

    React.useEffect(() => {
        GetNotificationList({ UserID: UserID, setNotificationList: setNotificationList, Top: Top, setloader, dashboardID })
    }, [Top, dashboardID])

    React.useEffect(() => {
        ExcelGetNotificationList({ UserID: UserID, setNotificationList: setExcelNotificationList, Top: '999999', setloader: setExcelloader, dashboardID })
    }, [dashboardID])


    React.useEffect(() => {
        if (CallCloseNofication == 'CallCloseNofication') {
            NotificationForCloseData({ UserID: UserID, Flag: dashboardID, setCloseData: setCloseData })
        }
    }, [dashboardID])

    const handleGetNotiAPI = () => {
        GetNotificationList({ UserID: UserID, setNotificationList: setNotificationList, Top: Top, setloader, dashboardID })
        ExcelGetNotificationList({ UserID: UserID, setNotificationList: setExcelNotificationList, Top: '999999', setloader: setExcelloader, dashboardID })
    }

    React.useEffect(() => {
        if (notification?.title) {
            // notify()
            NotitoastInfo(<div><b>{notification?.title}</b><br /> {notification?.body}</div>)
        }
    }, [notification])

    // if (window.location.protocol === "https:") {
    // onMessageListener()
    //     .then((payload) => {
    //         GetNotificationList({ UserID: UserID, setNotificationList: setNotificationList, Top: Top, setloader, dashboardID })
    //         setNotificationID(payload?.messageId)
    //         setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    //     })
    //     .catch((err) => console.log('failed: ', err));
    // }

    const handleSeeMore = () => {
        if (NotificationList) {
            if (Top <= NotificationList.length) {
                setSeeAll(false)
                setTop(Top + 10)
            } else {
                setSeeAll(true)
            }
        }
    }

    const handleSeeMore1 = () => {
        if (CloseData) {
            if (Top1 <= CloseData.length) {
                setSeeAll1(false)
                setTop1(Top1 + 10)
            } else {
                setSeeAll1(true)
            }
        }
    }

    return (
        <>

            <nav className="navbar navbar-light bg-light transparent-bg" id='shadowDIV'
            // style={{ display:'flex' , justifyContent:'space-evenly' }}
            >
                <button
                    className={flag && flag === 'invisible' ? "navbar-toggler invisible" : "navbar-toggler"}
                    type="button"
                    onClick={() => {
                        let classlist = document.getElementById("sidebartoggler").classList
                        classlist.toggle("show")
                    }}
                    style={{ borderColor: "#232F3E", borderWidth: "1px" }}
                >
                    <span className="navbar-toggler-icon" ></span>
                </button>

                {
                    window.innerWidth > 580 ?
                        <span className="navbar-brand mr-auto" >

                            <img
                                src="assets/img/lfms_logo.png"
                                alt="LFMS"
                                // style={{ width: '100px', marginLeft: "10px" }}
                                className={flag && flag === 'invisible' ? "lfmslogoRoleID4" : "lfmslogo"}
                            // style={{ marginLeft:"-25px"}}
                            />

                        </span> :
                        <></>
                }



                <div className="col-7" id="appname" >
                    <li className="nav-item" style={{ textAlign: 'center', paddingLeft: '18%' }}>
                        <h2 style={{ fontSize: 25, color: '#28245F' }}>Letter & File Management System <span style={{ fontSize: '14px' }}>(</span>LFMS<span style={{ fontSize: '14px' }}>)
                            {/* </span><p style={{color:'#12A0A1'}}>Navi Mumbai Municipal Corporation</p><p style={{color:'#12A0A1'}}>NMMC</p></h2> */}
                        </span><p style={{ color: '#AE2432', fontSize: '17px' }}>{sessionStorage.getItem("applicationName")}</p></h2>

                        <span>
                            {/* <p style={{ color: '#28245F', fontSize: 17, fontWeight: '600' }}>{sessionStorage.getItem("UserFullName")}</p> */}
                            <p style={{ color: '#28245F', fontSize: 17, fontWeight: '600' }}>{sessionStorage.getItem("TableUserMaster")}</p>
                        </span>
                    </li>
                </div>



                <span style={{
                    marginLeft: sessionStorage.getItem("RoleID") === '2' || sessionStorage.getItem("RoleID") === '14' || sessionStorage.getItem("RoleID") === '1'
                        ? '11%' : ""
                }}
                    className="navbar-brand mr-auto" >
                    {/* {
                        baseUrl === "http://apipmrda.elfms.in" &&
                            <>
                                <img
                                    src="assets/img/pmrda.png"
                                    alt="PMRDA"
                                    className="nmmclogo"
                                />
                                <p style={{ color: '#AE2432', fontSize: '15px', fontWeight: 'bold', marginLeft: '12px' }}>PMRDA</p>

                            </>
                    } */}

                    {/* <img
                        src="assets/img/nmmc_large_logo1.png"
                        alt="LFMS"
                        style={{ width:'80px', marginRight: "-50px" }}
                    /> */}

                </span>
                {
                    sessionStorage.getItem("RoleID") === '2' || sessionStorage.getItem("RoleID") === '14' || sessionStorage.getItem("RoleID") === '1' || sessionStorage.getItem("RoleID") === '5' ?
                        ''
                        :
                        dashboardID === '1' ?
                            <div className="col-1 mr-auto">
                                <SearchPage />
                            </div>
                            :
                            <div className="col-1 mr-auto" >
                                <FileSearchPage />
                            </div>

                }

                {/*<LetterFileCloseNotification
                    NotificationList={CloseData}
                    loader={loader}
                    Excelloader={Excelloader}
                    ExcelNotificationList={ExcelNotificationList}
                    SeeAll={SeeAll1}
                    PostReadNotification={PostReadNotification}
                    handleSeeMore={handleSeeMore1}
                    dashboardID={dashboardID}
            />*/}

                {/* <div style={window.innerWidth > 580 ? { marginLeft: '0px' } : { marginLeft: '40px' }}>

                    {
                        dashboardID === '1' ?
                            <LetterNotification
                                NotificationList={NotificationList}
                                loader={loader}
                                Excelloader={Excelloader}
                                ExcelNotificationList={ExcelNotificationList}
                                SeeAll={SeeAll}
                                PostReadNotification={PostReadNotification}
                                handleSeeMore={handleSeeMore}
                            />
                            :
                            <FileNotification
                                NotificationList={NotificationList}
                                loader={loader}
                                Excelloader={Excelloader}
                                ExcelNotificationList={ExcelNotificationList}
                                SeeAll={SeeAll}
                                PostReadNotification={PostReadNotification}
                                handleSeeMore={handleSeeMore}
                            />
                    }

                </div> */}

                <div className="mr-auto">
                    <li className="nav-item dropdown " >
                        <li className="dropdown user-menu" title={sessionStorage.getItem("PlfmsTableUserMaster")}>
                            <button className="dropdown-toggle nav-link " data-toggle="dropdown">
                                <img src="assets/img/lfms_user_icon.png" className="user-image" alt="User Image" style={{ width: '35px' }} />
                                {/* <span className="text-dark">{sessionStorage.getItem("UserFullName")}</span> */}
                            </button>

                            <ul className="dropdown-menu dropdown-menu-right">

                                <li className="right-sidebar-in" id="appname"  >
                                    <a className="dropdown-item">
                                        <User size={20} />
                                        <text>&nbsp;&nbsp;&nbsp;&nbsp;</text>
                                        {sessionStorage.getItem("TableUserMaster")}
                                    </a>
                                </li>

                                <li className="right-sidebar-in"
                                    onClick={() => navigation('/forgot-password')} >
                                    <a className="dropdown-item" >
                                        <Lock size={18} />
                                        <text>&nbsp;&nbsp;&nbsp;&nbsp;</text>
                                        पासवर्ड बदला
                                    </a>
                                </li>

                                <li className="right-sidebar-in"
                                    onClick={() => navigation('/updatedprofile')} >
                                    <a className="dropdown-item" >
                                        <Lock size={18} />
                                        <text>&nbsp;&nbsp;&nbsp;&nbsp;</text>
                                        Profile Updated
                                    </a>
                                </li>


                                <li className="right-sidebar-in" onClick={() => Logout()}>
                                    <a className="dropdown-item" >
                                        <LogOut size={18} />
                                        <text>&nbsp;&nbsp;&nbsp;&nbsp;</text>
                                        लॉग आउट
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </li>
                </div>
            </nav>

        </>
    );
}
