
import React from 'react'
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import SP_Pie_Chart from '../../Commisioner/SPDashboard/Dashboard/Pie_Chart/SP_Pie_Chart';


export default function BranchTableChart({
    ReferenceTypeTableData,
    flag,IsBranchSelect }) {

    // console.log(ReferenceTypeTableData)

    var PendingCount1 = []
    var PendingLabel1 = []
    var PendingColor1 = []

    const [PendingCount, setPendingCount] = React.useState()
    const [PendingLabel, setPendingLabel] = React.useState()
    const [PendingColor, setPendingColor] = React.useState()
    const [DaysFilter, setDaysFilter] = React.useState('All-Day')
    const [PieLoader, setPieLoader] = React.useState(false)
    const [SPPieFlag, setSPPieFlag] = React.useState('All-Day')


    React.useEffect(() => {
        if (ReferenceTypeTableData && ReferenceTypeTableData.data) {
            PieData()
        }else{
            setPendingCount([])
            setPendingLabel([])
            setPendingColor([])
        }
    }, [ReferenceTypeTableData, SPPieFlag , IsBranchSelect])

    const PieData = () => {
        setPieLoader(true)
        var dynamicColors = function () {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            console.log("rgb(" + r + "," + g + "," + b + ")")
            PendingColor1.push("rgb(" + r + "," + g + "," + b + ")")
            return "rgb(" + r + "," + g + "," + b + ")";
        };
        setPendingColor(PendingColor1)

        for (var i = 0; i < ReferenceTypeTableData.data.length; i++) {
            dynamicColors()
        }


        for (var i = 0; i < ReferenceTypeTableData.data.length; i++) {
            PendingLabel1.push(ReferenceTypeTableData.data[i].entityName)
            // SDPOpendigCount1.push(ReferenceTypeTableData[i].totalCount)
            if (SPPieFlag === 'All-Day') {
                PendingCount1.push(ReferenceTypeTableData.data[i].totalPendingCount)
            }
            else if (SPPieFlag === '0-7') {
                PendingCount1.push(ReferenceTypeTableData.data[i].daysPending_0_7_Count)
            }
            else if (SPPieFlag === '7-21') {
                PendingCount1.push(ReferenceTypeTableData.data[i].daysPending_7_21_Count)
            }
            else if (SPPieFlag === '21-3') {
                PendingCount1.push(ReferenceTypeTableData.data[i].daysPending_21_3_Month_Count)
            }
            else if (SPPieFlag === '3-12') {
                PendingCount1.push(ReferenceTypeTableData.data[i].daysPending_3_12_Month_Count)
            }
            else if (SPPieFlag === '1Year') {
                PendingCount1.push(ReferenceTypeTableData.data[i].daysPending_1_YearAbove_Count)
            }
            else {
                PendingCount1.push(ReferenceTypeTableData.data[i].totalPendingCount)
            }
        }
        setPendingCount(PendingCount1)
        setPendingLabel(PendingLabel1)
        setTimeout(() => {
            setPieLoader(false)
        }, 100);
        // setPieLoader(false)


    }


    function zeroTest(element) {
        return element === 0;
    }

    var allZeros

    if (PendingCount) {
        allZeros = PendingCount.every(zeroTest);
    }

    if (PendingCount) {
        allZeros = PendingCount.every(zeroTest);
    }

    const chart2 = () => {
        return {
            labels: PendingLabel,
            datasets: [
                {
                    label: "Team points 2",
                    data: PendingCount,
                    backgroundColor: PendingColor
                }
            ]
        };
    };


    // React.useEffect(() => {
    //     setSPPieFlag('All-Day')
    // }, [])

    // React.useEffect(() => {
    //     OnChangePie(SPPieFlag)
    // }, [SPPieFlag])

    const labels = ['अतिक्रमण विभाग', 'आरोग्य विभाग', 'उद्यान विभाग', 'नगररचना विभाग', 'निवडणूक विभाग', 'प्रशासन विभाग', 'लेखा विभाग']
    const pendigCount = ['150', '274', '212', '239', '249', '150', '189']
    const coloR = ["#fe974a", "#0063ff", "#3dd598", "#ffc442", "#460F8A", "#8D9268", "#A35BE1"]


    return (
        <div className="col-12 col-md-6 col-xxl-6 d-flex order-2 pl-1">
            <div className="spcard flex-fill w-100">

                <div className="spcard-header">
                    {/* <h5 className="spcard-title mb-0">{headingsData && headingsData.sec2Heading ? headingsData.sec2Heading : ""} कार्यालया नुसार प्रलंबित पत्राचा गोषवारा</h5> */}
                    <div className='row'>
                        <div className="col-12 col-lg-2 col-xl-2 col-md-11 col-sm-11 mt-2">
                            <h5 className="spcard-title mb-0">
                                {
                                    flag && flag === 'file' ?
                                        'प्रलंबित नस्ती'
                                        :
                                        'प्रलंबित पत्र'
                                }
                            </h5>
                        </div>

                        <div className="btn-group col-12 col-lg-10 col-xl-10 col-md-12 col-sm-12">
                            <button type="button"
                                onClick={() => setSPPieFlag('All-Day')}
                                className={SPPieFlag === 'All-Day' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>
                                एकूण
                            </button>

                            <button type="button"
                                onClick={() => setSPPieFlag('0-7')}
                                className={SPPieFlag === '0-7' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                0 ते 7 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setSPPieFlag('7-21')}
                                className={SPPieFlag === '7-21' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                7 ते 21 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setSPPieFlag('21-3')}
                                className={SPPieFlag === '21-3' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                21 ते 90 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setSPPieFlag('3-12')}
                                className={SPPieFlag === '3-12' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                90 ते 365 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setSPPieFlag('1Year')}
                                className={SPPieFlag === '1Year' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                1 वर्षा पुढील
                            </button>
                        </div>

                    </div>
                </div>

                <div className="spcard-body d-flex">
                    <div className="align-self-center w-100" style={{ height: '50vh' }}>
                        <div className="py-3">
                            {
                                PieLoader ?
                                    <div className="Chart" style={{ maxHeight: '50vh' }}>
                                        <Loader loading />
                                    </div>
                                    :

                                    PendingCount && allZeros === false ?
                                        <div className="Chart" style={{ maxHeight: '50vh' }}>

                                            <SP_Pie_Chart
                                                chart2={chart2()}
                                            />
                                        </div>
                                        :
                                        <div className='loadingcenter' style={{ height: '50vh', overflow: 'hidden' }}>
                                            <img
                                                src="assets/img/folder.png"
                                                style={{ height: "40px", marginLeft: "10px" }}
                                            /><label className='tablenorecordtable'>&nbsp;&nbsp;No Sufficient data...</label>
                                        </div>

                                // <div className="chart chart-xs" style={{ maxWidth: "250px", margin: "0 auto", }}>
                                //     <Max_Pendancy_PieChart
                                //         labels={labels}
                                //         data={pendigCount}
                                //         bgColor={coloR}
                                //     />
                                // </div>

                            }


                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}