
import React, { useEffect } from 'react'
import { FaFilePdf } from 'react-icons/fa';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { Link } from 'react-router-dom';
import CommFourthDrillPopUp from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/CommFourthDrillPopUp';
// import ExcelExportPendancyTableCOMM from '../../Commisioner/SPDashboard/Dashboard/Pendency/ExcelExportPendancyTableCOMM';
// import CommFourthDrillPopUp from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/CommFourthDrillPopUp';


export default function BranchWorkAccuracyTable(
    {
        Workshowhide,
        setWorkshowhide,
        ddlloader,
        ReferenceTypeDDL,
        onChnageDDL,
        SPData,
        flag,
        ReferenceTypeTableData,
        tableloader,
        IsBranchSelect,
        WorkTableData,
        WorkLoader
    }) {

    const [DDLDataID, setDDLDataID] = React.useState('0')

    const onChnageDays = (e) => {
        onChnageDDL(e)
    }

    React.useEffect(() => {
        sessionStorage.setItem('refid', DDLDataID)
        onChnageDDL(DDLDataID)
    }, [DDLDataID, IsBranchSelect])

    const [PendancyData, setPendancyData] = React.useState([])
    const [PendancyShow, setPendancyShow] = React.useState(false)

    const hideshowPendancyTablePopUp = (data, show) => {
        setPendancyData(data)
        setPendancyShow(show)
    }

    const [FourthData, setFourthData] = React.useState([])
    const [FourthShow, setFourthShow] = React.useState(false)

    const hideshowFourthPopUp = (data, show) => {
        setFourthData(data)
        setFourthShow(show)
    }

    return (

        <div className={
            Workshowhide ? "col-12 col-md-6 col-xxl-6 d-flex order-2 order-xxl-3 pr-3"
                :
                "col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-6 pr-3"}
        >
            <div className="spcard flex-fill w-100">
                <div className=" spcard-header mr-2 ml-2 mb-n4">
                    <div className='row'>

                        <div className="col-lg-5 col-xxl-8 col-md-11 col-sm-11 pb-3">
                            <h5 className="spcard-title mb-0 mt-2 ml-n2">
                                {
                                    flag && flag === 'KaryasanAvg' ?
                                        'कार्यासनाच्या कामाची सरासरी'
                                        :
                                        'कार्यासनानुसार कामाची सरासरी'
                                }
                            </h5>
                        </div>

                        <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 mt-2 mb-3 gx-1">
                            {/* <ExcelExportPendancyTableCOMM ReferenceTypeTableData={ReferenceTypeTableData} name='कार्यासना नुसार प्रलंबित नस्तीचा गोषवारा' flag='कार्यासनाचे नाव' /> */}

                            {/* {
                                ReferenceTypeTableData && ReferenceTypeTableData.url ?
                                    <button type="submit" className="btn btn-outline-danger btn-md mr-2"
                                        onClick={() => window.open(ReferenceTypeTableData && ReferenceTypeTableData.url)}
                                    >
                                        <FaFilePdf />
                                        <span className='mx-2'>PDF</span>
                                    </button>
                                    : ''
                            } */}
                        </div>

                        {/* <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 mt-2 mb-3 gx-2">
                            <button type='button' className='btn btn-primary'
                                disabled={ddlloader}
                                onClick={() => setWorkshowhide(!Workshowhide)}
                                style={{ fontSize: '12px', paddingTop: '2px' }}
                            >{!Workshowhide ? 'Show Graph' : 'Hide Graph'}</button>
                        </div> */}

                        {/* <div className="filter col-lg-3 col-xxl-4 col-md-11 col-sm-11 mt-n1 gx-1">
                            <select
                                className="form-control"
                                name="letterSorting"
                                id="letterSorting"
                                value={DDLDataID}
                                onChange={(e) => {
                                    setDDLDataID(e.target.value)
                                    sessionStorage.setItem('refid', e.target.value)
                                    setDDLDataIDHeading(ReferenceTypeDDL[e.target.selectedIndex - 1].referenceType)
                                }}

                            >
                                <option value='0' Selected>एकूण</option>
                                {
                                    ReferenceTypeDDL !== null ? ReferenceTypeDDL.map(item =>
                                        <option name={item.referenceType} value={item.id}>{item.referenceType}</option>

                                    ) : <option disabled>माहिती उपलब्ध नाही</option>

                                }

                            </select>
                        </div> */}

                    </div>
                </div>

                <div className="align-self-center w-100 p-2">

                    <div className="tbl-sticky mt-n1" style={{ height: '50vh' }}>
                        {

                            WorkLoader ?

                                <div className='loadingcenter' style={{ height: '50vh' }}>
                                    <i
                                        className="fa fa-refresh fa-spin loadingIcon"
                                    />
                                    <label className='loadinglabel'>Loading ...</label>
                                </div>

                                :
                                WorkTableData && WorkTableData.data === null ?

                                    <div className='loadingcenter' style={{ height: '50vh', overflow: 'hidden' }}>
                                        <img
                                            src="assets/img/folder.png"
                                            style={{ height: "40px", marginLeft: "10px" }}
                                        /><label className='tablenorecordtable'>&nbsp;&nbsp;No Record Found ...</label>
                                    </div>

                                    :

                                    <table className="table table-bordered table-hover" style={{ borderCollapse: "collapse", }}>

                                        <thead>
                                            <tr>
                                                {/* <th>अ.क्रं.</th> */}
                                                <th >कार्यासनाचे नाव</th>
                                                <th >एकूण नोंद
                                                    {/* <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalInwardCount ? ReferenceTypeTableData.totalInwardCount : "0"} */}
                                                </th>

                                                <th >एकूण निर्गमीत
                                                    {/* <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalCloseCount ? ReferenceTypeTableData.totalCloseCount : "0"} */}

                                                </th>
                                                <th >एकूण दिवस
                                                    {/* <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalPendingCount ? ReferenceTypeTableData.totalPendingCount : "0"} */}

                                                </th>

                                                <th ><span>कालावधी संपलेल्या <br />एकूण प्रलंबित नस्ती</span>
                                                    {/* <hr style={{ marginTop: '1px', marginBottom: '1px' }} />
                                                    एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalPendingCount ? ReferenceTypeTableData.totalPendingCount : "0"} */}

                                                </th>

                                            </tr>
                                        </thead>


                                        <tbody>
                                            {
                                                WorkTableData && WorkTableData.data ? WorkTableData.data.map((i, index) => {
                                                    return (
                                                        <tr >
                                                            <td >
                                                                {i.entityName ? i.entityName : '-'}

                                                            </td>
                                                            {/* id='trNamewidth' */}

                                                            <td align='center' id='trwidth'>
                                                                {i.totalInwardCount ? i.totalInwardCount : '0'}
                                                            </td>



                                                            <td align='center' id='trwidth'
                                                            // style={{ color: 'blue', cursor: 'pointer' }}
                                                            // onClick={() => {
                                                            //     // console.log('show')
                                                            //     hideshowFourthPopUp({
                                                            //         name: i.entityName,
                                                            //         flag: 'show',
                                                            //         ShowBy: "All",
                                                            //         FilterID: '63',
                                                            //         M_ReferenceTypeID: '0',

                                                            //         fourthflag: 'totalCloseCount',
                                                            //         fourthShowby: 'All',
                                                            //         fourthfilterID: i.entityID,
                                                            //         fourthReferenceTypeID: DDLDataID,
                                                            //         screen:'WorkAvarge'
                                                            //     }, true);
                                                            //     sessionStorage.setItem('flagPending1', 'Pending')

                                                            // }}
                                                            >
                                                                {i.totalCloseCount ? i.totalCloseCount : '0'}
                                                            </td>

                                                            <td align='center'
                                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log('show')
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All_Accuracy",
                                                                        FilterID: i.entityID,
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'totalCloseCount',
                                                                        fourthShowby: 'All_Accuracy',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID,
                                                                        screen: 'WorkAvarge'
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}>

                                                                {i.totalPendingCount ? i.totalPendingCount : '0'}
                                                            </td>

                                                            <td align='center'
                                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // console.log('show')
                                                                    hideshowFourthPopUp({
                                                                        name: i.entityName,
                                                                        flag: 'show',
                                                                        ShowBy: "All_Accuracy",
                                                                        FilterID: i.entityID,
                                                                        M_ReferenceTypeID: '0',

                                                                        fourthflag: 'TotalDurationCount',
                                                                        fourthShowby: 'All_Accuracy',
                                                                        fourthfilterID: i.entityID,
                                                                        fourthReferenceTypeID: DDLDataID,
                                                                        screen: 'WorkAvarge'
                                                                    }, true);
                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                }}
                                                            >
                                                                {i.totalDurationCount ? i.totalDurationCount : '0'}
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                                    :
                                                    <tr><label>No Record Found ...</label></tr>
                                            }


                                        </tbody>


                                    </table>
                        }

                        {
                            FourthShow ?
                                <CommFourthDrillPopUp
                                    setFourthShow={setFourthShow}
                                    FourthData={FourthData}
                                    flag='show'
                                    screen="KaryasanAvg"
                                /> : <></>
                        }

                        {/* {
                            PendancyShow ?
                                <PendancyTablePopUp
                                    setPendancyShow={setPendancyShow}
                                    PendancyData={PendancyData}
                                    DDLDataID={DDLDataID}
                                    ReferenceTypeDDL={ReferenceTypeDDL}
                                    DDLDataIDHeading={DDLDataIDHeading}
                                    flag={flag}
                                /> : <></>
                        } */}
                    </div>

                </div>
            </div>
        </div>
    )
}