import React from 'react'
import Popup from 'reactjs-popup';
import { baseUrl } from '../../../../../BaseURL/BaseURL';
import InboxLetterModal from '../InboxLetterModal/InboxLetterModal';
import moment from 'moment';
// import InboxLetterModal from "../../Modals/InboxLetterModal/InboxLetterModal";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

export default function TrackNasti({ fileNumber, data, mainCreatedInward_MasterID, inwardNumber, createdByUserID, parentInwardID }) {
    // console.log(parentInwardID, createdByUserID, inwardNumber, mainCreatedInward_MasterID)
    // console.log(data)
    const [tableData, settableData] = React.useState([])
    const [loading, setloading] = React.useState(false)
    const [createdByUserName, setcreatedByUserName] = React.useState('')
    const UserID = sessionStorage.getItem("User")


    const GetTrackData = () => {
        setloading(true)
        var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };


        fetch(`${baseUrl}/FileInwardMaster/Get_File_Track_Select?MainCreatedFile_Inward_MasterID=${mainCreatedInward_MasterID ? mainCreatedInward_MasterID : '0'}&CreatedByUserID=${createdByUserID}&ParentInwardID=${parentInwardID ? parentInwardID : '0'}&UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status && result.data) {
                    setcreatedByUserName(result.data && result.data[0] ? result.data[0].createdByUserName : '')
                    settableData(result.data)
                } else {
                    settableData([])
                }
                setTimeout(() => {
                    setloading(false)
                }, 800);
            })
            .catch(error => {
                console.log('error', error)
                setloading(false)
            });
    }

    const ExportToExcel2 = () => {
        return (
            <div style={{ display: 'inline', marginLeft: '20px' }}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button2"
                    className="download-table-xls-button btn btn-success btn-md mt-1"
                    table="table-to-xls2"
                    filename={`नस्ती ट्रॅक`}
                    sheet={`नस्ती ट्रॅक`}
                    buttonText="Export"
                    style={{ borderColor: 'black' }}
                />


                <table id="table-to-xls2" style={{ display: 'none' }}>
                    <h4 style={{ textAlign: 'center' }}>नस्ती ट्रॅक</h4>
                    <h5><span>&emsp;</span></h5>

                    <h5 style={{ textAlign: 'center' }}>

                        <>
                            <span>{fileNumber}</span>
                        </>

                        <h5><span>&emsp;</span></h5>
                    </h5>
                    <thead className="thead-dark" >
                        <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                            <th id='srwidth1'>अ.क्र</th>
                            <th style={{ width: '5%' }}>नस्ती क्रमांक </th>
                            <th style={{ width: '5%' }}>नस्ती जावक क्रमांक </th>
                            <th>नस्ती दिनांक/मिळाल्याची दिनांक</th>
                            <th>कोणाकडून आले</th>
                            <th>कोणास पाठवले</th>
                            <th>नस्ती दिनांक व वेळ</th>
                            {/* <th>पत्र</th> */}
                            <th>शेरा</th>
                            <th>नस्ती स्थिती</th>

                        </tr>
                    </thead>
                    <tbody>
                        {tableData ? tableData.map((item, index) =>
                            <tr key={item.inward_TransferID} style={{ border: 'groove' }}>
                                <td align='center' id="srwidth">{index + 1}</td>
                                <td align='center' style={{ width: '8%' }} id="breakword">{item.fileNumber ? item.fileNumber : "-"}</td>
                                <td align='center' style={{ width: '8%' }} id="breakword">{item.fileOutwardNumber ? item.fileOutwardNumber : "-"}</td>
                                {/* <td align='center' style={{ whiteSpace: 'nowrap' }}>
                                    {item.letterDate ? moment(item.letterDate).format("DD-MM-YYYY") : ""}
                                    <hr style={{ padding: 0, margin: 0 }} />
                                    {item.letterReceivedDate ? moment(item.letterReceivedDate).format("DD-MM-YYYY") : ""}

                                </td> */}

                                <td align='center' style={{ whiteSpace: 'nowrap' }}>
                                    <span>{item.letterDate ? moment(item.letterDate).format("DD-MM-YYYY") : ""}</span>
                                    <br />
                                    <span>{item.letterReceivedDate ? moment(item.letterReceivedDate).format("DD-MM-YYYY") : ""}</span>
                                </td>


                                <td>{item.transfer_From_Name ? item.transfer_From_Name : ""}</td>
                                <td>{item.transfer_To_Name ? item.transfer_To_Name : ""}</td>
                                {/* <td align='center' style={{ width: '10%', whiteSpace: 'nowrap' }}>
                                    {item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                        : "-"
                                    }
                                    <hr style={{ padding: 0, margin: 0 }} />
                                    {item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('h:mm:ss a')
                                        : "-"
                                    }
                                </td> */}

                                <td align='center' style={{ whiteSpace: 'nowrap' }}>
                                    <span> {item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                        : "-"
                                    }</span>
                                    <br />
                                    <span>{item.lastModifiedDate ?
                                        moment(item.lastModifiedDate).format('h:mm:ss a')
                                        : "-"
                                    }</span>
                                </td>

                                {/* <td>
                                                                    {item.correspondLetterFilePath ?
                                                                        <InboxLetterModal
                                                                            title={
                                                                                <button type="button" className="btn btn-primary" style={{ width: "100%", fontSize: 12, padding: "2px" }}>पहा</button>
                                                                            }
                                                                            image={item.correspondLetterFilePath}
                                                                            type="inbox"
                                                                            inwardNumber={inwardNumber}
                                                                        />
                                                                        : ""}

                                                                </td> */}
                                <td>{item.correspondRemark ? item.correspondRemark : "-"}</td>
                                <td align='center' id="srwidth">{item.statusName ? item.statusName : ""}</td>

                            </tr>
                        )
                            : <></>
                        }

                    </tbody>
                </table>
            </div>
        )
    }


    return (
        <>
            <Popup


                trigger={
                    <button data-tooltip="tooltip" title="ट्रॅक"
                        className="btn btn-success btn-md mt-2"
                        style={{ cursor: 'pointer', color: "#fff" }}
                    >
                        ट्रॅक
                    </button>
                }
                onOpen={GetTrackData}
                modal={true}
                closeOnDocumentClick={false}
                contentStyle={{ width: "88%", padding: "1%" }}
            >
                {
                    close => (
                        <div style={{ backgroundColor: 'white', width: "100%" }} >
                            {/* <div className="modal fade" id="attachment" tabindex="-1" aria-hidden="true"> */}
                            <div className=" modal-dialog-scrollable " style={{ width: "100%" }}>
                                <div className="modal-content" style={{ width: "100%" }}>

                                    <div className="modal-header" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "flex-end" }}>
                                        <h2 className="modal-title" id="staticBackdropLabel">नस्ती ट्रॅक </h2>
                                        <span style={{ fontSize: 12, fontWeight: "600", wordBreak: "break-all" }}>{fileNumber}</span>
                                        {/* <ExportToExcel2 /> */}
                                        <ExportToExcel2 />
                                        {/* <span>Export</span> */}
                                        <button onClick={() => close()} className="btn-close" type="button">x</button>
                                    </div>

                                    {
                                        loading ?
                                            <Loader fullPage loading />
                                            : <></>

                                    }
                                    <div className="modal-body" style={{ width: "100%" }}>

                                        <div
                                            className="table-responsive"
                                            // onScroll={handleScroll}
                                            style={{ height: '450px', width: 'auto', overflowY: "auto" }}
                                        >
                                            {
                                                createdByUserName !== '' || createdByUserName ?
                                                    <text className="trackUsername">नस्ती नोंद केलेल्या विभागाचे व कार्यासनाचे नाव - <text style={{ color: 'blue' }}>{createdByUserName ? createdByUserName : ''}</text></text>
                                                    : ''
                                            }

                                            <table className="table table-bordered data-table">
                                                <thead>
                                                    <tr>
                                                        {/* <th id='srwidth1'>अ.क्र</th>
                                                        <th>आवक क्रमांक </th>
                                                        <th>जावक क्रमांक </th>
                                                        <th>पत्र दिनांक/मिळाल्याची दिनांक</th>
                                                        <th>कोणाकडून आले</th>
                                                        <th>कोणास पाठवले</th>
                                                        <th>पत्र दिनांक व वेळ</th>
                                                        <th>पत्र</th>
                                                        <th>शेरा</th>
                                                        <th>पत्र स्थिती</th> */}

                                                        <th id='srwidth1'>अ.क्र</th>
                                                        <th style={{ width: '5%' }}>नस्ती क्रमांक </th>
                                                        <th style={{ width: '5%' }}>नस्ती जावक क्रमांक </th>
                                                        <th style={{ width: '8%' }}>नस्ती  दिनांक/मिळाल्याची दिनांक</th>
                                                        <th style={{ width: '25%' }}>कोणाकडून आले</th>
                                                        <th style={{ width: '25%' }}>कोणास पाठवले</th>
                                                        <th style={{ width: '8%' }}>नस्ती  दिनांक व वेळ</th>
                                                        {/* <th style={{ width: '5%' }}>पत्र</th> */}
                                                        <th style={{ width: '16%' }}>शेरा</th>
                                                        <th style={{ width: '10%' }}>नस्ती  स्थिती</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {tableData.length > 0 ?
                                                        tableData.map((item, index) => (

                                                            <tr key={item.inward_TransferID}>
                                                                <td align='center' id="srwidth">{index + 1}</td>
                                                                <td align='center' style={{ width: '8%' }} id="breakword">{item.fileNumber ? item.fileNumber : "-"}</td>
                                                                <td align='center' style={{ width: '8%' }} id="breakword">{item.fileOutwardNumber ? item.fileOutwardNumber : "-"}</td>
                                                                <td align='center' style={{ whiteSpace: 'nowrap' }}>
                                                                    {item.letterDate ? moment(item.letterDate).format("DD-MM-YYYY") : ""}
                                                                    <hr style={{ padding: 0, margin: 0 }} />
                                                                    {item.letterReceivedDate ? moment(item.letterReceivedDate).format("DD-MM-YYYY") : ""}

                                                                </td>
                                                                <td>{item.transfer_From_Name ? item.transfer_From_Name : ""}</td>
                                                                <td>{item.transfer_To_Name ? item.transfer_To_Name : ""}</td>
                                                                <td align='center' style={{ width: '10%', whiteSpace: 'nowrap' }}>
                                                                    {item.lastModifiedDate ?
                                                                        moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                                                        : "-"
                                                                    }
                                                                    <hr style={{ padding: 0, margin: 0 }} />
                                                                    {item.lastModifiedDate ?
                                                                        moment(item.lastModifiedDate).format('h:mm:ss a')
                                                                        : "-"
                                                                    }
                                                                </td>
                                                                {/* <td>
                                                                    {item.correspondLetterFilePath ?
                                                                        <InboxLetterModal
                                                                            title={
                                                                                <button type="button" className="btn btn-primary" style={{ width: "100%", fontSize: 12, padding: "2px" }}>पहा</button>
                                                                            }
                                                                            image={item.correspondLetterFilePath}
                                                                            type="inbox"
                                                                            inwardNumber={inwardNumber}
                                                                        />
                                                                        : ""}

                                                                </td> */}
                                                                <td>{item.correspondRemark ? item.correspondRemark : "-"}</td>
                                                                <td align='center' id="srwidth">{item.statusName ? item.statusName : ""}</td>

                                                            </tr>
                                                        )) :
                                                        <label style={{ width: '200px' }}>No Record Found ...</label>
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Popup>
        </>
    )
}