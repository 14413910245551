import { baseUrl } from "../../../../BaseURL/BaseURL";


var month = (new Date().getMonth()) + 1
var year = new Date().getFullYear()


export function GetDeptLetterDashCount({ UserID, setDashCount, setdashloading }) {
    setdashloading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
    fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_Letter_TabMainTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&UserID=${UserID}&Flag=All`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setDashCount(result.data)
            }
            setdashloading(false)
        })
        .catch(error => {
            console.log('error', error)
            setdashloading(false)

        });
}


export const Top3BranchLetterPralambitAPI = (setTopPralambit, setTopPralmbitLables, setTopPralmbitCount, setTopPralmbitLoader, Flag, ShowBy, m_BranchNameMasterID, setIsPost) => {
    let TopMostlabels = []
    let TopMostCount = []
    setTopPralmbitLoader(true)
    let User = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
    fetch(`${baseUrl}/CommDashBoard/Get_DB_Branch_Letter_DetailsTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${Flag}&ShowBy=${ShowBy}&UserID=${User}&M_BranchNameMasterID=${m_BranchNameMasterID}`, requestOptions)
        
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                if (result.data) {
                    setTopPralambit(result.data.slice(0, 5))
                    for (var i = 0; i < result.data.slice(0, 5).length; i++) {
                        TopMostlabels.push(result.data[i].entityName)
                        TopMostCount.push(result.data[i].totalPendingCount)
                    }
                    setTopPralmbitLables(TopMostlabels)
                    setTopPralmbitCount(TopMostCount)
                } else {
                    setTopPralambit([])
                    setTopPralmbitLables([])
                    setTopPralmbitCount([])
                }


                // setIsPost()
            } else {
                setTopPralambit([])
            }
            setTopPralmbitLoader(false)

        })
        .catch(error => {
            console.log('error', error)
            setTopPralmbitLoader(false)
        });
}

export const BranchCategoryWisePendencyAPI = (setPendingPatraData, setPendingLoader, DaysCount, m_BranchNameMasterID) => {

    setPendingLoader(true)
    let UserID = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Branch_Letter_LetterClassification_TabMainTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${DaysCount ? DaysCount : 'All-Day'}&UserID=${UserID}&M_BranchNameMasterID=${m_BranchNameMasterID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result)
            if (result.status) {
                setPendingPatraData(result.data)
            } else {
                setPendingPatraData([])
            }
            setPendingLoader(false)

        })
        .catch(error => {
            console.log('error', error)
            setPendingLoader(false)
        });
}

export const BranchVibhagTableData = (setddlloader, setReferenceTypeTableData, flag, M_DepartmentNameMasterID, M_LetterClassificationID, m_BranchNameMasterID) => {
    setddlloader(true)
    let UserID = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Branch_Letter_DetailsClassificationWiseTableUserWiseTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${flag}&M_LetterClassificationID=${M_LetterClassificationID ? M_LetterClassificationID : '0'}&UserID=${UserID}&M_BranchNameMasterID=${m_BranchNameMasterID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setReferenceTypeTableData(result)
            } else {
                setReferenceTypeTableData([])
            }
            setddlloader(false)

        })
        .catch(error => {
            console.log('error', error)
            setddlloader(false)
        });
}

export const BranchOutwardLetterData = ({ setgridData, setloading, Flag, FilterID,m_BranchNameMasterID }) => {
    setloading(true)
    let UserID = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
  
    fetch(`${baseUrl}/CommDashBoard/Get_DB_All_Letter_ActionIndicator_DetailsDeptwiseTableUserwiseTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${Flag}&FilterID=${m_BranchNameMasterID}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setgridData(result)
            } else {
                setgridData([])
            }
            setloading(false)

        })
        .catch(error => {
            console.log('error', error)
            setloading(false)
        });
}

export const DeptLetterCommDDLAPI = (setDDLData, setddlloader) => {

    setddlloader(true)
    let UserID = sessionStorage.getItem("User")
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
  
    fetch(`${baseUrl}/CommDashBoard/Get_DB_All_Letter_DDL_M_LetterClassification?UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status) {
                setDDLData(result.data)
            } else {
                setDDLData([])
            }
            setddlloader(false)

        })
        .catch(error => {
            console.log('error', error)
            setddlloader(false)
        });
}


export const BranchLetterAccuracyTable = (setWorkTableData, setWorkLoader, setWorkPieLoader, UserID, Flag, ShowBy, m_BranchNameMasterID) => {
    let id = sessionStorage.getItem('refid')
    // console.log(id)
    setWorkLoader(true)
    setWorkPieLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/CommDashBoard/Get_DB_Branch_Letter_DetailsTotalCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${Flag}&ShowBy=${ShowBy}&UserID=${UserID}&M_BranchNameMasterID=${m_BranchNameMasterID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result) {
                setWorkTableData(result)
                // console.log(result)
            } else {
                setWorkTableData([])
            }
            setWorkLoader(false)
            setWorkPieLoader(false)
        })
        .catch(error => {
            console.log('error', error)
            setWorkLoader(false)
            setWorkPieLoader(false)
        });
}