import InboxLetterModal from "../../../../File/PopUp/InboxLetterModal/InboxLetterModal"
import $ from 'jquery'

export default function CommFourthPaha({setModalShow, ModalData}) {

    $(function () {
        $(".modal-header").on("mousedown", function (e) {
            var $draggable = $(this);
            var x = e.pageX - $draggable.offset().left,
                y = e.pageY - $draggable.offset().top;
            $("body").on("mousemove", function (mousemoveEvt) {
                $draggable.closest(".modal-dialog").offset({
                    "left": mousemoveEvt.pageX - x,
                    "top": mousemoveEvt.pageY - y
                });
            });
            $("body").one("mouseup", function () {
                $("body").off("mousemove");
            });
        })
    })

    // console.log(ModalData)
    return (
        <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">

                    <div className="modal-header py-2">
                        <h4 className="modal-title">

                            {ModalData && ModalData.fileNumber ? ModalData.fileNumber : ''}
                        </h4>
                        <button type="button" className="btn-close mr-2" data-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setModalShow(false)
                            }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <main>

                            <div className="container-fluid px-5">
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12">

                                        <div className="form-row row mt-5">

                                            <div className="col-md-12 form-group">
                                                <label className="control-label" for="officeName">विषय</label>
                                                <div>
                                                    <textarea
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="officeName"
                                                        id="officeName"
                                                        value={ModalData && ModalData.letterSubject ? ModalData.letterSubject : ''}
                                                        readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label className="control-label" for="officeName">शेरा</label>
                                                <div>
                                                    <textarea
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="officeName"
                                                        id="officeName"
                                                        value={ModalData && ModalData.correspondRemark ? ModalData.correspondRemark : ''}
                                                        readOnly />
                                                </div>
                                            </div>

                                            <div className="col-sm-4 form-group" style={{ visibility: 'hidden' }}>
                                                <label className="control-label" for="outwardNo">माध्यम</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="outwardNo"
                                                        id="outwardNo"
                                                        value={ModalData && ModalData.letterMediumIndicator ? ModalData.letterMediumIndicator : ''}

                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-3 form-group mt-4">
                                                <div>

                                                    {
                                                        ModalData && ModalData.letterMediumIndicatorID === 8 ?

                                                            <InboxLetterModal
                                                                data-dismiss="modal"
                                                                title={
                                                                    <button type="button" className="btn btn-primary">पहा</button>
                                                                }
                                                                image={ModalData && ModalData.correspondLetterFilePath ? ModalData.correspondLetterFilePath : null}
                                                                type="inbox"
                                                                buttonsize='large'
                                                            // inwardNumber={inwardNumber}
                                                            />
                                                            :
                                                            <></>

                                                    }



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>

                    </div>

                </div>

            </div>
        </div>
    )
}