import React from 'react'
import ViewDownloadPDF from '../ViewDownloadPDF/ViewDownloadPDF';



function InboxLetterModal({ title, image, type, inwardNumber, buttonsize }) {
    const [DownloadError, setDownloadError] = React.useState(false)

    return (

        <>
            <ViewDownloadPDF
                url={image}
                buttonsize={buttonsize}
            />
        </>


    )
}

export default InboxLetterModal
