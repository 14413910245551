import ViewDownloadPDF from "./ViewDownloadPDF";



const ViewSingleLetterDetails = ({ singleLetter, setsingleLetter }) => {
    return (<>
        <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="paha" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document" >
                <div className="modal-content">

                    <div className="modal-header py-2">
                        <h4 className="modal-title">

                            {singleLetter && singleLetter.inwardNumber ? singleLetter.inwardNumber : ''}
                        </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={() => setsingleLetter({})}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <main>

                            <div className="container-fluid px-5">
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12">

                                        <div className="form-row row">

                                            <div className="col-md-12 form-group">
                                                <label className="control-label" for="officeName">पत्र विषय</label>
                                                <div>
                                                    <textarea
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="officeName"
                                                        id="officeName"
                                                        value={singleLetter && singleLetter.letterSubject ? singleLetter.letterSubject : ''}
                                                        readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label className="control-label" for="officeName">शेरा</label>
                                                <div>
                                                    <textarea
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="officeName"
                                                        id="officeName"
                                                        value={singleLetter && singleLetter.correspondRemark ? singleLetter.correspondRemark : ''}
                                                        readOnly />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 form-group">
                                                <label className="control-label" for="outwardNo">माध्यम</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="outwardNo"
                                                        id="outwardNo"
                                                        value={singleLetter && singleLetter.letterMediumIndicator ? singleLetter.letterMediumIndicator : ''}

                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-3 form-group mt-4">
                                                <div>

                                                    {
                                                        singleLetter && singleLetter.letterMediumIndicatorID === 8 ?


                                                            <ViewDownloadPDF url={singleLetter && singleLetter.correspondLetterFilePath ? singleLetter.correspondLetterFilePath : ""} buttonsize={"large"} />
                                                            :
                                                            <></>

                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </main>

                    </div>

                </div>

            </div>
        </div>
    </>);
}

export default ViewSingleLetterDetails;