import { baseUrl } from "../../BaseURL/BaseURL";


export const GetUserToken = ({
    NotificationCount,
    TypeFlag,
    UserID,
    setUserData,
    handleSendNotification
}) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    fetch(`${baseUrl}/LFMS/Master/Get_M_NotificationDataPush_Select?NotificationCount=${NotificationCount}&TypeFlag=${TypeFlag}&UserID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setUserData(result.data)
                if (result.data[0].deviceID) {
                    handleSendNotification(result.data)
                }
            }
        })
        .catch(error => {
            console.log('error', error)
        });
}



export const SendNotification = ({ androidDeviceId, body, title }) => {
    // console.log(androidDeviceId)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "key = AAAAX5QtKJY:APA91bERIGGYsSOqy3R0pCQ_AyYVtQdlmPzAtbbbwGKDQxaTygEVfSJQcJpOg7qjj_72MC4C91qgm1wOiODtXfmUcjp-GfILxsx80H3uA_f5pMO-wzbk5d4iiLgluEwQs70BWgIlMtvB");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "to": androidDeviceId,
        "notification": {
            "body": body,
            "title": title
        }
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                // setUserData(result.data)
            }
        })
        .catch(error => {
            console.log('error', error)
        });
}

export const GetNotificationList = ({
    UserID,
    setNotificationList, Top, setloader, dashboardID
}) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
    setloader(true)
    fetch(`${baseUrl}/LFMS/Master/Get_M_NotificationDataUserWise_Select?TypeFlag=${dashboardID === '1' ? 'Letter' : 'File'}&UserID=${UserID}&Top=${Top}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setNotificationList(result.data)
            } else {
                setNotificationList([])
            }
            setloader(false)
        })
        .catch(error => {
            console.log('error', error)
            setloader(false)
        });
}

export const ExcelGetNotificationList = ({
    UserID,
    setNotificationList, Top, setloader, dashboardID
}) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };
    setloader(true)
    fetch(`${baseUrl}/LFMS/Master/Get_M_NotificationDataUserWise_Select?TypeFlag=${dashboardID === '1' ? 'Letter' : 'File'}&UserID=${UserID}&Top=${Top}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setNotificationList(result.data)
            }else {
                setNotificationList([])
            }
            setloader(false)
        })
        .catch(error => {
            console.log('error', error)
            setloader(false)
        });
}

export const ReadPostNotification = ({ UserID, M_NotificationDataID, handleGetNotiAPI }) => {
    var formdata = new FormData();
    formdata.append("M_TableUserMasterID", UserID);
    formdata.append("M_NotificationDataID", M_NotificationDataID);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: myHeaders,
    };

    fetch(`${baseUrl}/LFMS/Master/Post_M_NotificationDataRead_Update`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetNotiAPI()
            }
        })
        .catch(error => console.log('error', error));
}