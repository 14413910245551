import moment from 'moment';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const ExportViewData = ({ exportgridData, heading }) => {
    return (<>
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHtmlTableToExcel
                id="test-table-xls-buttondash"
                className="download-table-xls-button btn btn-success btn-sm pt-1 pb-1 pl-3 pr-3"
                table="table-to-xlsdash"
                filename={heading}
                sheet={heading}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />

            <table id="table-to-xlsdash" style={{ display: 'none', width: '100%' }}>
                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{heading}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th>अ.क्र</th>
                        <th >आवक क्रमांक</th>
                        <th >पत्र दिनांक/मिळाल्याची दिनांक </th>
                        <th >पत्र क्रमांक </th>
                        {/* <th >पत्र दिनांक व वेळ </th> */}
                        <th >कार्यवाही प्रकार </th>
                        <th>कोणाकडून आले </th>
                        <th >कोणास पाठवले </th>
                        <th >वर्गवारी /पत्र प्रकार</th>
                        {/* <th >पहा</th> */}
                    </tr>
                </thead>


                <tbody>

                    {exportgridData.length > 0 ?
                        exportgridData.map((item, index) => {
                            return (
                                <tr>
                                    <td align='center' id="srwidth">{index + 1}</td>
                                    <td>{item.inwardNumber}</td>
                                    <td align='center' style={{border:'none'}}>{moment(item.letterDate).format('DD-MM-YYYY')}
                                        <br />
                                        {moment(item.letterReceivedDate).format('DD-MM-YYYY')}

                                    </td>


                                    <td>{item.receivedLetterNumber}</td>


                                    <td>{item.actionTypeIndicator}</td>
                                    <td>{item.letterSenderOfficeName}</td>
                                    <td>{item.transfer_To_Name}</td>
                                    <td style={{border:'none'}}>
                                        {item.classificationName}
                                        <br />
                                        {item.letterTypeName}
                                    </td>

                                    

                                </tr>
                            )
                        }
                        ) :
                        <tr>
                            <td> No records found...</td>
                        </tr>}


                </tbody>


            </table>
        </div>

    </>);
}

export default ExportViewData;