import { useEffect, useState } from "react"
import Select from "react-select";
import { baseUrl } from "../../../../BaseURL/BaseURL";

export const SelectBranchMasterDDL = (props) => {

    const { IsBranchSelect, setIsBranchSelect } = props

    useEffect(() => {
        GetBranchList()
    }, [])

    const [SelectedMasterBranch, setSelectedMasterBranch] = useState({
        DDL: [],
        ID: 0,      
        Label: "शाखा निवडा...",
    })

    const handleChangeBranch = (e) => {
        if (e) {
            setSelectedMasterBranch({ ...SelectedMasterBranch, ID: e.value,Label: e.label })
            handleChangeUser(e.value)
        }
        else {
            setSelectedMasterBranch({ ...SelectedMasterBranch, ID: 0, Label: "शाखा निवडा..." });
        }
    }

    const handleChangeUser = (value) => {
        sessionStorage.setItem("m_BranchNameMasterID", value)   
        setIsBranchSelect(!IsBranchSelect)
    }

    const GetBranchList = () => {
        var BUser = sessionStorage.getItem('BUser')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/DashBoard/GET_DB_DDL_MonitoringDept_M_TableUserMasterWiseBranchNameMaster?UserID=${BUser}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300 && result.data) {
                    let list = result.data.map((item, index) => ({
                        value: item.m_BranchNameMasterID,                     
                        label: item.ddlName,
                    }))

                    setSelectedMasterBranch({
                        DDL: list,
                        ID: list[0].value,                    
                        Label: list[0].label
                    })
                    handleChangeUser(list[0].value)

                } else {
                    setSelectedMasterBranch({
                        DDL: [],
                        ID: 0,
                        Label: "शाखा निवडा..."
                    })
                }
            })
            .catch(error => {
                setSelectedMasterBranch({
                    DDL: [],
                    ID: 0,
                    Label: "शाखा निवडा..."
                })
                console.log('error', error)
            });
    }

    return (
        <div className="row col-sm-12 col-lg-12 ">
            {/* <div className="row col-lg-6 col-sm-6 c"> */}
            <div className="col-lg-2 col-sm-2 mt-0"  >
                <h2 className='selectbranch'>शाखा निवडा :</h2>
            </div>
            <div className="col-lg-8 col-sm-8 ml-1 mt-1 mt-md-n2">
                <Select
                    isClearable={false}
                    // isRtl={isRtl}
                    isSearchable={false}
                    value={{ value: SelectedMasterBranch.ID, label: SelectedMasterBranch.Label }}
                    onChange={(e) => {
                        handleChangeBranch(e)

                    }}

                    options={SelectedMasterBranch.DDL}
                />

            </div>
        </div>
        // </div>
    )
}