
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaHome, FaEnvelopeOpenText, FaQrcode } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { FiEdit, FiHome } from "react-icons/fi";
import { ImQrcode } from "react-icons/im"
import $ from 'jquery'
import { LogOut } from 'react-feather';


export default function NavbarLetter({ active }) {
    const navigate = useNavigate()
    const [isclickedclass, setisclickedclass] = React.useState(active)
    const RoleID = sessionStorage.getItem("RoleID")
    const M_OfficeMasterID = sessionStorage.getItem("M_OfficeMasterID")

    // console.log("M_OfficeMasterID",M_OfficeMasterID)

    var width = $("body").width()

    const Logout = () => {
        sessionStorage.clear();
        // localStorage.clear();
        navigate('/')
    };


    return (
        <div style={{ maxWidth: '200px' }}>

            <div className={width > 873 ? "sidebar collapse show" : "sidebar collapse "} id="sidebartoggler">


                <div className="sidebar-content js-simplebar">
                    <a className="sidebar-brand" to="index.html">
                        &nbsp;
                    </a>

                    <ul className="sidebar-nav" id="navv">

                        <li
                            className={isclickedclass === "deptDashboard" || isclickedclass === "branchdashboard" || isclickedclass === "Dashboard" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to={RoleID === '4' ? "/deptdashboard" : RoleID === '9' ? "/branchdashboard" : "/dashboard"}>
                                <FiHome />
                                <span id="span" className="align-middle">डॅशबोर्ड</span>
                            </Link>
                        </li>

                        {
                            RoleID == '4' || RoleID == '5' || RoleID == '8' || RoleID == '9' || RoleID == '11' || RoleID == '12' || RoleID == '16' || RoleID == '17' ?
                                <>
                                    <li
                                        className={isclickedclass === "newletter" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/newletter">
                                            <FiEdit />
                                            <span id="span" className="align-middle">नवीन पत्र नोंद</span>
                                        </Link>
                                    </li>

                                    <li
                                        className={isclickedclass === "InboxLetterRegister" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/registerletter">
                                            <FaEnvelopeOpenText />
                                            <span id="span" className="align-middle">आवक पत्र रजिस्टर</span>
                                        </Link>
                                    </li>
                                </>
                                : ''
                        }

                        <li
                            className={isclickedclass === "inboxletter" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/inboxletter">
                                <FaEnvelopeOpenText />
                                <span id="span" className="align-middle">इनबॉक्स पत्र</span>
                            </Link>
                        </li>

                        {
                            RoleID == '4' || RoleID == '5' || RoleID == '8' || RoleID == '9' || RoleID == '11' || RoleID == '12' || RoleID == '16' || RoleID == '17' ?
                                <>
                                    {
                                        M_OfficeMasterID == "11" ?
                                            ''
                                            :
                                            <li
                                                className={isclickedclass === "NewOutwardLetter" ? "sidebar-item active" : "sidebar-item"}
                                            >
                                                <Link className="sidebar-link" to="/newOutLetter">
                                                    <FiEdit />
                                                    <span id="span" className="align-middle">नविन जावक पत्र नोंद</span>
                                                </Link>
                                            </li>
                                    }



                                    <li
                                        className={isclickedclass === "OutwardLetter" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/outwardletter">
                                            <FaEnvelopeOpenText />
                                            <span id="span" className="align-middle">जावक पत्र रजिस्टर</span>
                                        </Link>
                                    </li>
                                </>
                                : ''
                        }

                        {
                            RoleID == '4' || RoleID == '5' || RoleID == '8' || RoleID == '9' || RoleID == '11' ?
                                <>
                                    <li
                                        className={isclickedclass === "PatraAhval" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/letterahval">
                                            <FaEnvelopeOpenText />
                                            <span id="span" className="align-middle">पत्र अहवाल</span>
                                        </Link>
                                    </li>

                                    <li
                                        className={isclickedclass === "forwardReport" ? "sidebar-item active" : "sidebar-item"}
                                    >
                                        <Link className="sidebar-link" to="/forward-report">
                                            <FaEnvelopeOpenText />
                                            <span id="span" className="align-middle">अग्रेषित अहवाल</span>
                                        </Link>
                                    </li>

                                </>
                                : ""
                        }

                        {/* <li
                            className={isclickedclass === "InboxLetterRegister" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/inboxLetterRegister">
                                <FaArrowAltCircleLeft />
                                <span id="span" className="align-middle">आवक पत्र रजिस्टर</span>
                            </Link>
                        </li> */}



                        {/* <li className="sidebar-item" id="sidebarlogoutbutton">
                            <a className="sidebar-link" onClick={() => Logout()}>
                                <LogOut size={18} />
                                लॉग आउट

                            </a>
                        </li> */}


                    </ul>
                </div>
            </div>


        </div>
    )
}
