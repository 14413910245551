
import React from 'react'
import Max_Pendancy_PieChart from '../../Commisioner/SPDashboard/Dashboard/Pie_Chart/Max_Pendancy_PieChart'
import GraphButton from '../../../../Component/Button/GraphButton'
import Max_Pendancy_BarChart from '../../Commisioner/SPDashboard/Dashboard/Pie_Chart/Max_Pendancy_BarChart'
import CardTotal from '../../Commisioner/SPDashboard/Dashboard/Cards/CardTotal'
import BranchCardTotal6 from '../Cards/BranchCardTotal6'
import BranchCardTotal4 from '../Cards/BranchCardTotal4'
import BranchCardTotal from '../Cards/BranchCardTotal'
import FirstUserPopUp from '../../DeptUsers/DeptPopUp/FirstUserPopUp'
import { DeptTop3PralambitAPI } from '../BranchAPI/BranchFileAPI'


export default function BranchFileMianCard({ flag, DashCount, onsetFlag, Countdata, pendigCount, coloR, dashloading, IsBranchSelect }) {


    const RoleID = sessionStorage.getItem("RoleID")

    const onClicksetFlag = (value) => {
        onsetFlag(value)
    }

    var labels1 = ["Good", "Fair", "Bad"]
    var pendigCount1 = [45, 22, 21]
    var coloR1 = ["#460F8A", "#8D9268", "#A35BE1"]

    const [ShowTablePopUp, setShowTablePopUp] = React.useState(false)
    const [TableDataPopUp, setTableDataPopUp] = React.useState([])
    const [TopPralambit, setTopPralambit] = React.useState()
    const [TopPralmbitLables, setTopPralmbitLables] = React.useState([])
    const [TopPralmbitCount, setTopPralmbitCount] = React.useState([])
    var coloR = ["#fe974a", "#0063ff", "#3dd598", "#ffc442", '#460f8a']

    const [TopPralmbitLoader, setTopPralmbitLoader] = React.useState(false)

    const [TopPralambit2, setTopPralambit2] = React.useState()
    const [TopPralmbitLables2, setTopPralmbitLables2] = React.useState([])
    const [TopPralmbitCount2, setTopPralmbitCount2] = React.useState([])
    const [TopPralmbitLoader2, setTopPralmbitLoader2] = React.useState(false)
    const [showGraph, setShowGraph] = React.useState('Bar')

    const handleHideShowTable = (data, show) => {
        setShowTablePopUp(show)
        setTableDataPopUp(data)
    }


    React.useEffect(() => {
        DeptTop3PralambitAPI(setTopPralambit, setTopPralmbitLables, setTopPralmbitCount, setTopPralmbitLoader)
        // Top3PralambitAPI2(setTopPralambit2, setTopPralmbitLables2, setTopPralmbitCount2, setTopPralmbitLoader2)
    }, [IsBranchSelect])

    function zeroTest(element) {
        return element === 0;
    }

    var allZeros

    if (TopPralmbitCount) {
        allZeros = TopPralmbitCount.every(zeroTest);
    }

    function zeroTest2(element) {
        return element === 0;
    }

    var allZeros2

    if (TopPralmbitCount2) {
        allZeros2 = TopPralmbitCount2.every(zeroTest2);
    }

    // console.log('DashCount', RoleID)
    return (
        <div>


            <div className="row maintop" style={{ marginTop: '3%' }} >
                <div className="col-xl-12 col-xxl-12 d-flex">
                    <div className="w-100">
                        <div className="row">
                            {/* <div className="col-sm-12 col-lg-12">
                                <h4 className="py-2">
                                    <b>कार्यालय {flag} गोषवारा</b>
                                </h4>
                            </div> */}


                            <div className="col-sm-12 col-lg-6">
                                <div className="row">

                                    {/* <div className="col-sm-6 col-lg-4 gx-2">
                                        {
                                            flag && flag === 'file' ?

                                                <MainCategoryPopUp

                                                    flag="nasti"
                                                    name={flag && flag === 'file' ? "एकूण नस्ती" : "एकूण पत्र"}
                                                    NondCount={DashCount && DashCount[0] ? (DashCount[0].totalCount) : '0'}
                                                    EterCount={DashCount && DashCount[1] ? (DashCount[1].totalCount) : '0'}

                                                    table='show'
                                                    title={
                                                        <DeptCardTotal
                                                            title={flag && flag === 'file' ? "एकूण नस्ती" : "एकूण पत्र"}
                                                            quantity={
                                                                flag && flag === 'file' ?
                                                                    DashCount && DashCount[0] ? (DashCount[0].totalCount) + (DashCount[1].totalCount) : '0'
                                                                    :
                                                                    0
                                                            }
                                                            usedclass={flag && flag === 'file' ? "spcard bg-red1 py-3 file white main-tab" : "spcard bg-red1 py-3 letter white main-tab"}
                                                            titleColorClass="spcard-title text-white"
                                                            countColorClass="spcard-title text-white"
                                                            tableflag='ALL'
                                                            onClicksetFlag={onClicksetFlag}
                                                            dashloading={dashloading}
                                                        />
                                                    }
                                                />
                                                :
                                                // <MainCategoryPopUp

                                                //     flag="nasti"
                                                //     name={flag && flag === 'file' ? "एकूण नस्ती" : "एकूण पत्र"}
                                                //     NondCount={DashCount && DashCount[0] ? (DashCount[0].totalCount) : '0'}
                                                //     EterCount={DashCount && DashCount[1] ? (DashCount[1].totalCount) : '0'}

                                                //     table='show'
                                                //     title={
                                                <DeptCardTotal
                                                    title={flag && flag === 'file' ? "एकूण नस्ती" : "एकूण पत्र"}
                                                    quantity={
                                                        flag && flag === 'file' ?
                                                            DashCount && DashCount[0] ? (DashCount[0].totalCount) + (DashCount[1].totalCount) : '0'
                                                            :
                                                            0
                                                    }
                                                    usedclass={flag && flag === 'file' ? "spcard bg-red1 py-3 file white main-tab" : "spcard bg-red1 py-3 letter white main-tab"}
                                                    titleColorClass="spcard-title text-white"
                                                    countColorClass="spcard-title text-white"
                                                    tableflag='ALL'
                                                    onClicksetFlag={onClicksetFlag}
                                                    dashloading={dashloading}
                                                />
                                            //     }
                                            // />
                                        }

                                    </div> */}

                                    {/* <div className="col-sm-6 col-lg-4 gx-2"
                                        style={{ cursor: 'pointer' }}
                                        // onClick={() => condition ?  functionToClick() : null}
                                        onClick={() =>
                                            flag === 'file' ?

                                                handleHideShowTable({
                                                    flag: 'All',
                                                    showby: 'TableUserWise',
                                                    M_DepartmentNameMasterID: '0',
                                                    name1: 'एकूण नोंद केलेल्या नस्ती',
                                                    name: "एकूण नस्ती",
                                                    table: 'show',
                                                    fourthflag: 'TotalInwardCount',
                                                    fourthShowby: 'All',
                                                    fourthfilterID: '',
                                                    fourthReferenceTypeID: '0'

                                                }, true)
                                                // sessionStorage.setItem('flagPending1', 'Pending')

                                                :
                                                null
                                        }
                                    >

                                        <DeptCardTotal
                                            title={flag && flag === 'file' ? "एकूण नस्ती" : "एकूण पत्र"}
                                            quantity={
                                                flag && flag === 'file' ?
                                                    // DashCount && DashCount[0] ? (DashCount[1].totalCount) + (DashCount[2].totalCount) : '0'
                                                    Countdata && Countdata.TotalInwardCount ? Countdata.TotalInwardCount : '0'
                                                    :
                                                    0
                                            }
                                            usedclass={flag && flag === 'file' ? "spcard bg-red1 py-3 file white main-tab" : "spcard bg-red1 py-3 letter white main-tab"}
                                            titleColorClass="spcard-title text-white"
                                            countColorClass="spcard-title text-white"
                                            tableflag='ALL'
                                            onClicksetFlag={onClicksetFlag}
                                            dashloading={dashloading}
                                        />

                                    </div> */}

                                    {
                                        // RoleID == '4' ?
                                        RoleID == '9' ?
                                            <>
                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण नस्ती',
                                                            name1: "एकूण प्रलंबित",
                                                            table: 'show',
                                                            fourthflag: 'TotalPendingCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'
                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }
                                                    }>
                                                    <CardTotal
                                                        title="एकूण नस्ती"
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalInwardCount ? Countdata.TotalInwardCount : '0'
                                                                // DashCount && DashCount[2] && DashCount[2].totalCount ? DashCount[2].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-red1 white py-3 main-tab pendingLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                    {/* }
                                        /> */}
                                                </div>

                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण प्रलंबित',
                                                            name1: "एकूण प्रलंबित",
                                                            table: 'show',
                                                            fourthflag: 'TotalPendingCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'
                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }}
                                                >
                                                    <CardTotal
                                                        title="एकूण प्रलंबित"
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalPendingCount ? Countdata.TotalPendingCount : '0'
                                                                // DashCount && DashCount[2] && DashCount[2].totalCount ? DashCount[2].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-orange1 white py-3 main-tab pendingLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}
                                                    />
                                                    {/* }
                                        /> */}

                                                </div>

                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण निर्गमीत',
                                                            name1: "एकूण निर्गमीत",
                                                            table: 'show',
                                                            fourthflag: 'TotalCloseCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'

                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }
                                                    }
                                                >
                                                    <CardTotal
                                                        title={flag && flag === 'file' ? "एकूण निर्गमीत" : "एकूण निर्गमीत"}
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalCloseCount ? Countdata.TotalCloseCount : '0'
                                                                // DashCount && DashCount[3] && DashCount[3].totalCount ? DashCount[3].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-purple1 py-3 white main-tab closeLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                    {/* }
                                        /> */}

                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण नस्ती',
                                                            name1: "एकूण प्रलंबित",
                                                            table: 'show',
                                                            fourthflag: 'TotalPendingCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'
                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }
                                                    }>
                                                    <CardTotal
                                                        title="एकूण नस्ती"
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalInwardCount ? Countdata.TotalInwardCount : '0'
                                                                // DashCount && DashCount[2] && DashCount[2].totalCount ? DashCount[2].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-red1 white py-3 main-tab pendingLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                    {/* }
                                        /> */}
                                                </div>

                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण प्रलंबित',
                                                            name1: "एकूण प्रलंबित",
                                                            table: 'show',
                                                            fourthflag: 'TotalPendingCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'
                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }}
                                                >
                                                    <CardTotal
                                                        title="एकूण प्रलंबित"
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalPendingCount ? Countdata.TotalPendingCount : '0'
                                                                // DashCount && DashCount[2] && DashCount[2].totalCount ? DashCount[2].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-orange1 white py-3 main-tab pendingLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}
                                                    />
                                                    {/* }
                                        /> */}

                                                </div>

                                                <div className="col-sm-6 col-lg-4 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleHideShowTable({
                                                            flag: 'All',
                                                            showby: 'TableUserWise',
                                                            M_DepartmentNameMasterID: '0',
                                                            name: 'एकूण निर्गमीत',
                                                            name1: "एकूण निर्गमीत",
                                                            table: 'show',
                                                            fourthflag: 'TotalCloseCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: '',
                                                            fourthReferenceTypeID: '0'

                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }
                                                    }
                                                >
                                                    <CardTotal
                                                        title={flag && flag === 'file' ? "एकूण निर्गमीत" : "एकूण निर्गमीत"}
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                Countdata && Countdata.TotalCloseCount ? Countdata.TotalCloseCount : '0'
                                                                // DashCount && DashCount[3] && DashCount[3].totalCount ? DashCount[3].totalCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-purple1 py-3 white main-tab closeLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                    {/* }
                                        /> */}

                                                </div>

                                                {/* <div className="col-sm-6 col-lg-6 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    // onClick={() => condition ?  functionToClick() : null}
                                                    onClick={() =>
                                                        flag === 'file' ?

                                                            handleHideShowTable({
                                                                flag: 'All',
                                                                showby: 'TableUserWise',
                                                                M_DepartmentNameMasterID: '0',
                                                                name1: 'एकूण प्रलंबित',
                                                                name: "कार्यासन निहाय प्राप्त व प्रलंबित नस्तीचा गोषवारा",
                                                                table: 'show',
                                                                fourthflag: 'TotalPendingCount',
                                                                fourthShowby: 'All',
                                                                fourthfilterID: '',
                                                                fourthReferenceTypeID: '0'

                                                            }, true)
                                                            // sessionStorage.setItem('flagPending1', 'Pending')

                                                            :
                                                            null
                                                    }
                                                >
                                                    <BranchCardTotal
                                                        title="कार्यासन निहाय प्राप्त व प्रलंबित"
                                                        title1='नस्तीचा गोषवारा'
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                // DashCount && DashCount[1] && DashCount[1].totalCount ? DashCount[1].totalCount : '0'
                                                                Countdata && Countdata.TotalPendingCount ? Countdata.TotalPendingCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-red1 white py-3 main-tab pendingLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                  

                                                </div> */}

                                                {/* <div className="col-sm-12 col-md-12 col-lg-6 gx-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        flag === 'file' ?
                                                            handleHideShowTable({
                                                                flag: 'All',
                                                                showby: 'TableUserWise',
                                                                M_DepartmentNameMasterID: '0',
                                                                name1: 'एकूण नोंद केलेल्या नस्ती',
                                                                name: "एकूण निर्गमीत",
                                                                table: 'show',
                                                                fourthflag: 'TotalCloseCount',
                                                                fourthShowby: 'All',
                                                                fourthfilterID: '',
                                                                fourthReferenceTypeID: '0'

                                                            }, true)
                                                            // sessionStorage.setItem('flagPending1', 'Pending')
                                                            : null
                                                    }
                                                >
                                                    <BranchCardTotal
                                                        title={flag && flag === 'file' ? "एकूण निर्गमीत" : "एकूण निर्गमीत"}
                                                        quantity={
                                                            flag && flag === 'file' ?
                                                                // DashCount && DashCount[2] && DashCount[2].totalCount ? DashCount[2].totalCount : '0'
                                                                Countdata && Countdata.TotalCloseCount ? Countdata.TotalCloseCount : '0'
                                                                :
                                                                0
                                                        }
                                                        usedclass="spcard bg-purple1 py-3 white main-tab closeLetter"
                                                        titleColorClass="spcard-title text-white"
                                                        countColorClass="spcard-title text-white"
                                                        tableflag='ALL'
                                                        onClicksetFlag={onClicksetFlag}
                                                        dashloading={dashloading}

                                                    />
                                                    

                                                </div> */}
                                            </>
                                    }



                                </div>

                                <div className="row main-blocks ">
                                    <div className="col-sm-6 col-lg-4 gx-2"
                                    >
                                        <BranchCardTotal4
                                            title={flag && flag === 'file' ? " एकूण नस्ती नोंद " : "आजची आवक पत्र"}
                                            quantity={
                                                flag && flag === 'file' ?
                                                    // DashCount && DashCount[3] && DashCount[3].totalCount ? DashCount[3].totalCount : '0'
                                                    Countdata && Countdata.TodaysTotalInwardCount ? Countdata.TodaysTotalInwardCount : '0'
                                                    : 0
                                            }
                                            bgClass="spcard"
                                            titleColorClass="spcard-title text-black"
                                            textclass="card-title my-3"
                                            qtyclass="mt-1 mb-3 text-blue"
                                            nameclass="mt-1 mb-3 d-flex"
                                            onClick={(() => { })}


                                        />
                                    </div>

                                    <div className="col-sm-8 col-lg-8 gx-2 "
                                        style={{ cursor: 'pointer' }}
                                    // onClick={() => condition ?  functionToClick() : null}
                                    // onClick={() =>
                                    //     flag === 'file' ?

                                    //         handleHideShowTable({
                                    //             flag: 'All',
                                    //             showby: 'TableUserWise',
                                    //             M_DepartmentNameMasterID: '0',
                                    //             name1: 'एकूण नोंद केलेल्या नस्ती',
                                    //             name: "एकूण प्रलंबित",
                                    //             table: 'show',
                                    //             fourthflag: 'TotalPendingCount',
                                    //             fourthShowby: 'All',
                                    //             fourthfilterID: '',
                                    //             fourthReferenceTypeID: '0'

                                    //         }, true)
                                    //         // sessionStorage.setItem('flagPending1', 'Pending')

                                    //         :
                                    //         null
                                    // }
                                    >
                                        <BranchCardTotal6

                                            title={flag && flag === 'file' ? "सर्वात जास्त प्रलंबित नस्ती" : "सर्वात जास्त प्रलंबित पत्र"}
                                            bgClass="spcard bg-red1"
                                            titleColorClass="spcard-title text-white"
                                            textclass="card-title text-white"
                                            classComm="text-white maxcount"
                                            TopPralmbitLoader={TopPralmbitLoader}
                                            TopPralambit={TopPralambit}
                                            flag={flag}
                                        />
                                    </div>

                                </div>





                                {/* ####################### */}



                            </div>

                            <div className="col-12 col-lg-6 col-xxl-6 gx-2">
                                {/* d-flex order-2 order-xxl-3 */}
                                <div className="spcard flex-fill w-100">

                                    <div className="spcard-header mb-n4 d-flex justify-content-between">
                                        <h5 className="spcard-title mb-0">
                                            {flag && flag === 'file' ? "प्रलंबित नस्ती " : "प्रलंबित पत्र"}
                                        </h5>
                                        <div className=''>
                                            <GraphButton
                                                setShowGraph={setShowGraph}
                                                showGraph={showGraph}
                                            />
                                        </div>
                                    </div>
                                    {
                                        TopPralmbitLoader ?

                                            <div className='loadingcenter' style={{ height: '46vh' }}>
                                                <i
                                                    className="fa fa-refresh fa-spin loadingIcon"
                                                />
                                                <label className='loadinglabel'>Loading ...</label>
                                            </div>
                                            :
                                            <div className="spcard-body" style={{ height: '46vh' }}>
                                                <div className="align-self-center w-100">
                                                    {
                                                        showGraph === 'Pie' ?
                                                            <div className="" >
                                                                {
                                                                    flag === 'file' && TopPralmbitCount.length !== 0 && allZeros === false ?
                                                                        <Max_Pendancy_PieChart
                                                                            labels={TopPralmbitLables}
                                                                            data={TopPralmbitCount}
                                                                            bgColor={coloR}
                                                                        />
                                                                        :
                                                                        <div className='loadingcenter' style={{ height: '46vh', overflow: 'hidden' }}>
                                                                            <img
                                                                                src="assets/img/folder.png"
                                                                                style={{ height: "40px", marginLeft: "10px" }}
                                                                            /><label className='tablenorecordtable'>&nbsp;&nbsp;No Sufficient data...</label>
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="" >
                                                                {
                                                                    flag === 'file' && TopPralmbitCount.length !== 0 && allZeros === false ?
                                                                        <Max_Pendancy_BarChart
                                                                            labels={TopPralmbitLables}
                                                                            data={TopPralmbitCount}
                                                                            bgColor={coloR}
                                                                        />
                                                                        :
                                                                        <div className='loadingcenter' style={{ height: '46vh', overflow: 'hidden' }}>
                                                                            <img
                                                                                src="assets/img/folder.png"
                                                                                style={{ height: "40px", marginLeft: "10px" }}
                                                                            /><label className='tablenorecordtable'>&nbsp;&nbsp;No Sufficient data...</label>
                                                                        </div>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                    }

                                </div>
                            </div>



                            {
                                ShowTablePopUp ?
                                    <FirstUserPopUp
                                        setShowTablePopUp={setShowTablePopUp}
                                        ShowTablePopUp={ShowTablePopUp}
                                        TableDataPopUp={TableDataPopUp}
                                    /> : <></>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
} 