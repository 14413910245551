


import React from 'react'
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { baseUrl } from '../../../../BaseURL/BaseURL';
import CommFourthDrillPopUp from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/CommFourthDrillPopUp';
import ExportOfficeLetterThirdPopUpCOMM from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/ExportOfficeLetterThirdPopUpCOMM';
import $ from 'jquery'


export default function FirstUserPopUp({ TableDataPopUp, setShowTablePopUp }) {

    // console.log(fourthflag)
    const { fourthflag } = TableDataPopUp

    const UserID = sessionStorage.getItem("User")
    var month = (new Date().getMonth()) + 1
    var year = new Date().getFullYear()

    const [loading, setloading] = React.useState(false)
    const [SecondTableData, setSecondTableData] = React.useState([])

    $(function () {
        $(".modal-header").on("mousedown", function (e) {
            var $draggable = $(this);
            var x = e.pageX - $draggable.offset().left,
                y = e.pageY - $draggable.offset().top;
            $("body").on("mousemove", function (mousemoveEvt) {
                $draggable.closest(".modal-dialog").offset({
                    "left": mousemoveEvt.pageX - x,
                    "top": mousemoveEvt.pageY - y
                });
            });
            $("body").one("mouseup", function () {
                $("body").off("mousemove");
            });
        })
    })

    React.useEffect(() => {
        SecondTable()
    }, [])

    const SecondTable = () => {
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommDashBoard/Get_DB_Dept_File_TableUserWiseCount_Select?FinancialYearID=${year}&MonthID=${month}&Flag=${TableDataPopUp ? TableDataPopUp.flag : ''}&ShowBy=${TableDataPopUp ? TableDataPopUp.showby : ''}&UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setSecondTableData(result.data)
                } else {
                    setSecondTableData([])
                }
                setloading(false)
            })
            .catch(error => {
                console.log('error', error)
                setloading(false)

            });
    }


    const [FourthData, setFourthData] = React.useState([])
    const [FourthShow, setFourthShow] = React.useState(false)

    const hideshowFourthPopUp = (data, show) => {
        setFourthData(data)
        setFourthShow(show)
    }

    return (
        <>
            <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between" >
                            <div className="mt-2">
                                <h2>{TableDataPopUp && TableDataPopUp.name ? TableDataPopUp.name : ''}</h2>
                            </div>
                            <div className='mt-1 '>
                                <ExportOfficeLetterThirdPopUpCOMM
                                    SecondTableData={SecondTableData}
                                    PerformerData={TableDataPopUp}
                                    fourthflag={fourthflag}
                                />
                            </div>
                            <button type="button" className="btn-close mr-2 mt-1" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                onClick={() => {
                                    setShowTablePopUp(false)
                                }}>
                                <h1 aria-hidden="true" style={{ color: 'white' }}>&times;</h1>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div>
                                <div className="row">
                                    <div className="col-xl-12 col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 col-xl-12">

                                                <div
                                                    id='nestedMain1'
                                                    className="table-responsive"
                                                >
                                                    {
                                                        loading ?
                                                            <Loader loading /> : ''
                                                    }

                                                    <table className="table table-bordered" style={{ borderCollapse: 'collapse' }}>

                                                        <thead>
                                                            <tr>
                                                                {/* <th>अ.क्र</th> */}
                                                                {
                                                                    fourthflag === 'TotalInwardCount' ?
                                                                        <>
                                                                            <th>कार्यासनाचे नाव</th>
                                                                            <th>एकूण</th>
                                                                            <th>प्रलंबित</th>
                                                                            <th>निर्गमित</th>
                                                                        </>
                                                                        :
                                                                        fourthflag === 'TotalPendingCount' ?
                                                                            <>
                                                                                <th>कार्यासनाचे नाव</th>
                                                                                <th>प्राप्त नस्ती</th>
                                                                                <th>प्रलंबित</th>
                                                                                <th>निर्गमित</th>
                                                                            </>
                                                                            :
                                                                            fourthflag === 'TotalCloseCount' ?
                                                                                <>
                                                                                    <th>कार्यासनाचे नाव</th>
                                                                                    <th>प्राप्त नस्ती</th>
                                                                                    <th>प्रलंबित</th>
                                                                                    <th>निर्गमित</th>
                                                                                </>
                                                                                : ''

                                                                }


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                SecondTableData && SecondTableData !== 0 ? SecondTableData.map((i, j) => {
                                                                    return (
                                                                        <tr
                                                                        // style={{ cursor: 'pointer' }}
                                                                        // onClick={() =>
                                                                        //     hideshowFourthPopUp({
                                                                        //         name: i.entityName,
                                                                        //         flag: 'TotalInwardCount',
                                                                        //         ShowBy: "All",
                                                                        //         FilterID: '63',
                                                                        //         M_ReferenceTypeID: '0',

                                                                        //         fourthflag: TableDataPopUp ? TableDataPopUp.fourthflag : '',
                                                                        //         fourthShowby: TableDataPopUp ? TableDataPopUp.fourthShowby : '',
                                                                        //         fourthfilterID: i.entityID,
                                                                        //         fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                        //     }, true)
                                                                        // }
                                                                        >
                                                                            {/* <td width='8px'>{j + 1}</td> */}
                                                                            {
                                                                                fourthflag === 'TotalInwardCount' ?
                                                                                    <>
                                                                                        <td>{i.entityName ? i.entityName : '-'}</td>
                                                                                        <td align='center'
                                                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                                                            onClick={() => {
                                                                                                hideshowFourthPopUp({
                                                                                                    name: i.entityName,
                                                                                                    flag: 'totalInwardCount',
                                                                                                    ShowBy: "All",
                                                                                                    FilterID: '63',
                                                                                                    M_ReferenceTypeID: '0',

                                                                                                    fourthflag: 'totalInwardCount',
                                                                                                    fourthShowby: 'All',
                                                                                                    fourthfilterID: i.entityID,
                                                                                                    fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                }, true);
                                                                                                sessionStorage.setItem('flagPending1', '')
                                                                                            }
                                                                                            }

                                                                                        >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>
                                                                                        <td align='center'
                                                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                                                            onClick={() => {
                                                                                                hideshowFourthPopUp({
                                                                                                    name: i.entityName,
                                                                                                    flag: 'totalPendingCount',
                                                                                                    ShowBy: "All",
                                                                                                    FilterID: '63',
                                                                                                    M_ReferenceTypeID: '0',

                                                                                                    fourthflag: 'totalPendingCount',
                                                                                                    fourthShowby: 'All',
                                                                                                    fourthfilterID: i.entityID,
                                                                                                    fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                }, true);
                                                                                                sessionStorage.setItem('flagPending1', 'Pending')
                                                                                            }}

                                                                                        >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>

                                                                                        <td align='center'
                                                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                                                            onClick={() => {
                                                                                                hideshowFourthPopUp({
                                                                                                    name: i.entityName,
                                                                                                    flag: 'totalCloseCount',
                                                                                                    ShowBy: "All",
                                                                                                    FilterID: '63',
                                                                                                    M_ReferenceTypeID: '0',

                                                                                                    fourthflag: 'totalCloseCount',
                                                                                                    fourthShowby: 'All',
                                                                                                    fourthfilterID: i.entityID,
                                                                                                    fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                }, true);
                                                                                                sessionStorage.setItem('flagPending1', 'Pending')

                                                                                            }
                                                                                            }
                                                                                        >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>
                                                                                    </>
                                                                                    :
                                                                                    fourthflag === 'TotalPendingCount' ?
                                                                                        <>
                                                                                            <td>{i.entityName ? i.entityName : '-'}</td>
                                                                                            <td align='center'
                                                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                onClick={() => {
                                                                                                    hideshowFourthPopUp({
                                                                                                        name: i.entityName,
                                                                                                        flag: 'totalInwardCount',
                                                                                                        ShowBy: "All",
                                                                                                        FilterID: '63',
                                                                                                        M_ReferenceTypeID: '0',

                                                                                                        fourthflag: 'totalInwardCount',
                                                                                                        fourthShowby: 'All',
                                                                                                        fourthfilterID: i.entityID,
                                                                                                        fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                    }, true);
                                                                                                    sessionStorage.setItem('flagPending1', '')
                                                                                                }
                                                                                                }

                                                                                            >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>
                                                                                            <td align='center'
                                                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                onClick={() => {
                                                                                                    hideshowFourthPopUp({
                                                                                                        name: i.entityName,
                                                                                                        flag: 'totalPendingCount',
                                                                                                        ShowBy: "All",
                                                                                                        FilterID: '63',
                                                                                                        M_ReferenceTypeID: '0',

                                                                                                        fourthflag: 'totalPendingCount',
                                                                                                        fourthShowby: 'All',
                                                                                                        fourthfilterID: i.entityID,
                                                                                                        fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                    }, true);
                                                                                                    sessionStorage.setItem('flagPending1', 'Pending')
                                                                                                }
                                                                                                }

                                                                                            >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>

                                                                                            <td align='center'
                                                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                onClick={() => {
                                                                                                    hideshowFourthPopUp({
                                                                                                        name: i.entityName,
                                                                                                        flag: 'totalCloseCount',
                                                                                                        ShowBy: "All",
                                                                                                        FilterID: '63',
                                                                                                        M_ReferenceTypeID: '0',

                                                                                                        fourthflag: 'totalCloseCount',
                                                                                                        fourthShowby: 'All',
                                                                                                        fourthfilterID: i.entityID,
                                                                                                        fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                    }, true);
                                                                                                    sessionStorage.setItem('flagPending1', 'Pending')

                                                                                                }
                                                                                                }
                                                                                            >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>
                                                                                        </>
                                                                                        :
                                                                                        fourthflag === 'TotalCloseCount' ?
                                                                                            <>
                                                                                                <td>{i.entityName ? i.entityName : '-'}</td>
                                                                                                <td align='center'
                                                                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                    onClick={() => {
                                                                                                        hideshowFourthPopUp({
                                                                                                            name: i.entityName,
                                                                                                            flag: 'totalInwardCount',
                                                                                                            ShowBy: "All",
                                                                                                            FilterID: '63',
                                                                                                            M_ReferenceTypeID: '0',

                                                                                                            fourthflag: 'totalInwardCount',
                                                                                                            fourthShowby: 'All',
                                                                                                            fourthfilterID: i.entityID,
                                                                                                            fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                        }, true);
                                                                                                        sessionStorage.setItem('flagPending1', '')
                                                                                                    }
                                                                                                    }

                                                                                                >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>

                                                                                                <td align='center'
                                                                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                    onClick={() => {
                                                                                                        hideshowFourthPopUp({
                                                                                                            name: i.entityName,
                                                                                                            flag: 'totalPendingCount',
                                                                                                            ShowBy: "All",
                                                                                                            FilterID: '63',
                                                                                                            M_ReferenceTypeID: '0',

                                                                                                            fourthflag: 'totalPendingCount',
                                                                                                            fourthShowby: 'All',
                                                                                                            fourthfilterID: i.entityID,
                                                                                                            fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                        }, true);
                                                                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                                                                    }
                                                                                                    }

                                                                                                >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>

                                                                                                <td align='center'
                                                                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                                                                    onClick={() => {
                                                                                                        hideshowFourthPopUp({
                                                                                                            name: i.entityName,
                                                                                                            flag: 'totalCloseCount',
                                                                                                            ShowBy: "All",
                                                                                                            FilterID: '63',
                                                                                                            M_ReferenceTypeID: '0',

                                                                                                            fourthflag: 'totalCloseCount',
                                                                                                            fourthShowby: 'All',
                                                                                                            fourthfilterID: i.entityID,
                                                                                                            fourthReferenceTypeID: TableDataPopUp ? TableDataPopUp.fourthReferenceTypeID : ''
                                                                                                        }, true);
                                                                                                        sessionStorage.setItem('flagPending1', 'Pending')

                                                                                                    }}
                                                                                                >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>
                                                                                            </> : ''
                                                                            }

                                                                        </tr>

                                                                    )
                                                                })
                                                                    : 'No Record Found'
                                                            }


                                                        </tbody>
                                                    </table>

                                                    {
                                                        FourthShow ?
                                                            <CommFourthDrillPopUp
                                                                setFourthShow={setFourthShow}
                                                                FourthData={FourthData}
                                                            /> : <></>
                                                    }



                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}


