

import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Eye, Search } from 'react-feather';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Loader } from 'react-overlay-loader';
import { baseUrl } from '../../BaseURL/BaseURL';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import ViewLetterNasti from '../../Pages/Private/File/PopUp/ViewLetter/ViewLetterNasti';

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function FileSearchPage({ title, remark }) {

    const UserID = sessionStorage.getItem("User")
    const M_OfficeMasterID = sessionStorage.getItem('M_OfficeMasterID')


    const [SaveLetter, setSaveLetter] = React.useState(false)
    const [PerPage, setPerPage] = React.useState(5000)
    const [TableData, setTableData] = React.useState([])
    const [loading, setloading] = React.useState(false)
    const [loaded, setloaded] = React.useState(false)

    const [FileType, setFileType] = React.useState([])
    const [FileTypeID, setFileTypeID] = React.useState('0')
    const [FileSandarbh, setFileSandarbh] = React.useState([])
    const [FileSandarbhID, setFileSandarbhID] = React.useState('0')
    const [FileTableUserMaster, setFileTableUserMaster] = React.useState([])
    const [FileTableUserMasterID, setFileTableUserMasterID] = React.useState('0')
    const [FileSubject, setFileSubject] = React.useState('')
    const [FileNo, setFileNo] = React.useState('')
    const [LetterDate, setLetterDate] = React.useState('')
    const [LetterReceivedDate, setLetterReceivedDate] = React.useState('')


    const SaveSucess = () => {
        setSaveLetter(!SaveLetter)
    }

    const handleContainerOnBottom = () => {

        if (TableData) {
            if (PerPage <= TableData.length) {
                setloading(false)
                setloaded(true)
                setPerPage(PerPage + 20)
            }
        }
    };

    const containerRef = useBottomScrollListener(handleContainerOnBottom);

    // React.useEffect(() => {
    //     Get_DB_DDL_M_DepartmentNameMaster()
    //     Get_DDL_M_LetterClassification()
    // }, [])

    // React.useEffect(() => {
    //     if (PerPage > 20) {
    //         GetSearchTableData()
    //     }
    // }, [PerPage])

    const Get_DDL_FileCreation = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_FileCreation?UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setFileType(result.data)

            })
            .catch(error => console.log('error', error));
    }

    const Get_DDL_FileSandarbh = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_File_Inward_MasterWise_M_ReferenceType?UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                setFileSandarbh(result.data)

            })
            .catch(error => console.log('error', error));
    }


    const Get_DDL_File_M_TableUserMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DB_DDL_File_M_TableUserMaster?M_OfficeMasterID=${M_OfficeMasterID ? M_OfficeMasterID : '2'}&UserID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                setFileTableUserMaster(result.data)

            })
            .catch(error => console.log('error', error));
    }


    const GetTableData = (FileNo, FileSubject, FileSandarbhID, LetterDate, LetterReceivedDate, FileTableUserMasterID) => {
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/FileInwardMaster/Get_DB_All_FileSearchSelect?FileNumber=${FileNo ? FileNo : ''}&FileSubject=${FileSubject ? FileSubject : ''}&M_ReferenceTypeID=${FileSandarbhID ? FileSandarbhID : '0'}&SearchFromDate=${LetterDate ? LetterDate : ''}&SearchToDate=${LetterReceivedDate ? LetterReceivedDate : ''}&FilterID=${FileTableUserMasterID ? FileTableUserMasterID : '0'}&UserID=${UserID}&Top=${PerPage}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setTableData(result.data)
                }
                else {
                    setTableData([])
                }
                setloading(false)

            })
            .catch(error => {
                console.log('error', error)
                setloading(false)
            });

    }


    const SearchData = () => {
        GetTableData(FileNo, FileSubject, FileSandarbhID, LetterDate, LetterReceivedDate, FileTableUserMasterID)
    }


    const Clear = () => {
        //    setPerPage()
        setFileNo('')
        setFileSubject('')
        setFileSandarbhID('0')
        setLetterDate('0')
        setLetterReceivedDate('0')
        setFileTableUserMasterID('0')
        setTableData([])

    }



    const OnLoad = () => {
        // Get_DDL_FileCreation()
        Get_DDL_FileSandarbh()
        Get_DDL_File_M_TableUserMaster()
        setTableData([])
        // GetTableData(FileNo, FileSubject, FileSandarbhID, LetterDate, LetterReceivedDate, FileTableUserMasterID)
    }

    return (

        <Popup
            onOpen={() => { OnLoad() }}
            contentStyle={{ width: '85vw', height: '95vh', overflow: 'hidden', backdrop: 'static' }}
            trigger={

                <span>
                    <button className="btn btn-success p-2" >
                        <Search size={16} />
                        &nbsp;Search
                    </button>
                    {/* <a data-tooltip="tooltip" title="Search"
                        className="edit-btn text-primary alert-primary m-1"
                        style={{ cursor: 'pointer' }}
                    >
                        <Search color="green" size={18} />
                    </a> */}
                </span>

            } modal={true} keyboard={false} closeOnDocumentClick={false}>
            {
                close => (
                    // <Fade>
                    <div className="modal-dialog modal-dialog-scrollable modal-xl backdrop-static  ">
                        <div className="modal-content mt-n3">
                            <div className="modal-header">
                                <h3 className="modal-title mt-2" id="staticBackdropLabel">नस्ती शोधा</h3>
                                <button onClick={() => { close(); Clear(); }} className="btn-close mt-1" type="button">x</button>
                            </div>
                            {
                                loading ?
                                    <Loader fullPage loading />
                                    : <></>

                            }
                            <div className="modal-body" style={{ overflow: 'auto' }}>
                                <main>
                                    <div className="filter mt-n4">
                                        <div className="form-row">

                                            <div className="col-12 col-md-6 col-lg-4 form-group">
                                                <label className="control-label" for="priority">नस्ती क्रमांक<span style={{ color: 'red' }}> </span></label>
                                                <div>

                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setFileNo(e.target.value)}
                                                        value={FileNo}
                                                        autoFocus={true}
                                                    />

                                                </div>
                                            </div>


                                            <div className="col-12 col-md-6 col-lg-4 form-group">
                                                <label className="control-label" for="letterSubject">नस्ती विषय</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setFileSubject(e.target.value)}
                                                        value={FileSubject}
                                                    // autoFocus={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">नस्ती प्रकार</label>
                                                <div>
                                                    <select
                                                        id="1"
                                                        className="form-control input-lg"
                                                        name="letterSorting"
                                                        value={FileTypeID}
                                                        onChange={(e) => {
                                                            setFileTypeID(e.target.value)
                                                        }}
                                                    >
                                                        <option value={"0"} disabled>निवडा...</option>
                                                        {
                                                            FileType && FileType !== null ? FileType.map(item =>
                                                                <option value={item.indicatorId}>{item.indicatorName}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }

                                                    </select>
                                                </div>
                                            </div> */}

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">नस्ती संदर्भ प्रकार</label>
                                                <div>
                                                    <select
                                                        id="1"
                                                        className="form-control input-lg"
                                                        name="letterSorting"
                                                        // id="letterSorting"
                                                        value={FileSandarbhID}
                                                        onChange={(e) => {
                                                            setFileSandarbhID(e.target.value)
                                                            // document.getElementById('Prakar').selectedIndex = 0
                                                        }}
                                                    >
                                                        <option value={"0"} disabled>निवडा...</option>
                                                        {
                                                            FileSandarbh && FileSandarbh !== null ? FileSandarbh.map(item =>
                                                                <option value={item.id}>{item.referenceType}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">दिनांक पासून</label>
                                                <div>
                                                    <input
                                                        type="date"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setLetterDate(e.target.value)}
                                                        value={LetterDate}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2 form-group">
                                                <label className="control-label" for="letterSubject">दिनांक पर्यंत</label>
                                                <div>
                                                    <input
                                                        type="date"
                                                        className="form-control input-lg"
                                                        name="letterSubject"
                                                        id="letterSubject"
                                                        onChange={(e) => setLetterReceivedDate(e.target.value)}
                                                        value={LetterReceivedDate}
                                                        min={LetterDate}
                                                        max={moment().format('YYYY-MM-DD')}

                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4 form-group">
                                                <label className="control-label" for="letterSubject">विभागाचे नाव</label>
                                                <div>

                                                    <select
                                                        id="1"
                                                        className="form-control input-lg"
                                                        name="letterSorting"
                                                        // id="letterSorting"
                                                        value={FileTableUserMasterID}
                                                        onChange={(e) => {
                                                            setFileTableUserMasterID(e.target.value)
                                                            // document.getElementById('Prakar').selectedIndex = 0
                                                        }}
                                                    >
                                                        <option value={"0"} disabled>निवडा...</option>
                                                        {
                                                            FileTableUserMaster && FileTableUserMaster !== null ? FileTableUserMaster.map(item =>
                                                                <option value={item.m_TableUserMasterID}>{item.tableNameNo}</option>
                                                            ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                        }

                                                    </select>
                                                </div>
                                            </div>

                                            <div className=" col-md-6 col-lg-2 mt-1 mt-md-4 pt-md-1">

                                                <button type="button" className="btn btn-primary ml-1 ml-md-3 "
                                                    onClick={()=>SearchData()}
                                                >Search</button>

                                                <button type="button" className="btn btn-primary ml-3"
                                                    onClick={() => Clear()}
                                                >Clear</button>

                                            </div>

                                            {/* <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>
                                                <button type="button" className='btn btn-primary' onClick={() => SearchData()}>Search</button>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>
                                                <button type="button" className='btn btn-primary' onClick={() => Clear()}>Clear </button>
                                            </div> */}

                                            <div className="col-12 col-md-3 col-lg-1 form-group" style={{ marginTop: '2.5%' }}>

                                            </div>
                                            <div className="col-auto ml-auto mr-1 text-right mt-n1">



                                            </div>

                                        </div>
                                    </div>

                                    <div className="table-responsive"
                                        ref={containerRef}
                                        style={{ height: '40vh', width: 'auto', overflowY: "auto", marginBottom: '0%' }}>


                                        <table className="table table-bordered data-table">
                                            <thead style={{ position: 'relative', zIndex: '9999' }}>
                                                <tr>

                                                    <th id='srwidth1' style={{ width: '1%' }}>अ.क्र</th>
                                                    <th>नस्ती क्रमांक</th>
                                                    <th>नस्ती विषय</th>
                                                    <th>कोणी पाठवली</th>
                                                    <th>कोणास पाठवली</th>
                                                    <th>नस्ती पोच दिनांक व वेळ</th>
                                                    <th>नस्ती संदर्भ प्रकार</th>
                                                    <th>नस्ती स्थिती</th>
                                                    <th>ट्रॅक</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length !== 0 ? TableData.map((i, j) => {
                                                        return (
                                                            <tr>

                                                                <td align='center'>{j + 1}</td>
                                                                <td style={{ width: '15%', whiteSpace: 'nowrap' }} id="breakword">{i.fileNumber ? i.fileNumber : '-'}</td>
                                                                <td style={{ width: '18%' }} id="breakword">{i.letterSubject ? i.letterSubject : '-'}</td>

                                                                <td style={{ width: '15%' }}>
                                                                    {i.transfer_From_Name ? i.transfer_From_Name : ''}
                                                                </td>

                                                                <td style={{ width: '10%' }}>
                                                                    {i.transfer_To_Name ? i.transfer_To_Name : ''}
                                                                </td>

                                                                <td style={{ width: '8%', whiteSpace: 'nowrap' }}>
                                                                    <>
                                                                        <tr style={{ borderColor: 'transparent' }}>{i.lastModifiedDate ? moment(i.lastModifiedDate).format('DD-MM-YYYY') : ''}</tr>
                                                                        <hr style={{ margin: '0' }}></hr>
                                                                        <tr style={{ borderColor: 'transparent' }}>{i.lastModifiedDate ? moment(i.lastModifiedDate).format('hh:mm:a') : ''}</tr>
                                                                    </>
                                                                </td>


                                                                <td style={{ width: '8%' }} id="breakword">{i.referenceType ? i.referenceType : ''}</td>
                                                                <td style={{ width: '8%' }} id="breakword">{i.statusName ? i.statusName : ''}</td>
                                                                <td style={{ width: "6%", padding: 0, position: 'relative', zIndex: '1' }} align='center'>
                                                                    {
                                                                        i.statusID !== 11 ?

                                                                            <ViewLetterNasti
                                                                                title={
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary btn-sm m-1"
                                                                                        style={{ fontSize: 12 }}
                                                                                    // onClick={()=>console.log(i)}
                                                                                    >पहा</button>
                                                                                }
                                                                                letterSubject={i.letterSubject}
                                                                                actionTypeIndicator={i.actionTypeIndicator}
                                                                                image={i.outward_MasterLetterFilePath}
                                                                                correspondRemark={i.correspondRemark}
                                                                                inwardNumber={i.fileNumber}
                                                                                letterMediumIndicator={i.letterMediumName}
                                                                                outwardDepartmentName={i.transfer_From_Name}
                                                                                mainCreatedInward_MasterID={i.mainCreatedFile_Inward_MasterID}
                                                                                createdByUserID={i.createdByUserID}
                                                                                parentInwardID={i.parentInwardID}

                                                                                data={i}

                                                                            />
                                                                            : '-'
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    })


                                                        : <tr>
                                                            <td colSpan={9}> माहिती उपलब्ध नाही
                                                                <hr style={{ height: "10px", color: "#000", backgroundColor: "#000", margin: 0 }} />
                                                            </td>
                                                        </tr>
                                                }



                                            </tbody>
                                        </table>

                                        <div style={{ marginBottom: '8%' }}>
                                            <text style={{ textAlign: 'center' }}>
                                                <ScaleLoader color='#293990' loading={loaded} css={override} size={150} />
                                            </text>
                                        </div>

                                    </div>


                                </main>

                            </div>

                        </div>
                    </div>
                    // </Fade>
                )
            }
        </Popup>
    )
}

export default FileSearchPage
