import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Fade from 'react-reveal/Fade';
import InboxLetterModal from '../InboxLetterModal/InboxLetterModal';
import Track from '../Track/Track';
import TrackNasti from '../Track/TrackNasti';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function ViewLetterNasti({ data, outwardBranchName, title, image, letterSubject, outwardDepartmentName, actionTypeIndicator, correspondRemark, letterMediumIndicator, inwardNumber, createdByUserID, mainCreatedInward_MasterID, parentInwardID }) {




    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (

        <Popup trigger={<span style={{ cursor: 'pointer', position: 'relative' }}> {title} </span>} modal={true} closeOnDocumentClick={false}>
            {
                close => (

                    <div className="modal-dialog modal-dialog-scrollable modal-lg " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="staticBackdropLabel">पहा</h3>
                                <button onClick={() => close()} className="btn-close" type="button">x</button>
                            </div>

                            <div className="modal-body">
                                <main>

                                    <div className="container-fluid p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-xl-12">
                                                {/* <div className="card">
                                                        <div className="card-body"> */}
                                                {/* <form role="form" method="POST" action=""> */}
                                                <div className="form-row row">

                                                    <div className="col-md-12 form-group">
                                                        <label className="control-label" for="officeName">नस्ती विषय</label>
                                                        <div>
                                                            <textarea
                                                                type="text"
                                                                className="form-control input-lg"
                                                                name="officeName"
                                                                id="officeName"
                                                                value={letterSubject}
                                                                readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 form-group">
                                                        <label className="control-label" for="officeName">शेरा</label>
                                                        <div>
                                                            <textarea
                                                                type="text"
                                                                className="form-control input-lg"
                                                                name="officeName"
                                                                id="officeName"
                                                                value={correspondRemark}
                                                                readOnly />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4 form-group">
                                                            <label className="control-label" for="outwardNo">माध्यम</label>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-lg"
                                                                    name="outwardNo"
                                                                    id="outwardNo"
                                                                    value={letterMediumIndicator}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div> */}





                                                    <div className="col-md-12 form-group">
                                                        <label className="control-label" for="outwardNo">कोणत्या विभागाकडून</label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="form-control input-lg"
                                                                name="outwardNo"
                                                                id="outwardNo"
                                                                value={outwardDepartmentName}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>

                                                    {
                                                        data && data.sendFileByIndicatorName ?
                                                            <div className="col-md-12 form-group">
                                                                <label className="control-label" for="outwardNo">फाईल पाठविणे</label>
                                                                <div>
                                                                    <text className="form-control input-lg" style={{background:'#E9ECEF'}}>{data && data.sendFileByIndicatorName ? data.sendFileByIndicatorName : '-'} {data && data.sendFileByPersonName ? '- ' + data.sendFileByPersonName : ''}</text>
                                                                </div>
                                                            </div>
                                                            : <></>
                                                    }

                                                    <div className="col-md-12 form-group mt-4"
                                                        style={{ justifyContent: 'flex-end', display: " flex" }}
                                                    >


                                                        {/* {
                                                                image === null && image ?
                                                                    <></>
                                                                    :

                                                                    <InboxLetterModal
                                                                        title={
                                                                            <button type="button" className="btn btn-primary btn-lg">पहा</button>
                                                                        }
                                                                        image={image}
                                                                        // image=''
                                                                        type="inbox"
                                                                        buttonsize='large'
                                                                        inwardNumber={inwardNumber}
                                                                    // inwardNumber=''
                                                                    />

                                                            } */}

                                                        <TrackNasti
                                                            data={data}
                                                            inwardNumber={inwardNumber}
                                                            createdByUserID={createdByUserID}
                                                            mainCreatedInward_MasterID={mainCreatedInward_MasterID}
                                                            parentInwardID={parentInwardID}
                                                        />


                                                        {/* <span>
                                                                            <button type="button" className="btn btn-primary btn-md" style={{ width: '70px' }}>ट्रॅक</button>
                                                                        </span> */}

                                                        {/* {
                                                                            image === null ? <></> :
                                                                                <span onClick={() => window.open(image, "_blank")}>
                                                                                    <button type="button" className="btn btn-primary btn-md ml-1" style={{ width: '70px' }}>पत्र पहा</button>
                                                                                </span>
                                                                        } */}





                                                    </div>

                                                    {/* <div className="form-group">
                                                            
                                                                <div style={{ position: 'absolute', right: '35px', bottom: '2%' }}>
                                                                <Shera
                                                                    title={
                                                                        <button type="button" className="btn btn-success btn-md control-label">शेरा पहा</button> 
                                                                        // <a href="#" className="btn btn-sm attach" data-toggle="modal" data-target="#attachment">पत्र पहा</a>
                                                                    }
                                                                />
                                                                </div>
                                                            </div> */}
                                                </div>
                                                {/* </form> */}

                                                {/* </div>
                                                    </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </main>

                            </div>

                        </div>
                    </div>

                )
            }
        </Popup>
    )
}

export default ViewLetterNasti
