


import React from 'react'
import { BeatLoader } from 'react-spinners';

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export default function BeatLoaderfn({customcolor}) {
    return (
        <BeatLoader color={customcolor ? customcolor :'#fff'} css={override} size={10} />
    )
}
