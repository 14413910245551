

import React from 'react'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


export default function ViewDownloadPDF({ url, buttonsize }) {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());


    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            {
                url == null ?
                    ''
                    :
                    <button type='button'
                        className={buttonsize ? buttonsize === 'large' ? "btn btn-primary btn-lg mr-2" : "edit-btn btn-primary pl-1 pr-1" : "edit-btn btn-primary pl-1 pr-1"}
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(url,
                            '_blank', `top=50,menubar=0,toolbar=0,left=100,width=${windowDimensions.width - 200},height=${windowDimensions.height - 100}`)}>
                        पहा
                    </button>

            }
        </>

    )
}
