



import React from 'react'
import CountUp, { useCountUp } from 'react-countup';
import { Loader } from 'react-overlay-loader';
import ViewLettersPopUp from '../../Dashboard/LetterDashboard/LetterPopUp/ViewLettersPopUp';
import CommFourthDrillPopUp from '../../Commisioner/SPDashboard/Dashboard/SPOfficeModal/CommFourthDrillPopUp';

export default function BranchCardTotal6({ TopPralambit, classComm, title, bgClass, textclass, TopPralmbitLoader, flag }) {
    // console.log(flag)
    const [hideShowViewLetterPopUp, sethideShowViewLetterPopUp] = React.useState(false)
    const [allPropsPopUpData, setallPropsPopUpData] = React.useState({
        heading: "",
        heading2: "",
        filterID: 0,
        flag: "",
        showBy: "",
        letterClassificationID: 0
    })

    const [FourthData, setFourthData] = React.useState([])
    const [FourthShow, setFourthShow] = React.useState(false)

    const hideshowFourthPopUp = (data, show) => {
        setFourthData(data)
        setFourthShow(show)
    }

    return (
        <div className={bgClass} style={{ height: '27vh', overflow: 'auto' }}>
            <div className="col-12 card-body">
                <div className="col-12 pt-3 pt-md-1">
                    <h5 className={textclass} style={{ textAlign: 'center', marginTop: '-12px' }}>{title}</h5>
                </div>
                <div>
                    {
                        TopPralmbitLoader ?
                            <div className='loadingcenter' style={{ height: '15vh' }}>
                                <i style={{ color: 'white' }}
                                    className="fa fa-refresh fa-spin loadingIcon"
                                />
                                <label className='loadinglabel text-white'>Loading ...</label>
                            </div>
                            :
                            <>

                                {
                                    TopPralambit && TopPralambit.length !== 0 ? TopPralambit.map((i, j) => {
                                        return (
                                            <>
                                                <div className="row mt-n3 my-3"
                                                    // onClick={()=>console.log(i.entityName)}
                                                    onClick={() => {
                                                        hideshowFourthPopUp({
                                                            name: `एकूण प्रलंबित - ${i.entityName ? i.entityName : ''}`,
                                                            flag: 'totalPendingCount',
                                                            ShowBy: "All",
                                                            FilterID: '63',
                                                            M_ReferenceTypeID: '0',

                                                            fourthflag: 'totalPendingCount',
                                                            fourthShowby: 'All',
                                                            fourthfilterID: i.entityID,
                                                            fourthReferenceTypeID: ''
                                                        }, true);
                                                        sessionStorage.setItem('flagPending1', 'Pending')
                                                    }}
                                                >

                                                    <div className="col-10">
                                                        {
                                                            j == 0 ?
                                                                <span className={classComm} style={{ fontSize: '12px' }}>&nbsp;&nbsp;{j + 1} : &nbsp;{i.entityName ? i.entityName : '...'}</span>
                                                                :
                                                                <span className={classComm} style={{ fontSize: '12px' }}>&nbsp;{j + 1} : &nbsp;{i.entityName ? i.entityName : '...'}</span>
                                                        }


                                                    </div>
                                                    <div className="col-2">
                                                        <span className={classComm}>
                                                            <CountUp
                                                                end={i.totalPendingCount ? i.totalPendingCount : '0'}

                                                            />
                                                        </span>

                                                    </div>

                                                </div>
                                            </>
                                        )
                                    }) : <text className={classComm}>-----</text>
                                    // <Loader loading/>
                                }
                            </>
                    }
                </div>
            </div>

            {
                FourthShow ?
                    <CommFourthDrillPopUp
                        setFourthShow={setFourthShow}
                        FourthData={FourthData}
                    /> : <></>
            }

            {/* {hideShowViewLetterPopUp ?
                <ViewLettersPopUp
                    sethideShowViewLetterPopUp={sethideShowViewLetterPopUp}
                    allPropsPopUpData={allPropsPopUpData}
                    setallPropsPopUpData={setallPropsPopUpData}
                /> :
                <></>
            } */}
        </div>
    )
}