
import React from 'react'

export default function Footer() {
    var d = new Date()
    return (
        <div style={{height:'50px', bottom:'0px'}}>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-left">
                            <p className="mb-0">
                                &copy; {d.getFullYear()}. All right reserved.
                            </p>
                        </div>
                        <div className="col-6 text-right">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    {/* <a className="text-muted">Privacy</a> */}
                                </li>
                                <li className="list-inline-item">
                                    {/* <a className="text-muted">Terms</a> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
