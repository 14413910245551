


import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';



export default function ExportOfficeLetterThirdPopUpCOMM({ SecondTableData, TableDataPopUp, PerformerData, fourthflag }) {
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success btn-sm pt-1 pb-1 pl-3 pr-3"
                table="table-to-xlsExportOfficeLetterThirdPopUpCOMM"
                filename={PerformerData ? PerformerData.name : ''}
                sheet={PerformerData ? PerformerData.name : ''}
                buttonText="Export Excel"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-xlsExportOfficeLetterThirdPopUpCOMM" style={{ display: 'none', width: '100%' }}>
                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{PerformerData ? PerformerData.name : ''}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>

                        {/* <th>कार्यासनाचे नाव</th>
                        <th>प्राप्त नस्ती</th>
                        <th>प्रलंबित</th>
                        <th>निर्गमित</th> */}
                        {
                            fourthflag === 'TotalInwardCount' ?
                                <>
                                    <th>कार्यासनाचे नाव</th>
                                    <th>एकूण</th>
                                    <th>प्रलंबित</th>
                                    <th>निर्गमित</th>
                                </>
                                :
                                fourthflag === 'TotalPendingCount' ?
                                    <>
                                        <th>कार्यासनाचे नाव</th>
                                        <th>प्राप्त नस्ती</th>
                                        <th>प्रलंबित</th>
                                        <th>निर्गमित</th>
                                    </>
                                    :
                                    fourthflag === 'TotalCloseCount' ?
                                        <>
                                            <th>कार्यासनाचे नाव</th>
                                            <th>प्राप्त नस्ती</th>
                                            <th>प्रलंबित</th>
                                            <th>निर्गमित</th>
                                        </>
                                        : ''

                        }
                    </tr>

                </thead>
                <tbody>
                    {
                        SecondTableData && SecondTableData !== 0 ? SecondTableData.map((i, j) => {
                            return (
                                <tr
                                >

                                    {
                                        fourthflag === 'TotalInwardCount' ?
                                            <>
                                                <td>{i.entityName ? i.entityName : '-'}</td>
                                                <td align='center'
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>
                                                <td align='center'
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>
                                                <td align='center'
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>
                                            </>
                                            :
                                            fourthflag === 'TotalPendingCount' ?
                                                <>
                                                    <td>{i.entityName ? i.entityName : '-'}</td>
                                                    <td align='center'
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                    >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>
                                                    <td align='center'
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                    >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>
                                                    <td align='center'
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                    >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>

                                                </>
                                                :
                                                fourthflag === 'TotalCloseCount' ?
                                                    <>
                                                        <td>{i.entityName ? i.entityName : '-'}</td>
                                                        <td align='center'
                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                        >{i.totalInwardCount ? i.totalInwardCount : '0'}</td>
                                                        <td align='center'
                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                        >{i.totalPendingCount ? i.totalPendingCount : '0'}</td>
                                                        <td align='center'
                                                            style={{ cursor: 'pointer', color: 'blue' }}
                                                        >{i.totalCloseCount ? i.totalCloseCount : '0'}</td>
                                                    </>
                                                    : ''

                                    }


                                </tr>

                            )
                        })
                            : 'No Record Found'
                    }


                </tbody>
            </table>
        </div>
    )
}