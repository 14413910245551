
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaHome, FaEnvelopeOpenText, FaQrcode, FaArrowAltCircleRight } from "react-icons/fa";
import { BiLogIn } from "react-icons/bi";
import { FiEdit, FiHome } from "react-icons/fi";
import { ImQrcode } from "react-icons/im"
import $ from 'jquery'


export default function NavBar({ active }) {
    const navigate = useNavigate()
    const [isclickedclass, setisclickedclass] = React.useState(active)
    const RoleID = sessionStorage.getItem("RoleID")

    var width = $("body").width()

    const Logout = () => {
        sessionStorage.clear();
        // localStorage.clear();
        navigate('/')
    };


    return (
        <div style={{ maxWidth: '200px' }}>

            <div className={width > 873 ? "sidebar collapse show" : "sidebar collapse "} id="sidebartoggler">


                <div className="sidebar-content js-simplebar">
                    <a className="sidebar-brand" to="index.html">
                        &nbsp;
                    </a>

                    <ul className="sidebar-nav" id="navv">

                        <li
                            className={isclickedclass === "deptDashboard" || isclickedclass === "Dashboard" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to={RoleID === '4' ? "/deptdashboard" : RoleID === "9" ? "/branchdashboard" : "/dashboard"}>
                           
                                <FiHome />
                                <span id="span" className="align-middle">डॅशबोर्ड</span>
                            </Link>
                        </li>

                        {/* <li
                            className={isclickedclass === "CDashboard" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/Commdashboard">
                                <FiHome />
                                <span id="span" className="align-middle">डॅशबोर्ड</span>
                            </Link>
                        </li> */}

                        <li
                            className={isclickedclass === "nastinond" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/nastinond">
                                <FiEdit />
                                <span id="span" className="align-middle">नस्ती नोंद</span>
                            </Link>
                        </li>

                        <li
                            className={isclickedclass === "inboxnasti" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/inboxnasti">
                                <FaEnvelopeOpenText />
                                <span id="span" className="align-middle">इनबॉक्स नस्ती</span>
                            </Link>
                        </li>

                        <li
                            className={isclickedclass === "outwardnasti" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/outwardnasti">
                                <FaArrowAltCircleRight size={16} />
                                <span id="span" className="align-middle">जावक नस्ती</span>
                            </Link>
                        </li>



                        <li
                            className={isclickedclass === "nastiqrcode" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/nastiqrcode">
                                <ImQrcode />
                                <span id="span" className="align-middle">नस्ती QR CODE</span>
                            </Link>
                        </li>

                        {
                            RoleID === '4' ?
                                <li
                                    className={isclickedclass === "FileAhval" ? "sidebar-item active" : "sidebar-item"}
                                >
                                    <Link className="sidebar-link" to="/FileAhval">
                                        <FaEnvelopeOpenText />
                                        <span id="span" className="align-middle">नस्ती अहवाल</span>
                                    </Link>
                                </li>
                                : ""
                        }

                        {/* <li
                            className={isclickedclass === "InboxLetterRegister" ? "sidebar-item active" : "sidebar-item"}
                        >
                            <Link className="sidebar-link" to="/inboxLetterRegister">
                                <FaArrowAltCircleLeft />
                                <span id="span" className="align-middle">आवक पत्र रजिस्टर</span>
                            </Link>
                        </li> */}



                        {/* <li className="sidebar-item" id="sidebarlogoutbutton">
                            <a className="sidebar-link" onClick={() => Logout()}>
                                <LogOut size={18} />
                                लॉग आउट

                            </a>
                        </li> */}


                    </ul>
                </div>
            </div>


        </div>
    )
}
