import React, { lazy, Suspense } from 'react';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { GetDeptLetterDashCount, DeptVibhagTableData, DeptLetterCommDDLAPI, DeptLetterAccuracyTable, BranchLetterAccuracyTable, BranchVibhagTableData } from '../BranchAPI/BranchLetterAPI';
import { GetBranchLetterMasterDashCount } from '../BranchAPI/BranchFileAPI';
const BanchLetterMainCard = lazy(() => import('./BanchLetterMainCard'))
const BranchWeekly = lazy(() => import('./BranchWeekly/BranchWeekly'))
const BranchMonth = lazy(() => import('./BranchMonth/BranchMonth'))
const BranchStatusLetter = lazy(() => import('./BranchLetterTable/BranchStatusLetter/BranchStatusLetter'))
const BranchCategoryWisePendancy = lazy(() => import('./BranchCategoryWisePendancy/BranchCategoryWisePendancy'))
const BranchLetterPendancyTable = lazy(() => import('./BranchLetterTable/BranchLetterPendancyTable/BranchLetterPendancyTable'))
const BranchLetterPendancyChart = lazy(() => import('./BranchLetterTable/BranchLetterPendancyTable/BranchLetterPendancyChart'))
const BranchWorkAccuracyLetterTable = lazy(() => import('./BranchWorkAccuracyLetter/BranchWorkAccuracyLetterTable'))
// const DeptLetterPendancyTable = lazy(() => import('./DeptLetterTable/DeptLetterPendancyTable/DeptLetterPendancyTable'))
const DeptCategoryWisePendancy = lazy(() => import('./BranchCategoryWisePendancy/BranchCategoryWisePendancy'))
const DeptMonth = lazy(() => import('./BranchMonth/BranchMonth'))
const DeptWeekly = lazy(() => import('./BranchWeekly/BranchWeekly'))
const DeptLetterMainCard = lazy(() => import('./BanchLetterMainCard'))


export default function BranchLetter({ flag, IsBranchSelect }) {

    const m_BranchNameMasterID = sessionStorage.getItem("m_BranchNameMasterID")


    const [DashCount, setDashCount] = React.useState([])
    const [Countdata, setCountdata] = React.useState([])
    const [loading, setloading] = React.useState(true)
    const [dashloading, setdashloading] = React.useState(false)
    const [showhide, setshowhide] = React.useState(false)
    const [Workshowhide, setWorkshowhide] = React.useState(false)
    const UserID = sessionStorage.getItem("User")

    const [DDLData, setDDLData] = React.useState()
    const [ddlloader, setddlloader] = React.useState(false)
    const [ReferenceTypeTableData, setReferenceTypeTableData] = React.useState([])
    const [LetterAccuracyTableData, setLetterAccuracyTableData] = React.useState([])
    const [DDLDataID, setDDLDataID] = React.useState('0')

    const [WorkTableData, setWorkTableData] = React.useState([])
    const [WorkLoader, setWorkLoader] = React.useState(false)
    const [WorkPieLoader, setWorkPieLoader] = React.useState(false)


    React.useEffect(() => {
        // GetDeptLetterDashCount({ UserID, setDashCount, setdashloading })
        GetBranchLetterMasterDashCount({ UserID, setDashCount, setdashloading ,Flag : "All", m_BranchNameMasterID:m_BranchNameMasterID})
        DeptLetterCommDDLAPI(setDDLData, setddlloader)
        BranchLetterAccuracyTable(setWorkTableData, setWorkLoader, setWorkPieLoader, UserID, 'All_Accuracy', 'All', m_BranchNameMasterID)
    }, [IsBranchSelect])

    React.useEffect(() => {
        handleGetCount(DashCount)
    }, [DashCount])

    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    React.useEffect(() => {
        BranchVibhagTableData(setddlloader, setReferenceTypeTableData, 'TableUserWise', '0', DDLDataID,m_BranchNameMasterID)
    }, [DDLDataID, IsBranchSelect])

    return (
        <div>
            {/* {
                dashloading ?
                    <Loader fullPage loading />
                    : <></>

            } */}

            <div className="container-fluid p-0">


                <div className="row">
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BanchLetterMainCard
                            flag={flag}
                            DashCount={DashCount}
                            Countdata={Countdata}
                            dashloading={dashloading}
                            IsBranchSelect={IsBranchSelect}
                            m_BranchNameMasterID={m_BranchNameMasterID}
                        />
                    </Suspense>

                    {/* जि.पो.अ. कार्यालय साप्ताहिक पत्राचा गोषवारा */}
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchWeekly
                            DashCount={DashCount}
                            Countdata={Countdata}
                            flag={flag}
                            dashloading={dashloading}
                        />
                    </Suspense>



                    {/* जि.पो.अ. कार्यालय मासिक पत्राचा गोषवारा*/}
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchMonth
                            DashCount={DashCount}
                            Countdata={Countdata}
                            flag={flag}
                            dashloading={dashloading}

                        />
                    </Suspense>

                    {/*विभागा मधून जावक पत्राचा गोषवारा */}

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchStatusLetter
                            DDLDataID={DDLDataID}
                        />
                    </Suspense>

                    {/* <StatusOfLetter
                    // DashCount={DashCount}
                    // onGetPatraKaraywahiData={onGetPatraKaraywahiData}
                    // PatraKaraywahiData={PatraKaraywahiData}
                    // headingsData={headingsData}

                    /> */}

                    {/* वर्गनिहाय प्रलंबित पत्राचा गोषवारा */}
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchCategoryWisePendancy IsBranchSelect={IsBranchSelect} />
                    </Suspense>

                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchLetterPendancyTable
                            flag={flag}
                            showhide={showhide}
                            setshowhide={setshowhide}
                            ReferenceTypeTableData={ReferenceTypeTableData}
                            DDLData={DDLData}
                            DDLDataID={DDLDataID}
                            setDDLDataID={setDDLDataID}
                            ddlloader={ddlloader}
                            IsBranchSelect={IsBranchSelect}
                        />
                    </Suspense>
                    {
                        showhide ?
                            <Suspense fallback={<div><Loader fullPage loading /></div>}>
                                <BranchLetterPendancyChart
                                    ReferenceTypeTableData={ReferenceTypeTableData}
                                    showhide={showhide}
                                    flag={flag}
                                />
                            </Suspense>
                            : ''
                    }


                    {/* कार्यासनाच्या कामाची सरासरी */}
                    {/* अचूकता */}
                    <Suspense fallback={<div><Loader fullPage loading /></div>}>
                        <BranchWorkAccuracyLetterTable
                            ReferenceTypeDDL=''
                            ddlloader=''
                            ReferenceTypeTableData=''
                            WorkLoader={WorkLoader}
                            WorkTableData={WorkTableData}
                            tableloader=''
                            onChnageDDL=''
                            Workshowhide={Workshowhide}
                            setWorkshowhide={setWorkshowhide}
                            flag="show"
                            IsBranchSelect={IsBranchSelect}

                        />
                    </Suspense>

                    {/* {
                        Workshowhide ?
                            <Suspense fallback={<div><Loader fullPage loading /></div>}>
                                <BranchWorkAccuracyLetterPie
                                    ReferenceTypeTableData={ReferenceTypeTableData}
                                    WorkPieLoader={WorkPieLoader}
                                    WorkTableData={WorkTableData}
                                    PendingCount=''
                                    Reflabels=''
                                    PColor=''
                                    OnChangePie=''
                                    PieLoader=''
                                    setSPPieFlag=''
                                    SPPieFlag=''
                                    flag='file'
                                    IsBranchSelect={IsBranchSelect}
                                />
                            </Suspense>
                            : ''
                    } */}

                </div>
            </div>

        </div>
    );
}

