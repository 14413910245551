

import React from 'react'
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { FaArrowCircleDown, FaArrowCircleUp, FaFilePdf, FaMinusCircle } from "react-icons/fa";
import moment from 'moment'
import $ from 'jquery'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ScaleLoader from "react-spinners/ScaleLoader";
import { baseUrl } from '../../../../BaseURL/BaseURL';
import NavbarLetter from '../../../../Component/NavbarLetter/NavbarLetter';
import Header from '../../../../Component/Header/Header';
import Footer from '../../../../Component/Footer/Footer';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { toast } from 'react-toastify';
import { ExportExcelLetterAhval } from './ExportExcelLetterAhval';

export default function LetterAhval({ onYearChange }) {
    const [checked, setchecked] = React.useState(false)
    const [loading, setloading] = React.useState(false)

    const handleChange = () => {
        setchecked(!checked)
    }
    // const alert = useAlert();

    const acknowledge = sessionStorage.getItem("acknowledge")
    const RoleID = sessionStorage.getItem("RoleID")
    const UserID = sessionStorage.getItem("User")
    const DepartmentMasterID = sessionStorage.getItem("DepartmentMasterID")
    const M_OfficeMasterID = sessionStorage.getItem("M_OfficeMasterID")
    const DesignationID = sessionStorage.getItem("DesignationID")
    const IsPortalAdmin = sessionStorage.getItem("IsPortalAdmin")
    const [NoRecords, setNoRecords] = React.useState(false)
    // const [PerPage, setPerPage] = React.useState(19)

    const [M_LetterClassification, setM_LetterClassification] = React.useState([])
    const [M_LetterClassificationID, setM_LetterClassificationID] = React.useState('0')
    const [M_ClassificationWiseLetterType, setM_ClassificationWiseLetterType] = React.useState([])
    const [M_ClassificationWiseLetterTypeID, setM_ClassificationWiseLetterTypeID] = React.useState('0')
    const [InwardNumber, setInwardNumber] = React.useState('')
    const [ReceivedLetterNumber, setReceivedLetterNumber] = React.useState('')
    const [InwardMaster, setInwardMaster] = React.useState([])
    const [StatusMaster, setStatusMaster] = React.useState([])
    const [LetterDate, setLetterDate] = React.useState('')
    const [LetterDateTo, setLetterDateTo] = React.useState('')
    const [YearChange, setYearChange] = React.useState(false)
    const [PerPage, setPerPage] = React.useState(19)
    const [StatusID, setStatusID] = React.useState('')
    const [FormSubmitted, setFormSubmitted] = React.useState(false)
    const [Flag, setFlag] = React.useState('')
    const [loaded, setLoaded] = React.useState(false)
    const [StatusName, setStatusName] = React.useState('')
    const [branchescustomdata, setbranchescustomdata] = React.useState([])
    const [isClear, setisClear] = React.useState(false);
    const [Excelbranchescustomdata, setExcelbranchescustomdata] = React.useState([])
    const [PDFUrl, setPDFUrl] = React.useState('')
    const [TableUserMaster, setTableUserMaster] = React.useState([])
    const [TableUserMasterID, setTableUserMasterID] = React.useState('')
    const [TableUserMasterName, setTableUserMasterName] = React.useState('')

    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
    `;

    React.useEffect(() => {
        Get_DDL_M_LetterClassification()
        Get_DDL_StatusMaster()
        Get_DDL_RM_M_TableUserMaster()
    }, [])

    var id = []
    const Get_DDL_StatusMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_Letter_StatusMaster?UserId=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                setStatusMaster(result.data)
                if (acknowledge !== 'true') {
                    setStatusID(result.data[0].id)
                    setStatusName(result.data[0].statusName)

                }
                else {
                    id = result.data.find(item => item.id === 2)
                    setStatusID(id.id)
                    setStatusName(id.statusName)
                }
                // setStatusName(result.data[0].statusName)

            })
            .catch(error => console.log('error', error));
    }


    const Get_DDL_RM_M_TableUserMaster = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommDashBoard/Get_DDL_RM_M_TableUserMaster?UserId=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setTableUserMaster(result.data)
            })
            .catch(error => console.log('error', error));
    }


    const [TrueData, setTrueData] = React.useState([])
    const [CheckboxData, setCheckboxData] = React.useState()

    const GetTableData = () => {
        // console.log(PerPage)
        setLoaded(true)
        setTrueData([])
        setCheckboxData()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/InwardMaster/Get_RM_InwardSelect?Inward_MasterID=&InwardNumber=${InwardNumber}&M_OfficeMasterID=${M_OfficeMasterID}&ReceivedLetterNumber=${ReceivedLetterNumber}&SearchFromDate=${LetterDate}&SearchToDate=${LetterDateTo}&Flag=&UserID=${TableUserMasterID ? TableUserMasterID : '0'}&Top=99999&StatusID=${StatusID}&LoginUserID=${UserID}`, requestOptions)

            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }
                else {
                    // alert.info("Unable to load resources")
                }
            })
            .then(result => {

                setLoaded(false)
                setInwardMaster(result.data)
                if (result.data) {
                    setbranchescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))
                }
                setYearChange(false)
                setFormSubmitted(false)
                setPDFUrl(result.url)
                sessionStorage.removeItem("acknowledge")

                setTimeout(() => {
                    setloading(false)
                }, 500);
                // Reset()

            })
            .catch(error => {
                console.log('error', error)
                setloading(false)
                setLoaded(false)
                // alert.error("Something went wrong")
            });
    }

    const GetExcelData = () => {
        setLoaded(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`${baseUrl}/InwardMaster/Get_InwardSelect?Inward_MasterID=&InwardNumber=${InwardNumber}&M_OfficeMasterID=${M_OfficeMasterID}&ReceivedLetterNumber=${ReceivedLetterNumber}&M_LetterClassificationID=${M_LetterClassificationID}&M_ClassificationWiseLetterTypeID=${M_ClassificationWiseLetterTypeID}&SearchFromDate=${LetterDate}&Flag=${Flag}&UserID=${UserID}&Top=10&StatusID=${StatusID}`, requestOptions)

            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }
                else {
                    // alert.info("Unable to load resources")
                }
            })
            .then(result => {

                setLoaded(false)
                if (result.data) {
                    setExcelbranchescustomdata(result.data.map(customdata => ({ ...customdata, isChecked: false })))
                }
                // console.log(result.data)
            })
            .catch(error => {
                console.log('error', error)
                setloading(false)
                setLoaded(false)
                // alert.error("Something went wrong")
            });
    }



    const Get_DDL_M_LetterClassification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_LetterClassification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setM_LetterClassification(result.data)
            })
            .catch(error => console.log('error', error));
    }

    const Get_DDL_M_ClassificationWiseLetterType = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/CommonDLLData/Get_DDL_M_ClassificationWiseLetterType?M_OfficeMasterID=${M_OfficeMasterID}&LetterClassificationID=${M_LetterClassificationID}&UserID=${UserID}&IsPortalAdmin=${IsPortalAdmin}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setM_ClassificationWiseLetterType(result.data)
            })
            .catch(error => console.log('error', error));
    }



    const Clear = () => {

        setInwardNumber('')
        document.getElementById('karyasan').selectedIndex = 0
        setTableUserMasterID('0')
        setTableUserMasterName('')
        setLetterDate('')
        setLetterDateTo('')
    }

    var isAllCheck = false;

    React.useEffect(() => {
        setloading(true)

        // if (StatusID !== "") {
            GetTableData()
            setInwardMaster([])
        // }
    }, [
        TableUserMasterID,
        InwardNumber,
        M_OfficeMasterID,
        ReceivedLetterNumber,
        M_LetterClassificationID,
        M_ClassificationWiseLetterTypeID,
        LetterDate,LetterDateTo,
        UserID,
        PerPage,
        StatusID,
       ])



    React.useEffect(() => {
        Get_DDL_M_ClassificationWiseLetterType()
    }, [M_OfficeMasterID, M_LetterClassificationID, UserID, IsPortalAdmin])


    // const { items, requestSort, sortConfig } = useSortableData(InwardMaster);

    // const getClassNamesFor = (name) => {
    //     if (!sortConfig) {
    //         return;
    //     }
    //     return sortConfig.key === name ? sortConfig.direction : undefined;
    // };



    const handleContainerOnBottom = () => {
        if (branchescustomdata) {
            if (PerPage <= branchescustomdata.length) {
                setloading(false)
                // setloaded(true)
                setTimeout(() => {
                    setPerPage(PerPage + 10)
                    // setloaded(false)
                }, 500);
            } else {
                setNoRecords(true)
            }
        }

    };
    const containerRef = useBottomScrollListener(handleContainerOnBottom);

    const showMore = (id) => {

        $('#moretext' + id).slideToggle();

        var x = document.getElementById('qw' + id);
        if (x.innerHTML === "Read more") {
            x.innerHTML = "Read less";
            document.getElementById('dot' + id).style.display = 'none'
        }
        else if (x.innerHTML === "Read less") {
            x.innerHTML = "Read more";
            document.getElementById('dot' + id).style.display = 'block'
        }
    }

    return (

        <div>
            {
                loading ?
                    <Loader fullPage loading />
                    : <></>

            }
            <div className="wrapper">

                <NavbarLetter active="PatraAhval" />

                <div className="main">
                    <Header />
                    <main className="content">
                        <div className="container-fluid p-0" >

                            <div className="row mb-2 ml-auto mb-xl-2" style={{ marginTop: '-2%' }}>
                                <div className="col-auto  d-sm-block">
                                    <h2>कार्यासनानुसार पत्राचा अहवाल</h2>
                                </div>
                                <div className="col-auto ml-auto mr-1 text-right mt-n1">

                                    {/* <button type="submit" className="btn btn-outline-danger btn-md mr-2"
                                        onClick={() => window.open(PDFUrl)}
                                    >
                                        <FaFilePdf />
                                        <span className='mx-2'>PDF</span>
                                    </button> */}

                                    {
                                        InwardMaster && InwardMaster.length > 0 ?
                                            <ExportExcelLetterAhval
                                                Exceldata={InwardMaster}
                                                StatusName={StatusName}
                                                TableUserMasterName={TableUserMasterName}
                                            />
                                            : <></>
                                    }

                                    <button type="button" style={{ display: 'inline' }} className="btn btn-primary btn-md mx-2"
                                        onClick={Clear}
                                    >Clear</button>


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12 col-xl-12">
                                    <div className="card w-100">
                                        <div className="card-body w-100">
                                            <div className="filter w-100">
                                                <div className="form-row">
                                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <label className="control-label" for="letterSubject">आवक क्रमांक</label>
                                                        <div>
                                                            <input
                                                                // id="InwardNumber"
                                                                type="text"
                                                                className="form-control input-lg"
                                                                name="letterSubject"
                                                                id="letterSubject"
                                                                value={InwardNumber}
                                                                onChange={(e) => setInwardNumber(e.target.value)}
                                                                autoFocus={true}
                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <label className="control-label" for="priority">पत्र स्थिती</label>
                                                        <div>
                                                            <select
                                                                className="form-control input-lg"
                                                                id="priority"
                                                                name="priority"
                                                                value={StatusID}
                                                                onChange={(e) => {
                                                                    setStatusID(e.target.value)
                                                                    setStatusName(e.target.options[e.target.selectedIndex].text)
                                                                }}
                                                            >
                                                                <option disabled>निवडा....</option>
                                                                {
                                                                    StatusMaster !== null ? StatusMaster.map((item) =>
                                                                        <option value={item.id} name={item.statusName} key={item.id}>{item.statusName}</option>
                                                                    ) :
                                                                        <option disabled>माहिती उपलब्ध नाही</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <label className="control-label" for="priority">कार्यासनाचे नाव </label>
                                                        <div>
                                                            <select
                                                                className="form-control input-lg"
                                                                id="karyasan"
                                                                name="karyasan"
                                                                // value={TableUserMasterID}
                                                                onChange={(e) => {
                                                                    setTableUserMasterID(e.target.value)
                                                                    setTableUserMasterName(e.target.options[e.target.selectedIndex].text)
                                                                }}
                                                            >
                                                                <option disabled selected>निवडा....</option>
                                                                {
                                                                    TableUserMaster !== null ? TableUserMaster.map((item) =>
                                                                        <option value={item.id} name={item.tableNameNo} key={item.id}>{item.tableNameNo}</option>
                                                                    ) :
                                                                        <option disabled>माहिती उपलब्ध नाही</option>
                                                                }


                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <label className="control-label" for="LetterDate">दिनांक पासून</label>
                                                        <div>
                                                            <input
                                                                type="date"
                                                                className="form-control input-lg datetime"
                                                                name="LetterDate"
                                                                id="LetterDate"
                                                                value={LetterDate}
                                                                onChange={(e) => setLetterDate(e.target.value)}
                                                            // max={moment().format("YYYY-MM-DD")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <label className="control-label" for="LetterDate">दिनांक पर्यंत</label>
                                                        <div>
                                                            <input
                                                                type="date"
                                                                className="form-control input-lg datetime"
                                                                name="LetterDate"
                                                                id="LetterDate"
                                                                value={LetterDateTo}
                                                                onChange={(e) => setLetterDateTo(e.target.value)}
                                                                min={moment(LetterDate).format("YYYY-MM-DD")}
                                                                max={moment().format("YYYY-MM-DD")}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div
                                                className="table-responsive"
                                                // onScroll={handleScroll}
                                                ref={containerRef}
                                                style={{ height: '350px', width: 'auto', overflowY: "auto" }}
                                            >
                                                <table className="table table-bordered data-table">
                                                    <thead>
                                                        <tr>
                                                            <th id="srwidth1" style={{ width: "2%" }}>अ.क्र</th>
                                                            <th>आवक क्रमांक</th>

                                                            <th>पत्र विषय</th>

                                                            <th>पत्र दिनांक/मिळाल्याची दिनांक</th>

                                                            <th>पत्र क्रमांक</th>

                                                            <th>पत्र दिनांक व वेळ</th>

                                                            <th>कोणाकडून आले</th>

                                                            <th>कोणास पाठवले</th>

                                                            <th>वर्गवारी /पत्र प्रकार</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            InwardMaster && InwardMaster.length > 0 ?
                                                                InwardMaster.map((item, index) =>
                                                                    <tr>

                                                                        <td align='center' id="srwidth">{index + 1}</td>

                                                                        <td
                                                                            style={{ width: '20%' }}
                                                                            id="breakword"
                                                                        >{item.inwardNumber}</td>

                                                                        <td style={{ width: "18%", cursor: "pointer" }}>
                                                                            {item.letterSubject.length > 50 ?
                                                                                <>
                                                                                    <text style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>{item.letterSubject.slice(0, 50)} <text id={'dot' + index}>...</text></text>
                                                                                    <text id={"moretext" + index} style={{ display: 'none' }}>
                                                                                        {item.letterSubject.slice(50)}
                                                                                    </text>
                                                                                    <br />
                                                                                    <text>
                                                                                        <a onClick={() => showMore(index)} class="moreless-button" style={{ color: 'blue', textDecoration: 'underline', fontSize: 11 }}><text id={'qw' + index}>Read more</text></a>
                                                                                    </text>
                                                                                </>
                                                                                : item.letterSubject
                                                                            }


                                                                        </td>

                                                                        <td style={{ width: '9%', whiteSpace: 'nowrap' }} >
                                                                            <>
                                                                                <tr style={{ border: 'none' }}>{moment(item.letterDate).format('DD-MM-YYYY')}</tr>
                                                                                <hr style={{ margin: '0' }}></hr>
                                                                                <tr style={{ border: 'none' }}>{item.letterReceivedDate ? moment(item.letterReceivedDate).format('DD-MM-YYYY') : '-'}</tr>
                                                                            </>
                                                                        </td>

                                                                        <td style={{ width: '5%' }}
                                                                        // id="breakword"
                                                                        >{item.receivedLetterNumber}</td>

                                                                        <td style={{ width: '9%', whiteSpace: 'nowrap' }}>
                                                                            <tr style={{ border: 'none' }}>
                                                                                {item.lastModifiedDate ?
                                                                                    moment(item.lastModifiedDate).format('DD-MM-YYYY')
                                                                                    : "-"
                                                                                }
                                                                            </tr>
                                                                            <hr style={{ margin: '0' }}></hr>
                                                                            <tr style={{ border: 'none' }}>
                                                                                {item.lastModifiedDate ?
                                                                                    moment(item.lastModifiedDate).format('h:mm:ss a')
                                                                                    : "-"
                                                                                }
                                                                            </tr>
                                                                        </td>

                                                                        {/* <td id="breakword" >{item.letterSenderOfficeName}</td> */}

                                                                        <td style={{ width: '9%' }}>{item.transfer_From_Name}</td>
                                                                        <td style={{ width: '9%' }}>{item.transfer_To_Name}</td>

                                                                        <td style={{ width: '12%' }}>
                                                                            <>
                                                                                <tr style={{ border: 'none' }}>{item.classificationName}</tr>
                                                                                <hr style={{ margin: '0' }}></hr>
                                                                                <tr style={{ border: 'none' }}>{item.letterTypeName}</tr>
                                                                            </>
                                                                        </td>
                                                                    </tr>

                                                                ) :
                                                                <tr>
                                                                    <td colSpan={12}> माहिती उपलब्ध नाही
                                                                        <hr style={{ height: "10px", color: "#000", backgroundColor: "#000", margin: 0 }} />
                                                                    </td>
                                                                </tr>
                                                        }


                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </div>


    )
}
