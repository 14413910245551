

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

export const ExportExcelLetterAhval = ({ Exceldata ,StatusName,TableUserMasterName}) => {

    return (
        <div style={{ display: 'inline', marginLeft: '20px', width:'100%' }}>


            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success btn-md"
                table="table-to-xls"
                filename={`कार्यासनानुसार पत्राचा अहवाल - ${TableUserMasterName}`}
                sheet={`कार्यासनानुसार पत्राचा अहवाल - ${TableUserMasterName}`}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-xls" style={{ display: 'none' }}>
                <h5><span>&emsp;</span></h5>
                <h4 style={{ textAlign: 'center' }}>कार्यासनानुसार पत्राचा अहवाल - {TableUserMasterName}</h4>
                <h5><span>&emsp;</span></h5>

                <h5 style={{ textAlign: 'center' }}>
                    {/* {LetterDate ? ( */}
                    <>
                        {/* <span>पत्र दिनांक:{moment(LetterDate).format("DD-MM-YYYY")}</span> */}
                        {/* <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span> */}
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    </>
                    {/* ) */}
                    {/* : ""} */}
                    {StatusName ? (<span>पत्र स्थिती :{StatusName}</span>) : ""}
                </h5>

                {/* <h5 style={{ textAlign: 'center' }}>
                        <span>पत्र वर्गीकरण:  </span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span>पत्र प्रकार: </span>
                    </h5> */}
                <h5><span>&emsp;</span></h5>



                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th id='srwidth1'>अ.क्र.</th>
                        <th>आवक क्रमांक</th>

                        <th>पत्र विषय</th>

                        <th>पत्र दिनांक/मिळाल्याची दिनांक</th>

                        <th>पत्र क्रमांक</th>

                        <th>पत्र दिनांक व वेळ</th>

                        <th>कोणाकडून आले</th>

                        <th>कोणास पाठवले</th>

                        <th>वर्गवारी /पत्र प्रकार</th>


                    </tr>
                </thead>
                <tbody>
                    {
                        Exceldata ? Exceldata.map((item, index) =>
                            <tr style={{ borderStyle: 'groove' }}>

                                <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>

                                <td style={{ verticalAlign: 'middle' }}>{item.inwardNumber}</td>

                                <td style={{ verticalAlign: 'middle' }}>
                                    <text>{item.letterSubject}</text>
                                </td>

                                <td >
                                    <>
                                        <text>{moment(item.letterDate).format('DD-MM-YYYY')}</text><br />
                                        <text>{item.letterReceivedDate ? moment(item.letterReceivedDate).format('DD-MM-YYYY') : '-'}</text>
                                    </>
                                </td>

                                <td style={{ verticalAlign: 'middle' }}>{item.receivedLetterNumber}</td>

                                <td >
                                    <text>{moment(item.lastModifiedDate).format('DD-MM-YYYY')}</text><br />
                                    
                                    <text>
                                        {item.lastModifiedDate ?
                                            moment(item.lastModifiedDate).format('h:mm:ss a')
                                            : "-"
                                        }
                                    </text>
                                </td>

                                {/* <td id="breakword" >{item.letterSenderOfficeName}</td> */}

                                <td style={{ verticalAlign: 'middle' }}>{item.transfer_From_Name}</td>
                                <td style={{ verticalAlign: 'middle' }}>{item.transfer_To_Name}</td>

                                <td >
                                    <>
                                        <text>{item.classificationName}</text><br/>
                                        <text >{item.letterTypeName}</text>
                                    </>
                                </td>


                            </tr>
                        )
                            : <></>
                    }

                </tbody>
            </table>
        </div>
    )
}
