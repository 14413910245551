
import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-piechart-outlabels";
import { baseUrl } from "../../../../../../BaseURL/BaseURL";
import { Loader } from "react-overlay-loader";
import 'react-overlay-loader/styles.css';


export default function LineGraph(props) {
    const { FourthData, setshowhide, type, allPropsPopUpData } = props

    console.log(allPropsPopUpData)

    const [year, setyear] = React.useState([])

    const [KaryalayPrakarID, setKaryalayPrakarID] = React.useState()
    const [KaryalayShakhaID, setKaryalayShakhaID] = React.useState(0)
    const [yearID, setyearID] = React.useState(0)
    const [loading, setloading] = React.useState(false)
    const [MonthData, setMonthData] = React.useState([])
    const [MonthID, setMonthID] = React.useState(0)

    const [BarData, setBarData] = React.useState()

    const [compLabels, setcompLabels] = React.useState()

    const M_TableUserMasterID = sessionStorage.getItem("PlfmsTableUserID")
    const UserID = sessionStorage.getItem("User")

    // var month = (new Date().getMonth()) + 1
    // var year = new Date().getFullYear()

    React.useEffect(() => {
        YearDDL()
        MonthDDL()
    }, [])

    const YearDDL = () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/DashBoard/GET_DB_DDL_FinancialYearSelect?M_TableUserMasterID=${UserID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status) {
                    setyear(result.data)
                    // setyearID(result && result.data ? result.data[0].financialYearID : 0)
                    // MonthDDL(result && result.data ? result.data[0].financialYearID : 0)
                } else {
                    setyear([])
                }
            })
            .catch(error => {
                setyear([])
                console.log('error', error)
            });
    }

    const MonthDDL = (value) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(`${baseUrl}/DashBoard/GET_DB_DDL_MonthNamesSelect?FinancialYearID=${yearID ? yearID : '0'}&M_TableUserMasterID=${UserID}`, requestOptions)

            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setMonthData(result.data)
                    // setMonthID(result && result.data ? result.data[0].monthNamesID : 0)
                }
            })
            .catch(error => {
                setMonthData([])
                console.log('error', error)
            });
    }

    React.useEffect(() => {
        BarGraphData()
    }, [yearID, MonthID])

    var labels = []
    var TotalDays = []

    const BarGraphData = () => {

        // let id = sessionStorage.getItem('refid')
        setloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem('NMCToken'));

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        let url
        if (type == 'Letter') {
            url = `${baseUrl}/CommDashBoard/Get_DB_All_Letter_FinalDetails_Select?FilterID=${allPropsPopUpData && allPropsPopUpData.filterID ? allPropsPopUpData.filterID : '0'}&Flag=${allPropsPopUpData && allPropsPopUpData.flag ? allPropsPopUpData.flag : 'Close'}&ShowBy=${allPropsPopUpData && allPropsPopUpData.showBy ? allPropsPopUpData.showBy : '"All_Accuracy"'}&M_LetterClassificationID=0&UserID=${UserID}&Top=9999`
        }
        else if (type == 'FileNew') {
            url = `${baseUrl}/CommDashBoard/Get_DB_ALL_COMM_File_FinalDrill_Select?FinancialYearID=${yearID ? yearID : '0'}&MonthID=${MonthID ? MonthID : '0'}&Flag=${allPropsPopUpData && allPropsPopUpData.flag ? allPropsPopUpData.flag : 'Close'}&ShowBy=${allPropsPopUpData && allPropsPopUpData.showBy ? allPropsPopUpData.showBy : '"All_Accuracy"'}&FilterID=${allPropsPopUpData && allPropsPopUpData.filterID ? allPropsPopUpData.filterID : '0'}&UserID=${UserID}&M_ReferenceTypeID=0&Top=99999`

        } else {
            url = `${baseUrl}/CommDashBoard/Get_DB_ALL_COMM_File_FinalDrill_Select?FinancialYearID=${yearID ? yearID : '0'}&MonthID=${MonthID ? MonthID : '0'}&Flag=${FourthData ? FourthData.fourthflag : ''}&ShowBy=${FourthData ? FourthData.fourthShowby : ''}&FilterID=${FourthData ? FourthData.fourthfilterID : ''}&UserID=${UserID}&M_ReferenceTypeID=0&Top=99999`
        }
        fetch(url, requestOptions)

            .then(response => response.json())
            .then(result => {

                if (type == 'Letter') {
                    for (var i = 0; i < result.data.length; i++) {
                        labels.push(result.data[i].inwardNumber)
                        TotalDays.push(result.data[i].totalDays)
                    }
                } else {
                    for (var i = 0; i < result.data.length; i++) {
                        labels.push(result.data[i].fileNumber)
                        TotalDays.push(result.data[i].totalDays)
                    }
                }
                setcompLabels(labels)

                setTimeout(() => {
                    setloading(false)
                }, 300);
            })


            .catch(error => {
                setloading(false)

            });

        setBarData({
            labels: labels,

            datasets: [
                {

                    label: "दिवस",
                    data: TotalDays,
                    fill: false,
                    backgroundColor: '#6BAF01',
                    borderColor: "rgba(0,0,0,0)",

                    barThickness: 32,
                    maxBarThickness: 28,
                }
            ]
        });


    }

    const options = {
        layout: {
            padding: {
                top: 70,
                bottom: 20
            }
        },
        legend: {
            position: 'chartArea',
            align: 'start',
        },
        plugins: {
            labels: {
                render: 'value',
                fontColor: 'black'
            },
            outlabels: {
                color: "black",
                // text: "%l\n%v (%p)",
                stretch: 15,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 16
                }
            },
        },

        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            }],
            yAxes: [{
                gridLines: {
                    drawOnChartArea: false
                },
                ticks: {
                    stepSize: 1,
                    beginAtZero: true
                }
            }]
        }
    }

    return (
        <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055" }} id="nested" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header justify-content-between" >
                        <div className="mt-2">
                            {
                                type == 'Letter' ?
                                    <h2>{allPropsPopUpData && allPropsPopUpData.heading ? allPropsPopUpData.heading : ''}</h2>
                                    :
                                    type == 'FileNew' ?
                                        <h2>{allPropsPopUpData && allPropsPopUpData.heading ? allPropsPopUpData.heading : ''}</h2>
                                        :
                                        <h2>{FourthData && FourthData.name ? FourthData.name : ''}</h2>

                            }
                        </div>

                        <button type="button" className="btn-close"
                            onClick={() => {
                                setshowhide(false)
                            }}
                        >x</button>

                    </div>

                    <div className="modal-body" style={{ overflowY: 'hidden' }} >


                        <div className="flex-fill w-100">
                            <div className=" spcard-header mr-2 ml-2">
                                {/* <div className="col-lg-12 col-xxl-12 col-md-11 col-sm-11 mb-2">
                                    <h5 className="spcard-title mb-0 mt-2 ml-n2">विश्लेषण</h5>

                                </div> */}

                                <div className='row'>

                                    {/* <div className="filter col-lg-3 col-xxl-3 col-md-6 col-sm-6 mt-n1">

                                        <div className="row">
                                            <div className="col-5 mt-2">
                                                <h5 style={{ float: 'right' }}>कार्यालयाचा प्रकार:</h5>
                                            </div>

                                            <div className="col-7">
                                                <select
                                                    className="form-control"
                                                    name="letterSorting"
                                                    id="letterSorting"
                                                    value={KaryalayPrakarID}
                                                    onChange={(e) => {
                                                        setKaryalayPrakarID(e.target.value)
                                                        KaryalayShakhaDDL(e.target.value)
                                                        YearDDL()
                                                        setKaryalayShakhaID(0)
                                                        document.getElementById('shakha').selectedIndex = 0
                                                    }}
                                                >


                                                    {
                                                        KaryalayPrakar !== null ? KaryalayPrakar.map(item =>
                                                            <option value={item.id}>{item.officeTypeName}</option>
                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="filter col-lg-3 col-xxl-3 col-md-6 col-sm-6 mt-n1">

                                        <div className="row">
                                            <div className="col-5 mt-2">
                                                <h5 style={{ float: 'right' }}>कार्यालय / शाखा:</h5>
                                            </div>
                                            <div className="col-7">
                                                <select
                                                    className="form-control"

                                                    id="shakha"
                                                    value={KaryalayShakhaID}
                                                    onChange={(e) => {
                                                        setKaryalayShakhaID(e.target.value)
                                                        console.log(e.target.value)
                                                        if (e.target.value !== '0') {
                                                            setMonthID(0)
                                                        } else {
                                                            YearDDL()
                                                        }
                                                        document.getElementById('month').selectedIndex = 0
                                                    }}
                                                >
                                                    <option value={0} Selected>निवडा</option>

                                                    {
                                                        KaryalayShakha !== null ? KaryalayShakha.map(item =>
                                                            <option value={item.id}>{item.ddL_Items}</option>
                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="filter col-lg-6 col-xxl-6 col-md-6 col-sm-6 mt-n1">
                                        <div className="row">
                                            <div className="col-4 mt-2 gx-3">
                                                <h5 style={{ float: 'right', marginTop: '2px' }}>वर्ष :</h5>
                                            </div>
                                            <div className="col-7">
                                                <select
                                                    className="form-control"
                                                    name="letterSorting"
                                                    id="letterSorting"
                                                    value={yearID}
                                                    onChange={(e) => setyearID(e.target.value)}
                                                >
                                                    <option value={0} Selected disabled>निवडा</option>
                                                    {
                                                        year !== null ? year.map(item =>
                                                            <option value={item.financialYearID}>{item.financialYear}</option>
                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="filter col-lg-6 col-xxl-6 col-md-6 col-sm-6 mt-n1">
                                        <div className="row">
                                            <div className="col-4 mt-2 gx-2">
                                                <h5 style={{ float: 'right', marginTop: '2px' }}>महिना :</h5>
                                            </div>
                                            <div className="col-7">
                                                <select
                                                    // disabled={KaryalayShakhaID !== 0 || KaryalayShakhaID !== '0'}
                                                    className="form-control"
                                                    name="letterSorting"
                                                    id="month"
                                                    value={MonthID}
                                                    onChange={(e) => setMonthID(e.target.value)}
                                                >
                                                    <option value={0} Selected disabled>निवडा</option>

                                                    {
                                                        MonthData !== null ? MonthData.map(item =>
                                                            <option value={item.monthNamesID}>{item.monthNames}</option>
                                                        ) : <option disabled>माहिती उपलब्ध नाही</option>

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div style={{ overflowX: 'auto' }}>

                                {
                                    loading ?
                                        <div className="Chart" style={{ height: '60vh' }}>
                                            <Loader loading />
                                        </div>
                                        :
                                        <div style={{ width: compLabels !== undefined && compLabels.length > 12 ? '4000px' : 'auto' }}>
                                            <Bar

                                                height={compLabels !== undefined && compLabels.length > 12 ? 35 : 100}
                                                data={BarData}
                                                options={options}
                                            />
                                        </div>

                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}
