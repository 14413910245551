import React, { Suspense, useState, lazy, useEffect } from 'react';
import { Loader } from 'react-overlay-loader';
import Footer from '../../../../Component/Footer/Footer';
import Header from '../../../../Component/Header/Header';
import NavBar from '../../../../Component/NavBar/NavBar';
import NavbarLetter from '../../../../Component/NavbarLetter/NavbarLetter';
import { GetBranchDashCount, GetBranchMasterDashCount } from '../BranchAPI/BranchFileAPI';
import { SelectBranchDDL, SelectBranchMasterDDL } from './SelectBranchMasterDDL';
import BranchFile from '../BranchFile/BranchFile';
import BranchLetter from '../BranchLetter/BranchLetter';
import { GetDeptDashCount } from '../../DeptUsers/DeptAPI/DeptFileAPI';

// const DeptFile = lazy(() => import('../DeptFile/DeptFile'))
// const DeptLetter = lazy(() => import('../DeptLetter/DeptLetter'))

export default function BranchDashboard(props) {

    const UserID = sessionStorage.getItem("User")
    const RoleID = sessionStorage.getItem("RoleID")
    const m_BranchNameMasterID = sessionStorage.getItem("m_BranchNameMasterID")

    const [dashloading, setdashloading] = React.useState(false)
    const [dashboardID, setdashboardID] = React.useState(1)
    const [type, settype] = React.useState('file')
    const [DashCount, setDashCount] = React.useState([])
    const [Countdata, setCountdata] = React.useState({})
    const [IsBranchSelect, setIsBranchSelect] = React.useState(false)
    const [CloseData, setCloseData] = React.useState([])

    React.useEffect(() => {
        if (dashboardID === 1) {
            settype('letter')
        } else {
            settype('file')
        }
    }, [dashboardID])


    React.useEffect(() => {
        GetDeptDashCount({ UserID, setDashCount, setdashloading })
       
    }, [IsBranchSelect, dashboardID])

    React.useEffect(() => {
        handleGetCount(DashCount)
    }, [DashCount])

    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    React.useEffect(() => {
        sessionStorage.setItem('dashboardID', dashboardID)
        var DashTypeID = sessionStorage.getItem('dashboardID')
        if (DashTypeID) {
            setdashboardID(parseInt(DashTypeID))
        }
    }, [])

    return (
        <div>
            {/* {
				headingload ?
					// <Loader fullPage loading />
					// <BeatLoaderfn/>
					<PreLoader className='d-block' />
					: <></>

			} */}

            <div className="wrapper">

                {dashboardID === 0 ? <NavBar active="branchdashboard" /> : <NavbarLetter active="branchdashboard" />}


                <div className="main">

                    <Header flag='branchdashboard' dashboardID={dashboardID} />

                    <main className="content mt-2">
                        <div className="container-fluid p-0">

                            <div className="row mb-n3">
                                <div className="row col-md-4 col-sm-12">
                                    <div>
                                        <h2>
                                            {dashboardID == 0 ? <strong>शाखेमधील नस्ती गोषवारा डॅशबोड</strong> : <strong>शाखेमधील पत्रांचा गोषवारा</strong>}

                                            &nbsp;
                                            डॅशबोर्ड

                                        </h2>
                                    </div>
                                </div>

                                <div className="row col-md-6 col-sm-12 mt-1 mt-md-0">
                                    <SelectBranchMasterDDL IsBranchSelect={IsBranchSelect} setIsBranchSelect={setIsBranchSelect} />
                                </div>

                                <div className="row col-md-2 col-sm-12 maintop">
                                    <div className="btn-group btn-group-lg" role="group" aria-label="Basic example" style={{ marginLeft: "auto" }}>
                                        <button type="button" className={dashboardID === 0 ? "btn dash-btn dash-btn-active" : "btn dash-btn btn-secondary"}
                                            // onClick={() => { setdashboardID(0); sessionStorage.setItem('dashboardID', '0') }}
                                            >नस्ती</button>
                                        <button type="button" className={dashboardID === 1 ? "btn dash-btn dash-btn-active" : "btn dash-btn btn-secondary"}
                                            onClick={() => { setdashboardID(1); sessionStorage.setItem('dashboardID', '1') }}
                                        >पत्र</button>
                                    </div>
                                </div>


                            </div>

                            {/* <Suspense fallback={<div><Loader fullPage loading /></div>}> */}
                            {dashboardID === 0 && <BranchFile flag={type} dashloading={dashloading} DashCount={DashCount} IsBranchSelect={IsBranchSelect} Countdata={Countdata} />}
                            {/* </Suspense> */}

                            {/* <Suspense fallback={<div><Loader fullPage loading /></div>}> */}
                            {dashboardID === 1 && <BranchLetter flag={type} dashloading={dashloading} IsBranchSelect={IsBranchSelect} />}
                            {/* </Suspense> */}


                        </div>
                    </main>
                    <Footer />
                </div>
            </div>



            {/* <div className="wrapper">
				<CommLetter />
			</div> */}
        </div>
    );
}

