

import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';



export default function ExcelExportPendancyTableCOMM({ ReferenceTypeTableData,name, flag}) {
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success btn-sm pt-1 pb-1 pl-3 pr-3"
                table="table-to-xlsExcelExportPendancyTableCOMM"
                filename={name}
                sheet={name}
                buttonText="Export Excel"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-xlsExcelExportPendancyTableCOMM" style={{ display: 'none', width: '100%' }}>
                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>

                        <th >
                            {
                                flag === 'कार्यासनाचे नाव' ?
                                'कार्यासनाचे नाव'
                                :
                                'विभागाचे नाव'
                            }
                            
                            </th>
                        <th >{name === 'विभागा नुसार प्रलंबित पत्राचा गोषवारा' ? 'एकूण' : 'एकूण'}
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalInwardCount ? ReferenceTypeTableData.totalInwardCount : "0"}
                        </th>
                        <th >एकूण प्रलंबित
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalPendingCount ? ReferenceTypeTableData.totalPendingCount : "0"}

                        </th>
                        <th >एकूण निर्गमीत
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.totalCloseCount ? ReferenceTypeTableData.totalCloseCount : "0"}

                        </th>
                        <th>0 ते 7 दिवस
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_0_7_Count ? ReferenceTypeTableData.daysPending_0_7_Count : "0"}

                        </th>
                        <th>7 ते 21 दिवस
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_7_21_Count ? ReferenceTypeTableData.daysPending_7_21_Count : "0"}

                        </th>
                        <th >21 ते 90 दिवस
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_21_3_Month_Count ? ReferenceTypeTableData.daysPending_21_3_Month_Count : "0"}

                        </th>
                        <th >90 ते 365 दिवस
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_3_12_Month_Count ? ReferenceTypeTableData.daysPending_3_12_Month_Count : "0"}

                        </th>
                        <th >1 वर्षा पुढील
                            <br style={{ marginTop: '1px', marginBottom: '1px' }} />
                            एकूण = {ReferenceTypeTableData && ReferenceTypeTableData.daysPending_1_YearAbove_Count ? ReferenceTypeTableData.daysPending_1_YearAbove_Count : "0"}

                        </th>
                    </tr>

                </thead>
                <tbody>
                    {
                        ReferenceTypeTableData && ReferenceTypeTableData.data ? ReferenceTypeTableData.data.map((i, index) => {
                            return (
                                <tr >
                                    <td >{i.entityName ? i.entityName : '-'}</td>
                                    <td align='center' id='trwidth'>
                                        {i.totalInwardCount ? i.totalInwardCount : '0'}
                                    </td>

                                    <td align='center'
                                    >
                                        {i.totalPendingCount ? i.totalPendingCount : '0'}
                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.totalCloseCount ? i.totalCloseCount : '0'}
                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.daysPending_0_7_Count ? i.daysPending_0_7_Count : '0'}

                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.daysPending_7_21_Count ? i.daysPending_7_21_Count : '0'}

                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.daysPending_21_3_Month_Count ? i.daysPending_21_3_Month_Count : '0'}

                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.daysPending_3_12_Month_Count ? i.daysPending_3_12_Month_Count : '0'}

                                    </td>

                                    <td align='center' id='trwidth'>
                                        {i.daysPending_1_YearAbove_Count ? i.daysPending_1_YearAbove_Count : '0'}

                                    </td>

                                </tr>
                            )
                        })
                            :
                            <tr><label>No Record Found ...</label></tr>
                    }


                </tbody>
            </table>
        </div>
    )
}